@media screen and (min-width: 1020px) {
  .footerChild {
    position: absolute;
    top: 0;
    left: -1px;
    background-color: var(--color-header-bg);
    width: 1001px;
    height: 504px;
  }
  .aboutUs {
    position: absolute;
    top: 66px;
    left: 850px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .aboutUs,
  .learn,
  .roadmap,
  .whitepaper {
    display: flex;
    align-items: center;
    width: 150px;
    height: 16px;
  }
  .learn {
    position: absolute;
    top: 102px;
    left: 850px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .hippocratCommunityVote {
    position: absolute;
    top: 132px;
    left: 850px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .mainnetNode {
    position: absolute;
    top: 162px;
    left: 850px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .roadmap,
  .whitepaper {
    left: 510px;
  }
  .roadmap {
    position: absolute;
    top: 102px;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .whitepaper {
    top: 162px;
  }
  .blog,
  .news,
  .whitepaper {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .blog {
    top: 132px;
    left: 510px;
    display: flex;
    align-items: center;
    width: 150px;
    height: 16px;
  }
  .news {
    top: 192px;
  }
  .faqs,
  .news,
  .pressKit {
    left: 510px;
    display: flex;
    align-items: center;
    width: 150px;
    height: 16px;
    font-weight: 500;
  }
  .faqs {
    position: absolute;
    top: 222px;
    letter-spacing: -0.02em;
  }
  .pressKit {
    top: 252px;
  }
  .hippocratDaoFoundation,
  .pressKit,
  .resources {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .hippocratDaoFoundation {
    top: 430px;
    left: 0;
    font-size: 12px;
    color: var(--color-gray-100);
    font-weight: 300;
  }
  .resources {
    top: 66px;
    left: 510px;
    color: var(--color-gray-200);
    display: flex;
    align-items: center;
    width: 150px;
    height: 16px;
    font-weight: 300;
  }
  .circle,
  .discord,
  .github,
  .hippo,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    position: absolute;
    top: 102px;
    left: 340px;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 150px;
    height: 16px;
    font-weight: 500;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    top: 327px;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    top: 357px;
  }
  .circle,
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 510px;
  }
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 680px;
  }
  .github,
  .instagram,
  .linkedIn,
  .p2p,
  .sdk,
  .twitterX {
    top: 327px;
    left: 510px;
  }
  .github,
  .linkedIn,
  .p2p,
  .sdk,
  .twitterX {
    left: 680px;
  }
  .github,
  .linkedIn,
  .p2p,
  .sdk {
    left: 850px;
  }
  .github,
  .p2p,
  .sdk {
    top: 357px;
  }
  .p2p,
  .sdk {
    top: 162px;
    left: 340px;
    width: 160px;
  }
  .sdk {
    top: 132px;
  }
  .solutions {
    top: 66px;
    left: 340px;
    color: var(--color-gray-200);
    font-weight: 300 !important;
  }
  .community,
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1,
  .solutions {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 150px;
    height: 16px;
    font-weight: 500;
  }
  .community {
    top: 291px;
    left: 340px;
    color: var(--color-gray-200);
    font-weight: 300;
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1 {
    top: 102px;
    left: 680px;
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 162px;
  }
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 132px;
  }
  .p2pProtocol,
  .protocol1 {
    top: 192px;
  }
  .protocol1 {
    top: 66px;
    color: var(--color-gray-200);
    font-weight: 300;
  }
  .icon {
    position: absolute;
    top: 327px;
    left: -1px;
    width: 255px;
    height: 42px;
    object-fit: cover;
  }
  .footerItem {
    position: absolute;
    top: 416.75px;
    left: calc(50% - 500.25px);
    border-top: 0.5px solid var(--color-white);
    box-sizing: border-box;
    width: 1000.5px;
    height: 0.5px;
  }
  .icon1,
  .icon2 {
    position: absolute;
    top: 65px;
    left: 4px;
    width: 37px;
    height: 37px;
    overflow: hidden;
    object-fit: cover;
  }
  .icon2 {
    left: 46px;
  }
  .footer {
    position: relative;
    width: 1000px;
    margin: 0 auto;
    height: 504px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    border-radius: 20px 20px 0px 0px;

    a {
      color: var(--color-white);
      cursor: pointer;
    }
  }
  .footerWrap {
    border-radius: 20px 20px 0px 0px;
    background-color: var(--color-header-bg);
    position: relative;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .footerChild {
    position: absolute;
    top: 0;
    left: -1px;
    background-color: var(--color-header-bg);
    width: 800px;
    height: 504px;
  }
  .aboutUs {
    position: absolute;
    top: 66px;
    left: 685px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .aboutUs,
  .learn,
  .roadmap,
  .whitepaper {
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
  }
  .learn {
    position: absolute;
    top: 102px;
    left: 685px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .hippocratCommunityVote {
    position: absolute;
    top: 132px;
    left: 685px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .mainnetNode {
    position: absolute;
    top: 162px;
    left: 685px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .roadmap,
  .whitepaper {
    left: 410px;
  }
  .roadmap {
    position: absolute;
    top: 102px;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .whitepaper {
    top: 162px;
  }
  .blog,
  .news,
  .whitepaper {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .blog {
    top: 132px;
    left: 410px;
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
  }
  .news {
    top: 192px;
  }
  .faqs,
  .news,
  .pressKit {
    left: 410px;
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
    font-weight: 500;
  }
  .faqs {
    position: absolute;
    top: 222px;
    letter-spacing: -0.02em;
  }
  .pressKit {
    top: 252px;
  }
  .hippocratDaoFoundation,
  .pressKit,
  .resources {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .hippocratDaoFoundation {
    top: 430px;
    left: 0;
    font-size: 12px;
    color: var(--color-gray-100);
    font-weight: 300;
  }
  .resources {
    top: 66px;
    left: 410px;
    color: var(--color-gray-200);
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
    font-weight: 300;
  }
  .circle,
  .discord,
  .github,
  .hippo,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    position: absolute;
    top: 102px;
    left: 275px;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
    font-weight: 500;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    top: 327px;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    top: 357px;
  }
  .circle,
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 410px;
  }
  .github,
  .instagram,
  .linkedIn,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 547px;
  }
  .github,
  .instagram,
  .linkedIn,
  .p2p,
  .sdk,
  .twitterX {
    top: 327px;
    left: 410px;
  }
  .github,
  .linkedIn,
  .p2p,
  .sdk,
  .twitterX {
    left: 547px;
  }
  .github,
  .linkedIn,
  .p2p,
  .sdk {
    left: 685px;
  }
  .github,
  .p2p,
  .sdk {
    top: 357px;
  }
  .p2p,
  .sdk {
    top: 162px;
    left: 275px;
    width: 100px;
  }
  .sdk {
    top: 132px;
  }
  .solutions {
    top: 66px;
    left: 275px;
    color: var(--color-gray-200);
    font-weight: 300 !important;
  }
  .community,
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1,
  .solutions {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
    font-weight: 500;
  }
  .community {
    top: 291px;
    left: 275px;
    color: var(--color-gray-200);
    font-weight: 300;
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1 {
    top: 102px;
    left: 547px;
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 162px;
  }
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 132px;
  }
  .p2pProtocol,
  .protocol1 {
    top: 192px;
  }
  .protocol1 {
    top: 66px;
    color: var(--color-gray-200);
    font-weight: 300;
  }
  .icon {
    position: absolute;
    top: 333px;
    left: -1px;
    width: 186px;
    height: 30px;
    object-fit: cover;
  }
  .footerItem {
    position: absolute;
    top: 416.75px;
    left: 0;
    border-top: 0.5px solid var(--color-white);
    box-sizing: border-box;
    width: 800px;
    height: 0.5px;
  }
  .icon1,
  .icon2 {
    position: absolute;
    top: 65px;
    left: 4px;
    width: 37px;
    height: 37px;
    overflow: hidden;
    object-fit: cover;
  }
  .icon2 {
    left: 46px;
  }
  .footer {
    position: relative;
    width: 800px;
    margin: 0 auto;
    height: 504px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    border-radius: 20px 20px 0px 0px;

    a {
      color: var(--color-white);
      cursor: pointer;
    }
  }
  .footerWrap {
    border-radius: 20px 20px 0px 0px;
    background-color: var(--color-header-bg);
    position: relative;
  }
}
@media screen and (max-width: 819px) and (min-width: 500px) {
  .footerChild {
    position: absolute;
    top: 0;
    background-color: var(--color-header-bg);
    width: 500px;
    height: 688px;
  }
  .hippocratDaoFoundation {
    position: absolute;
    top: 611px;
    left: 0;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: var(--color-gray-100);
    font-weight: 500;
  }
  .aboutUs,
  .blog,
  .faqs,
  .learn,
  .news,
  .pressKit,
  .resources,
  .roadmap,
  .whitepaper {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
    font-weight: 500;
  }
  .aboutUs {
    top: 107px;
    left: 390px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .blog,
  .faqs,
  .learn,
  .news,
  .pressKit,
  .resources,
  .roadmap,
  .whitepaper {
    top: 143px;
  }
  .learn {
    left: 390px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .hippocratCommunityVote {
    position: absolute;
    top: 173px;
    left: 390px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .mainnetNode {
    position: absolute;
    top: 203px;
    left: 390px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .blog,
  .faqs,
  .news,
  .pressKit,
  .resources,
  .roadmap,
  .whitepaper {
    left: 130px;
  }
  .blog,
  .faqs,
  .news,
  .pressKit,
  .resources,
  .whitepaper {
    top: 203px;
  }
  .blog,
  .faqs,
  .news,
  .pressKit,
  .resources {
    top: 173px;
  }
  .faqs,
  .news,
  .pressKit,
  .resources {
    top: 233px;
  }
  .faqs,
  .pressKit,
  .resources {
    top: 263px;
  }
  .pressKit,
  .resources {
    top: 293px;
  }
  .resources {
    top: 107px;
    color: var(--color-gray-200);
  }
  .circle,
  .discord,
  .github,
  .hippo,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    position: absolute;
    top: 143px;
    left: 0;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
    font-weight: 500;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    top: 390px;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    top: 420px;
  }
  .circle,
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 130px;
  }
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 260px;
  }
  .github,
  .instagram,
  .linkedin,
  .p2p,
  .sdk,
  .twitterX {
    top: 390px;
    left: 130px;
  }
  .github,
  .linkedin,
  .p2p,
  .sdk,
  .twitterX {
    left: 260px;
  }
  .github,
  .linkedin,
  .p2p,
  .sdk {
    left: 390px;
  }
  .github,
  .p2p,
  .sdk {
    top: 420px;
  }
  .p2p,
  .sdk {
    top: 203px;
    left: 0;
  }
  .sdk {
    top: 173px;
  }
  .solutions {
    top: 107px;
    left: 0;
    color: var(--color-gray-200);
  }
  .community,
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1,
  .solutions {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 100px;
    height: 16px;
    font-weight: 500;
  }
  .community {
    top: 354px;
    left: 0;
    color: var(--color-gray-200);
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1 {
    top: 143px;
    left: 260px;
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 203px;
  }
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 173px;
  }
  .p2pProtocol,
  .protocol1 {
    top: 233px;
  }
  .protocol1 {
    top: 107px;
    color: var(--color-gray-200);
  }
  .linkedIn {
    position: absolute;
    top: 390px;
    left: 390px;
    font-size: var(--font-size-base);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    display: flex;
    align-items: center;
    width: 86px;
    height: 16px;
    font-weight: 500;
  }

  .icon {
    position: absolute;
    top: 533px;
    left: 0;
    width: 242px;
    height: 39px;
    object-fit: cover;
  }
  .footerItem {
    position: absolute;
    top: 597.75px;
    left: calc(50% - 250.25px);
    border-top: 0.5px solid var(--color-white);
    box-sizing: border-box;
    width: 500.5px;
    height: 0.5px;
  }
  .icon1,
  .icon2 {
    position: absolute;
    top: 535px;
    left: 260px;
    width: 37px;
    height: 37px;
    overflow: hidden;
  }
  .icon2 {
    left: 302px;
  }
  .footer {
    position: relative;
    width: 500px;
    margin: 0 auto;
    height: 688px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-white);
      cursor: pointer;
    }
  }
  .footerWrap {
    border-radius: 20px 20px 0px 0px;
    background-color: var(--color-header-bg);
    position: relative;
  }
}
@media screen and (max-width: 500px) {
  .footerChild {
    position: absolute;
    top: 0;
    background-color: var(--color-header-bg);
    width: 360px;
    height: 688px;
    margin: 0 auto;
  }
  .hippocratDaoFoundation {
    position: absolute;
    top: 584px;
    left: 0;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: var(--color-gray-100);
    font-weight: 500;
  }
  .aboutUs,
  .blog,
  .faqs,
  .learn,
  .news,
  .pressKit,
  .resources,
  .roadmap,
  .whitepaper {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 86px;
    height: 16px;
    font-weight: 500;
  }
  .aboutUs {
    top: 81px;
    left: 274px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .blog,
  .faqs,
  .learn,
  .news,
  .pressKit,
  .resources,
  .roadmap,
  .whitepaper {
    top: 116px;
  }
  .learn {
    left: 274px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .hippocratCommunityVote {
    position: absolute;
    top: 146px;
    left: 274px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .mainnetNode {
    position: absolute;
    top: 176px;
    left: 274px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    font-weight: 500;
  }
  .blog,
  .faqs,
  .news,
  .pressKit,
  .resources,
  .roadmap,
  .whitepaper {
    left: 92px;
  }
  .blog,
  .faqs,
  .news,
  .pressKit,
  .resources,
  .whitepaper {
    top: 176px;
  }
  .blog,
  .faqs,
  .news,
  .pressKit,
  .resources {
    top: 146px;
  }
  .faqs,
  .news,
  .pressKit,
  .resources {
    top: 206px;
  }
  .faqs,
  .pressKit,
  .resources {
    top: 236px;
  }
  .pressKit,
  .resources {
    top: 266px;
  }
  .resources {
    top: 81px;
    color: var(--color-gray-200);
  }
  .circle,
  .discord,
  .github,
  .hippo,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    position: absolute;
    top: 116px;
    left: 0;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 86px;
    height: 16px;
    font-weight: 500;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .telegram,
  .twitterX {
    top: 363px;
  }
  .circle,
  .discord,
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    top: 393px;
  }
  .circle,
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 92px;
  }
  .github,
  .instagram,
  .linkedin,
  .medium,
  .p2p,
  .sdk,
  .twitterX {
    left: 183px;
  }
  .github,
  .instagram,
  .linkedin,
  .p2p,
  .sdk,
  .twitterX {
    top: 363px;
    left: 92px;
  }
  .github,
  .linkedin,
  .p2p,
  .sdk,
  .twitterX {
    left: 183px;
  }
  .github,
  .linkedin,
  .p2p,
  .sdk {
    left: 274px;
  }
  .github,
  .p2p,
  .sdk {
    top: 393px;
  }
  .p2p,
  .sdk {
    top: 176px;
    left: 0;
  }
  .sdk {
    top: 146px;
  }
  .solutions {
    top: 81px;
    left: 0;
    color: var(--color-gray-200);
  }
  .community,
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1,
  .solutions {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 86px;
    height: 16px;
    font-weight: 500;
  }
  .community {
    top: 327px;
    left: 0;
    color: var(--color-gray-200);
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol,
  .protocol1 {
    top: 116px;
    left: 183px;
  }
  .dao,
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 176px;
  }
  .hpoToken,
  .p2pProtocol,
  .protocol1 {
    top: 146px;
  }
  .p2pProtocol,
  .protocol1 {
    top: 206px;
  }
  .protocol1 {
    top: 81px;
    color: var(--color-gray-200);
  }
  .linkedIn {
    position: absolute;
    top: 363px;
    left: 274px;
    font-size: 13px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    display: flex;
    align-items: center;
    width: 86px;
    height: 16px;
    font-weight: 500;
  }

  .icon {
    position: absolute;
    top: 513px;
    left: 0;
    width: 186px;
    height: 30px;
    object-fit: cover;
  }
  .footerItem {
    position: absolute;
    top: 571px;
    left: calc(50% - 250.25px);
    border-top: 0.5px solid var(--color-white);
    box-sizing: border-box;
    width: 500.5px;
    height: 0.5px;
  }
  .icon1,
  .icon2 {
    position: absolute;
    top: 515px;
    left: 210px;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .icon2 {
    left: 244px;
  }
  .footer {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 660px;
    overflow: hidden;
    text-align: left;
    font-size: 13px;
    color: var(--color-white);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-white);
      cursor: pointer;
    }
  }
  .footerWrap {
    border-radius: 20px 20px 0px 0px;
    background-color: var(--color-header-bg);
    position: relative;
    overflow: hidden;
  }
}
