@media screen and (max-width: 819px) and (min-width: 500px) {
  .forMostOf,
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .forMostOf {
    top: 324px;
    font-size: 20px;
    line-height: 28px;
    width: 452px;
    height: 131px;
  }
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 262px;
    line-height: 35px;
    width: 462px;
  }
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 551px;
  }
  .whenBitcoinAnd1 {
    top: 663px;
  }
  .q {
    font-family: var(--font-bodoni-72);
  }
  .faq,
  .frequentlyAskedQuestions {
    position: absolute;
    top: 75px;
    left: 0;
    font-size: 45px;
    letter-spacing: -0.02em;
  }
  .frequentlyAskedQuestions {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .faq500Child,
  .faq500Inner,
  .faq500Item,
  .lineDiv {
    position: absolute;
    top: 213.65px;
    left: -0.35px;
    border-top: 0.7px solid var(--color-white);
    box-sizing: border-box;
    width: 500.7px;
    height: 0.7px;
  }
  .faq500Inner,
  .faq500Item,
  .lineDiv {
    top: 502.65px;
  }
  .faq500Inner,
  .lineDiv {
    top: 614.65px;
  }
  .lineDiv {
    top: 726.65px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    position: absolute;
    top: 258px;
    left: 472px;
    width: 28px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    top: 547px;
  }
  .chevronLeftIcon2 {
    top: 659px;
  }
  .faq500 {
    position: relative;
    background-color: #000;
    width: 100%;
    height: 1150px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);
  }
  .faqListWrap {
    border-top: 0.7px solid var(--color-white);
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
    top: 247px;
    font-size: 16px;
    line-height: 28px;
    width: 500px;
    height: 131px;
  }
}
@media screen and (max-width: 500px) {
  .forMostOf,
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .forMostOf {
    top: 324px;
    font-size: 20px;
    line-height: 28px;
    width: 452px;
    height: 131px;
  }
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 262px;
    line-height: 35px;
    width: 462px;
  }
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 551px;
  }
  .whenBitcoinAnd1 {
    top: 663px;
  }
  .q {
    font-family: var(--font-bodoni-72);
  }
  .faq,
  .frequentlyAskedQuestions {
    position: absolute;
    top: 79px;
    left: 0;
    font-size: 35px;
    white-space: pre-wrap;
  }
  .frequentlyAskedQuestions {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .faq500Child,
  .faq500Inner,
  .faq500Item,
  .lineDiv {
    position: absolute;
    top: 213.65px;
    left: -0.35px;
    border-top: 0.7px solid var(--color-white);
    box-sizing: border-box;
    width: 500.7px;
    height: 0.7px;
  }
  .faq500Inner,
  .faq500Item,
  .lineDiv {
    top: 502.65px;
  }
  .faq500Inner,
  .lineDiv {
    top: 614.65px;
  }
  .lineDiv {
    top: 726.65px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    position: absolute;
    top: 258px;
    left: 472px;
    width: 28px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    top: 547px;
  }
  .chevronLeftIcon2 {
    top: 659px;
  }
  .faq500 {
    position: relative;
    background-color: #000;
    width: 360px;
    margin: 0 auto;
    height: 1150px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);
  }
  .faqListWrap {
    border-top: 0.7px solid var(--color-white);
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
    top: 247px;
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    height: 131px;
  }
}
