@media screen and (min-width: 1020px) {
  .pleaseCheckThe {
    position: absolute;
    top: 687px;
    left: 510px;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 490px;
    height: 84px;
    font-size: 17px;
  }
  .ver101 {
    margin: 0;
  }
  .ver10 {
    position: absolute;
    top: 687px;
    left: 0;
    font-size: 30px;
    letter-spacing: -0.02em;
    line-height: 35px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 490px;
    height: 84px;
  }
  .t {
    font-family: var(--font-bodoni-72);
    font-weight: 700;
  }
  .e {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .whitePaper {
    top: 100px;
    font-size: 68px;
    white-space: pre-wrap;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .checkOutThe,
  .updatedDec14,
  .whitePaper {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .checkOutThe {
    top: 167px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .updatedDec14 {
    top: 789px;
    font-size: 14px;
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 142px;
    height: 10px;
  }
  .whitepaperChild,
  .whitepaperItem {
    position: absolute;
    top: 244px;
    left: 0;
    border-radius: 30px;
    background-color: #d9d9d9;
    width: 1000px;
    height: 400px;

    img {
      width: 100%;
      border-radius: 30px;
    }
  }
  .whitepaperItem {
    top: 789px;
    left: 510px;
    border-radius: 999px;
    background-color: #19d959;
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 120px;
    height: 36px;
  }
  .readMore {
    position: absolute;
    top: 791px;
    left: 510px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .whitepaper {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 1149px;
    overflow: hidden;
    text-align: left;
    font-size: 19px;
    color: var(--color-black);
    font-family: var(--font-geomanist);

    img {
      pointer-events: none;
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .pleaseCheckThe {
    position: absolute;
    top: 684px;
    left: 410px;
    letter-spacing: -0.02em;
    line-height: 26px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 375px;
    height: 84px;
    font-size: 15px;
  }
  .ver101 {
    margin: 0;
  }
  .ver10 {
    position: absolute;
    top: 684px;
    left: 15px;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 375px;
    height: 84px;
  }
  .t {
    font-family: var(--font-bodoni-72);
  }
  .whitePaper {
    top: 100px;
    left: 0;
    font-size: 60px;
    white-space: pre-wrap;
  }
  .checkOutThe,
  .updatedDec14,
  .whitePaper {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .checkOutThe {
    top: 164px;
    left: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .updatedDec14 {
    top: 786px;
    left: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 137px;
    height: 10px;
  }
  .whitepaper800Child {
    position: absolute;
    top: 241px;
    left: 0;
    border-radius: 20px;
    background-color: #d9d9d9;
    width: 800px;
    height: 400px;
  }
  .whitepaper800Item {
    position: absolute;

    border-radius: 999px;
    background-color: #19d959;
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 120px;
    height: 36px;
  }
  .readMore {
    position: absolute;
    top: 786px;
    left: 410px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .maskGroupIcon {
    position: absolute;
    top: 241px;
    left: 0;
    width: 800px;
    height: 400px;
    object-fit: cover;
    border-radius: 30px;
  }
  .whitepaper800 {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 1085px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
}
