@media screen and (min-width: 1020px) {
  .ourPioneerJourney {
    position: absolute;
    top: 167px;
    left: calc(50% - 500px);
    font-size: var(--font-size-lg);
    text-transform: uppercase;
    color: #00d308;
    font-family: Geomanist;
    font-size: 22px;
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.36px;
    text-transform: uppercase;
  }
  .ourPioneerJourney,
  .r {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.07em;
  }
  .ad {
    font-family: var(--font-bodoni-72-oldstyle);
    font-weight: 400;
  }
  .roadMap {
    position: absolute;
    top: 100px;
    left: calc(50% - 500px);
    font-size: 68px;
    color: var(--color-black);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .theJourneyOf {
    position: absolute;
    top: 315px;
    left: 511px;
    font-size: var(--font-size-lg);
    letter-spacing: -0.02em;
    line-height: 30px;
    color: #484848;
    display: inline-block;
    width: 490px;
    height: 166px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .roadMapScroll {
    overflow-x: scroll;
    position: absolute;
    top: 581px;
    width: 1000px;
    height: 477px;
    overflow-y: hidden;
    display: flex;
    gap: 27px;

    scrollbar-width: none; /* 파이어폭스(Firefox) */
    -ms-overflow-style: none; /* 인터넷 익스플로러(Internet Explorer) 및 엣지(Edge) */
    /* 크롬, 사파리, 오페라 등 */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .roadmapChild,
  .roadmapInner,
  .roadmapInner2,
  .roadmapInner3,
  .roadmapItem {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 293px;
    height: 457px;
  }
  .roadmapInner,
  .roadmapItem {
    left: 320px;
  }
  .roadmapInner {
    left: 640px;
  }
  .roadmapInner2 {
    left: 960px;
  }
  .roadmapInner3 {
    left: 1280px;
  }
  .q,
  .q1 {
    top: 94px;
    font-size: var(--font-size-16xl);
  }
  .q {
    position: absolute;
    left: 28px;
    letter-spacing: -0.02em;
  }
  .q1 {
    left: 348px;
  }
  .div,
  .div1,
  .div2,
  .q1,
  .q2 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .q2 {
    top: 94px;
    left: 668px;
    font-size: var(--font-size-16xl);
  }
  .div,
  .div1,
  .div2 {
    top: 57px; //581
    left: 28px;
    font-weight: 300;
  }
  .div1,
  .div2 {
    left: 348px;
  }
  .div2 {
    left: 668px;
  }
  .proofOfConcept,
  .proofOfConcept1,
  .proofOfConcept2 {
    position: absolute;
    top: 200px; // 782 - 581
    left: 26px;
    letter-spacing: -0.02em;
    line-height: 38px;
    display: inline-block;
    color: var(--color-white);
    width: 240px;
    height: 151px;
  }
  .proofOfConcept1,
  .proofOfConcept2 {
    left: 346px;
  }
  .proofOfConcept2 {
    left: 666px;
  }
  .joinTheJourney {
    margin: 0;
  }
  .joinTheJourneyContainer {
    position: absolute;
    top: 244px;
    left: 0;
    font-size: 34px;
    letter-spacing: -0.02em;
    line-height: 47px;
    display: inline-block;
    color: var(--color-black);
    width: 490px;
    height: 105px;
  }
  .roadmap {
    position: relative;
    width: 100%;
    height: 1372px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-6xl);
    color: var(--color-limegreen);
    font-family: var(--font-geomanist);
    margin-top: -500px;

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }

  .roadmapWrapper {
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 236px;
    min-width: 236px;
    height: 403px;
    padding: 57px 28px 0 28px;
    img {
      width: 110px;
      height: 121px;
      float: right;
      margin-right: -28px;
      margin-top: -57px;
      pointer-events: none;
    }
    div {
      font-family: var(--font-geomanist);
      font-size: 25px;
      color: #00d308;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.5px;
    }
    b {
      margin-top: 12px;
      font-family: var(--font-geomanist);
      font-size: 35px;
      color: #00d308;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.7px;
    }
    p {
      margin-top: 14px;
      font-family: var(--font-geomanist);
      font-size: 25px;
      color: #ffffff;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.4px;
      margin-bottom: 20px;
    }
    li {
      color: #fff;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.24px;
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .ourPioneerJourney {
    position: absolute;
    top: 164px;
    left: calc(50% - 400px);
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .ourPioneerJourney,
  .r {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.07em;
  }
  .ad {
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .roadMap {
    position: absolute;
    top: 100px;
    left: calc(50% - 400px);
    font-size: 60px;
    color: var(--color-black);
  }
  .theJourneyOf {
    position: absolute;
    top: 355px;
    left: 410px;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #484848;
    display: inline-block;
    width: 390px;
    height: 166px;
    font-size: 14px;
  }
  .roadmap800Child,
  .roadmap800Inner,
  .roadmap800Item {
    position: absolute;
    top: 598px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 231px;
    height: 390px;
  }
  .roadmap800Inner,
  .roadmap800Item {
    left: 251px;
  }
  .roadmap800Inner {
    left: 502px;
  }
  .div,
  .div1,
  .div2,
  .q,
  .q1,
  .q2 {
    position: absolute;
    top: 692px;
    left: 28px;
    letter-spacing: -0.02em;
  }
  .div,
  .div1,
  .div2,
  .q1,
  .q2 {
    left: 279px;
  }
  .div,
  .div1,
  .div2,
  .q2 {
    left: 530px;
  }
  .div,
  .div1,
  .div2 {
    top: 655px;
    left: 28px;
    font-size: var(--font-size-6xl);
    font-weight: 300;
  }
  .div1,
  .div2 {
    left: 279px;
  }
  .div2 {
    left: 530px;
  }
  .proofOfConcept,
  .proofOfConcept1,
  .proofOfConcept2 {
    position: absolute;
    top: 799px;
    left: 26px;
    font-size: var(--font-size-2xl);
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    color: var(--color-white);
    width: 179px;
    height: 151px;
  }
  .proofOfConcept1,
  .proofOfConcept2 {
    left: 277px;
  }
  .proofOfConcept2 {
    left: 528px;
  }
  .joinTheJourney {
    margin: 0;
  }
  .joinTheJourneyContainer {
    position: absolute;
    top: 241px;
    left: 0;
    font-size: 32px;
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    color: var(--color-black);
    width: 390px;
    height: 105px;
  }
  .roadmap800 {
    position: relative;
    width: 100%;
    height: 1229px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-16xl);
    color: var(--color-limegreen);
    font-family: var(--font-geomanist);
    margin-top: -500px;
  }
  .roadMapScroll {
    overflow-x: scroll;
    position: absolute;
    top: 581px;
    width: 800px;
    height: 477px;
    display: flex;
    gap: 20px;
    overflow-y: hidden;

    scrollbar-width: none; /* 파이어폭스(Firefox) */
    -ms-overflow-style: none; /* 인터넷 익스플로러(Internet Explorer) 및 엣지(Edge) */
    /* 크롬, 사파리, 오페라 등 */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .roadmapWrapper {
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 180px;
    min-width: 180px;
    height: 373px;
    padding: 17px 26px 0 26px;
    img {
      width: 110px;
      height: 121px;
      position: absolute;
      margin-top: -17px;
      margin-left: 97px;
      pointer-events: none;
    }
    div {
      font-family: var(--font-geomanist);
      font-size: 25px;
      color: #00d308;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.5px;
    }
    b {
      margin-top: 12px;
      font-family: var(--font-geomanist);
      font-size: 35px;
      color: #00d308;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.7px;
    }
    p {
      margin-top: 14px;
      font-family: var(--font-geomanist);
      font-size: 20px;
      color: #ffffff;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.4px;
      margin-bottom: 20px;
    }
    li {
      color: #fff;
      font-family: Geomanist;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.24px;
    }
  }
}
