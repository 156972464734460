@media screen and (max-width: 819px) and (min-width: 500px) {
  .maskGroupIcon {
    position: absolute;
    top: 3976px;
    left: 0;
    width: 500px;
    height: 516px;
    object-fit: cover;
    border-radius: var(--br-11xl);
  }
  .efficientDataCommunication,
  .highSecurityWithout,
  .reducedNetworkTraffic {
    position: absolute;
    top: 1845px;
    left: 87px;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    width: 326px;
  }
  .efficientDataCommunication,
  .reducedNetworkTraffic {
    top: 2435px;
  }
  .reducedNetworkTraffic {
    top: 2140px;
  }
  .theAdvantagesOf {
    margin: 0;
  }
  .technology {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theAdvantagesOfContainer1 {
    width: 100%;
  }
  .hippocratP2pStorageContainer,
  .theAdvantagesOfContainer {
    position: absolute;
    top: 1482px;
    left: 0;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 390px;
  }
  .hippocratP2pStorageContainer {
    top: 2627px;
    left: 1px;
    width: 430px;
  }
  .storage {
    font-family: var(--font-bodoni-72);
  }
  .decentralizedStorageSystem,
  .p2pStorage {
    position: absolute;
    top: 75px;
    left: 0;
    font-size: 45px;
    letter-spacing: -0.02em;
  }
  .decentralizedStorageSystem {
    top: 134px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    text-align: center;
  }
  .p2pDataTransmission {
    position: absolute;
    top: 211px;
    left: 0;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 457px;
    height: 166px;
  }
  .boostsTheEfficiency,
  .highEfficiencyLargeFile {
    position: absolute;
    left: 87px;
    display: inline-block;
  }
  .boostsTheEfficiency {
    top: 3066px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    width: 272px;
    font-size: 14px;
  }
  .highEfficiencyLargeFile {
    top: 2990px;
    letter-spacing: -0.02em;
    line-height: 28px;
    width: 290px;
  }
  .p2pCommunicationUsed {
    position: absolute;
    top: 860px;
    left: 0;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 390px;
    font-size: 14px;
  }
  .p2p500Child,
  .p2p500Item {
    position: absolute;
    top: 2730.5px;
    left: calc(50% - 249.5px);
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 501px;
    height: 1px;
  }
  .p2p500Item {
    top: 1585.5px;
    left: calc(50% - 250.5px);
  }
  .providesAReliable {
    top: 3428px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    width: 290px;
    font-size: 14px;
  }
  .providesAReliable,
  .realTimeStreaming,
  .utilizesP2pCommunication {
    position: absolute;
    left: 87px;
    display: inline-block;
  }
  .realTimeStreaming {
    top: 3380px;
    letter-spacing: -0.02em;
    line-height: 28px;
    width: 326px;
  }
  .utilizesP2pCommunication {
    top: 3815px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    width: 290px;
    font-size: 14px;
  }
  .distributedDataStorage {
    position: absolute;
    top: 3767px;
    left: 87px;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    width: 326px;
  }
  .maskGroupIcon1,
  .p2p500Inner {
    position: absolute;
    left: calc(50% - 163px);
    width: 303px;
    height: 283px;
  }
  .p2p500Inner {
    top: 529px;
    border-radius: 18px;
    background-color: #f6f8f9;
  }
  .maskGroupIcon1 {
    top: 1083px;
    object-fit: contain;
  }
  .openSource {
    top: 4072px;
    left: 142px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .developedOpenlyAcrossContainer,
  .github,
  .openSource {
    position: absolute;
    color: var(--color-black);
  }
  .github {
    top: 4130px;
    left: 210px;
    font-size: 22px;
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .developedOpenlyAcrossContainer {
    top: 4186px;
    left: calc(50% - 207px);
    font-size: var(--font-size-13xl);
    line-height: 46px;
    display: inline-block;
    text-align: center;
    width: 414px;
    height: 209px;
  }
  .maskGroupIcon2,
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5 {
    position: absolute;
    top: 1634px;
    left: calc(50% - 163px);
    width: 179px;
    height: 179px;
    object-fit: contain;
  }
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5 {
    top: 1929px;
  }
  .maskGroupIcon4,
  .maskGroupIcon5 {
    top: 2224px;
  }
  .maskGroupIcon5 {
    top: 2779px;
  }
  .maskGroupIcon6 {
    position: absolute;
    top: 3170px;
    left: calc(50% - 163px);
    width: 179px;
    height: 179px;
    object-fit: contain;
  }
  .maskGroupIcon7 {
    position: absolute;
    top: 3555px;
    left: calc(50% - 163px);
    width: 179px;
    height: 179px;
    object-fit: contain;
  }
  .p2p500 {
    position: relative;
    background-color: var(--color-black);
    width: 100%;
    height: 4639px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
  .p2p500Wrapper {
    position: absolute;
    background-color: var(--color-black);
    width: 100%;
    height: 4639px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);
  }
}
@media screen and (max-width: 500px) {
  .maskGroupIcon {
    position: absolute;
    top: 3976px;
    left: calc(50% - 180px);
    width: 360px;
    height: 516px;
    object-fit: cover;
    border-radius: 30px;
  }
  .efficientDataCommunication,
  .highSecurityWithout,
  .reducedNetworkTraffic {
    position: absolute;
    top: 1845px;
    left: 34px;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    width: 326px;
  }
  .efficientDataCommunication,
  .reducedNetworkTraffic {
    top: 2435px;
  }
  .reducedNetworkTraffic {
    top: 2140px;
  }
  .theAdvantagesOf {
    margin: 0;
  }
  .technology {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theAdvantagesOfContainer1 {
    width: 100%;
  }
  .hippocratP2pStorageContainer,
  .theAdvantagesOfContainer {
    position: absolute;
    top: 1486px;
    left: 0;
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 390px;
  }
  .hippocratP2pStorageContainer {
    top: 2627px;
    left: 1px;
    font-size: var(--font-size-13xl);
    width: 430px;
  }
  .storage {
    font-family: var(--font-bodoni-72);
  }
  .decentralizedStorageSystem,
  .p2pStorage {
    position: absolute;
    top: 79px;
    left: 0;
    font-size: 35px;
    letter-spacing: -0.02em;
  }
  .decentralizedStorageSystem {
    top: 134px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    text-align: center;
  }
  .p2pDataTransmission {
    position: absolute;
    top: 211px;
    left: 0;
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    display: inline-block;
    width: 360px;
  }
  .boostsTheEfficiency,
  .highEfficiencyLargeFile {
    position: absolute;
    left: 35px;
    display: inline-block;
  }
  .boostsTheEfficiency {
    top: 3066px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    width: 272px;
    font-size: 14px;
  }
  .highEfficiencyLargeFile {
    top: 2990px;
    letter-spacing: -0.02em;
    line-height: 28px;
    width: 290px;
  }
  .p2pCommunicationUsed {
    position: absolute;
    top: 860px;
    left: 0;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 100%;
    font-size: 14px;
  }
  .p2p360Child,
  .p2p360Item {
    position: absolute;
    top: 2730.5px;
    left: 0;
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .p2p360Item {
    top: 1585.5px;
    left: 0;
    width: 100%;
  }
  .providesAReliable {
    top: 3428px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    width: 290px;
    font-size: 14px;
  }
  .providesAReliable,
  .realTimeStreaming,
  .utilizesP2pCommunication {
    position: absolute;
    left: 35px;
    display: inline-block;
  }
  .realTimeStreaming {
    top: 3380px;
    letter-spacing: -0.02em;
    line-height: 28px;
    width: 326px;
  }
  .utilizesP2pCommunication {
    top: 3815px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    width: 290px;
    font-size: 14px;
  }
  .distributedDataStorage {
    position: absolute;
    top: 3767px;
    left: 35px;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    width: 326px;
  }
  .maskGroupIcon1,
  .p2p360Inner {
    position: absolute;
    left: calc(50% - 157px);
    width: 303px;
    height: 283px;
  }
  .p2p360Inner {
    top: 529px;
    border-radius: 18px;
  }
  .maskGroupIcon1 {
    top: 1083px;
    object-fit: contain;
  }
  .openSource {
    top: 4107px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .developedOpenlyAcrossContainer,
  .github,
  .openSource {
    position: absolute;
    color: var(--color-black);
  }
  .github {
    top: 4165px;
    left: calc(50% - 180px);
    text-align: center;
    width: 360px;
    font-size: 22px;
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .developedOpenlyAcrossContainer {
    top: 4221px;
    left: calc(50% - 150px);
    font-size: 21px;
    line-height: 31px;
    display: inline-block;
    text-align: center;
    width: 300px;
  }
  .maskGroupIcon2,
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5 {
    position: absolute;
    top: 1634px;
    left: 34px;
    width: 179px;
    height: 179px;
    object-fit: contain;
  }
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5 {
    top: 1929px;
  }
  .maskGroupIcon4,
  .maskGroupIcon5 {
    top: 2224px;
  }
  .maskGroupIcon5 {
    top: 2779px;
  }
  .maskGroupIcon6 {
    position: absolute;
    top: 3170px;
    left: 34px;
    width: 179px;
    height: 179px;
    object-fit: contain;
  }
  .maskGroupIcon7 {
    position: absolute;
    top: 3555px;
    left: 34px;
    width: 179px;
    height: 179px;
    object-fit: contain;
  }
  .p2p360 {
    position: relative;
    background-color: var(--color-black);
    width: 360px;
    margin: 0 auto;
    height: 4639px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
}
