@media screen and (max-width: 819px) and (min-width: 500px) {
  .relatedSt {
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .ories {
    font-family: var(--font-bodoni-72);
  }
  .pleaseCheckThe,
  .relatedStories {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .relatedStories {
    top: 1217px;
    left: -1px;
    font-size: 32px;
    color: var(--color-white);
  }
  .pleaseCheckThe {
    top: 645px;
    left: 23px;
    line-height: 26px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 390px;
    height: 84px;
  }
  .blankLine {
    margin: 0;
  }
  .ver10 {
    position: absolute;
    top: 545px;
    left: 23px;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 90px;
    height: 21px;
  }
  .whitePaper {
    top: 75px;
    left: 0;
    font-size: 45px;
    white-space: pre-wrap;
  }
  .aFocusedInsightful,
  .updatedDec14,
  .whitePaper {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .aFocusedInsightful {
    top: 134px;
    left: 0;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .updatedDec14 {
    top: 590px;
    left: 23px;
    font-size: 14px;
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 174px;
  }
  .whitepaper500Child {
    position: absolute;
    top: 200px;
    left: calc(50% - 250px);
    border-radius: 20px;
    background-color: #d9d9d9;
    width: 500px;
    height: 300px;
  }
  .whitepaper500Item {
    position: absolute;
  }
  .readMore {
    position: absolute;
    top: 754px;
    left: 23px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .maskGroupIcon {
    position: absolute;
    top: 200px;
    left: 0;
    width: 500px;
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
  }
  .whitepaper500 {
    position: relative;
    background-color: var(--color-white);
    width: 100%;
    height: 986px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
}
@media screen and (max-width: 500px) {
  .relatedSt {
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .ories {
    font-family: var(--font-bodoni-72);
  }
  .pleaseCheckThe,
  .relatedStories {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .relatedStories {
    top: 1217px;
    left: -1px;
    font-size: 32px;
    color: var(--color-white);
  }
  .pleaseCheckThe {
    top: 645px;
    left: 11px;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-inter);
    width: calc(100% - 22px);
    height: 84px;
  }
  .blankLine {
    margin: 0;
  }
  .ver10 {
    position: absolute;
    top: 545px;
    left: 11px;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 90px;
    height: 21px;
  }
  .whitePaper {
    top: 79px;
    left: 0;
    font-size: 35px;
    white-space: pre-wrap;
  }
  .aFocusedInsightful,
  .updatedDec14,
  .whitePaper {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .aFocusedInsightful {
    top: 134px;
    left: 0;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .updatedDec14 {
    top: 590px;
    left: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 174px;
  }
  .readMore {
    position: absolute;
    top: 776px;
    left: 11px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .maskGroupIcon {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .whitepaper360 {
    position: relative;
    background-color: var(--color-white);
    width: 360px;
    margin: 0 auto;
    height: 986px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
}
