@media screen and (max-width: 819px) and (min-width: 500px) {
  .hippocratIsThe {
    margin: 0;
  }
  .hippocratIsTheContainer,
  .itHasBeenContainer {
    position: absolute;
    top: 1925px;
    left: 25px;
    line-height: 25px;
    display: inline-block;
    width: 432px;
    font-size: 14px;
  }
  .itHasBeenContainer {
    top: 1259px;
    left: 23px;
    width: 434px;
  }
  .howToAchieveContainer,
  .whyHealthcareData {
    position: absolute;
    top: 1725px;
    left: 23px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
  }
  .whyHealthcareData {
    top: 1159px;
    line-height: 35px;
  }
  .learn500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1099px;
  }
  .visitingHospitalAnd,
  .whatIsSelfSovereigntyContainer {
    position: absolute;
    top: 760px;
    left: 26px;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 431px;
    font-size: 14px;
  }
  .whatIsSelfSovereigntyContainer {
    top: 605px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    width: 390px;
  }
  .se {
    font-family: var(--font-geomanist);
  }
  .healthcareData1,
  .lf {
    font-family: var(--font-bodoni-72);
  }
  .healthcareData1 {
    margin: 0;
  }
  .selfSovereignHealthcareDatContainer {
    position: absolute;
    top: 75px;
    left: 0;
    font-size: 45px;
    letter-spacing: -0.02em;
    line-height: 45px;
    color: var(--color-white);
  }
  .theComingFuture {
    position: absolute;
    top: 179px;
    left: 0;
    font-size: 18px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn500Item {
    position: absolute;
    top: 245px;
    left: calc(50% - 250px);
    border-radius: var(--br-11xl);
    background-color: #d9d9d9;
    width: 500px;
    height: 300px;
  }
  .learn500Img {
    width: 500px;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn500Inner {
    position: absolute;
    top: 1664.5px;
    left: calc(50% - 227.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 455px;
    height: 1px;
  }
  .healthLevel7,
  .nextUseCaseContainer {
    position: absolute;
    left: 240px;
    width: 200px;
    letter-spacing: -0.02em;
  }
  .nextUseCaseContainer {
    top: 2300px;
    font-size: 27px;
  }
  .healthLevel7 {
    top: 2345px;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 2338px;
    left: 475px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    object-fit: contain;
  }
  .learn500 {
    position: relative;
    width: 100%;
    height: 2615px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 500px) {
  .hippocratIsThe {
    margin: 0;
  }
  .hippocratIsTheContainer,
  .itHasBeenContainer {
    position: absolute;
    top: 1792px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .itHasBeenContainer {
    top: 1186px;
  }
  .howToAchieve,
  .whyHealthcareData {
    position: absolute;
    left: 0;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 35px;
  }
  .howToAchieve {
    top: 1682px;
    display: inline-block;
    width: 360px;
  }
  .whyHealthcareData {
    top: 1111px;
  }
  .learn360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1051px;
  }
  .visitingHospitalAnd,
  .whatIsSelfSovereigntyContainer {
    position: absolute;
    top: 715px;
    left: 0;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .whatIsSelfSovereigntyContainer {
    top: 605px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 35px;
    width: 390px;
    font-weight: 700;
    font-family: var(--font-geomanist);
  }
  .se {
    font-family: var(--font-geomanist);
  }
  .healthcareData,
  .lf {
    font-family: var(--font-bodoni-72);
  }
  .healthcareData {
    margin: 0;
  }
  .selfSovereignHealthcareDatContainer {
    position: absolute;
    top: 79px;
    left: 0;
    font-size: 35px;
    letter-spacing: -0.02em;
    line-height: 45px;
    color: var(--color-white);
  }
  .theComingFuture {
    position: absolute;
    top: 179px;
    left: 0;
    font-size: 18px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn360Item {
    position: absolute;
    top: 1621.5px;
    left: 0;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .healthLevel7,
  .nextUseCaseContainer {
    position: absolute;
    width: 200px;
    right: 8px;
    letter-spacing: -0.02em;
  }
  .nextUseCaseContainer {
    top: 2178px;
    font-size: 27px;
  }
  .healthLevel7 {
    top: 2223px;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 2216px;
    right: 0;
    width: 30px;
    height: 30px;
    overflow: hidden;
    object-fit: contain;
  }
  .maskGroupIcon {
    position: absolute;
    top: 245px;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 2390px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
}
