@media screen and (max-width: 819px) and (min-width: 500px) {
  .hippocratActivelyUtilizes {
    position: absolute;
    top: 1731px;
    left: 25px;
    line-height: 25px;
    display: inline-block;
    width: 432px;
    font-size: 14px;
  }
  .hl7HealthLevel {
    margin: 0;
  }
  .hl7HealthLevelContainer {
    position: absolute;
    top: 1185px;
    left: 23px;
    line-height: 25px;
    display: inline-block;
    width: 434px;
    font-size: 14px;
  }
  .hippocratsApproachToContainer,
  .howHl7AchieveContainer {
    position: absolute;
    top: 1576px;
    left: 23px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
  }
  .howHl7AchieveContainer {
    top: 1030px;
  }
  .learn500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 970px;
  }
  .interoperabilityIssuesIn,
  .issueOfInteroperability {
    position: absolute;
    top: 715px;
    left: 26px;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 431px;
    font-size: 14px;
  }
  .issueOfInteroperability {
    top: 560px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    width: 390px;
  }
  .el {
    font-family: var(--font-bodoni-72);
  }
  .healthLevelSevenContainer,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .healthLevelSevenContainer {
    top: 75px;
    font-size: 45px;
    color: var(--color-white);
  }
  .theComingFuture {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn500Inner {
    position: absolute;
    top: 1515.5px;
    left: calc(50% - 227.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 455px;
    height: 1px;
  }
  .nextUseCaseContainer,
  .p2pProtocol {
    position: absolute;
    left: 240px;
    letter-spacing: -0.02em;
  }
  .nextUseCaseContainer {
    top: 2110px;
    width: 200px;
    font-size: 27px;
  }
  .p2pProtocol {
    top: 2155px;
    width: 200px;
  }
  .chevronLeftIcon,
  .maskGroupIcon {
    position: absolute;
    object-fit: contain;
  }
  .chevronLeftIcon {
    top: 2148px;
    left: 475px;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .maskGroupIcon {
    top: 200px;
    left: calc(50% - 250px);
    width: 500px;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn500 {
    position: relative;
    width: 100%;
    height: 2388px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 500px) {
  .hippocratActivelyUtilizes {
    position: absolute;
    top: 1607px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .hl7HealthLevel {
    margin: 0;
  }
  .hl7HealthLevelContainer {
    position: absolute;
    top: 1076px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .hippocratsApproachToContainer,
  .howHl7AchieveContainer {
    position: absolute;
    top: 1497px;
    left: 0;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 35px;
  }
  .howHl7AchieveContainer {
    top: 966px;
  }
  .learn360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 906px;
  }
  .interoperabilityIssuesIn,
  .issueOfInteroperability {
    position: absolute;
    top: 670px;
    left: 0;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .issueOfInteroperability {
    top: 560px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 35px;
    width: 390px;
    font-weight: 700;
    font-family: var(--font-geomanist);
  }
  .el {
    font-family: var(--font-bodoni-72);
  }
  .healthLevelSevenContainer,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .healthLevelSevenContainer {
    top: 79px;
    font-size: 35px;
    color: var(--color-white);
    letter-spacing: -0.7px;
    line-height: 25px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    white-space: nowrap;
  }
  .theComingFuture {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn360Item {
    position: absolute;
    top: 1436.5px;
    left: 0;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .nextUseCaseContainer,
  .p2pProtocol {
    position: absolute;
    letter-spacing: -0.02em;
    width: 200px;
    right: 8px;
  }
  .nextUseCaseContainer {
    top: 1943px;
    font-size: 27px;
    width: 200px;
    right: 8px;
  }
  .p2pProtocol {
    top: 1988px;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 1981px;
    right: 0;
    width: 30px;
    height: 30px;
    overflow: hidden;
    object-fit: contain;
  }
  .maskGroupIcon {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 2158px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
}
