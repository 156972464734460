@media screen and (min-width: 1020px) {
  .blogChild {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 919px;
  }
  .exploreTheWorld {
    position: absolute;
    top: 678px;
    left: 510px;
    font-size: 17px;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 460px;
    height: 84px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nowToSpot {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-inter);
    font-size: 18px;
  }
  .blogTitle {
    position: absolute;
    top: 678px;
    left: 30px;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 35px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 460px;
    height: 84px;
  }
  .og {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .blog1 {
    top: 100px;
    left: 0;
    font-size: 68px;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .aFocusedInsightful,
  .blog1,
  .blogDate {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .aFocusedInsightful {
    top: 167px;
    left: 0;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .blogDate {
    top: 787px;
    left: 32px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .blogItem {
    position: absolute;
    top: 233px;
    left: calc(50% - 500px);
    border-radius: 33px;
    background-color: var(--color-gainsboro);
    width: 1000px;
    height: 400px;
    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
  }
  .blogInner {
    position: absolute;
    top: 787px;
    left: 510px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    width: 130px;
    height: 36px;
    line-height: 36px;
  }
  .blogImg1,
  .blogImg2,
  .blogImg3,
  .blogImg4,
  .blogImg5,
  .blogImg0 {
    position: absolute;
    top: 1004px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 320px;
    height: 200px;
  }
  .blogImg1,
  .blogImg2,
  .blogImg3,
  .blogImg4,
  .blogImg5 {
    top: 1391px;
  }
  .blogImg1,
  .blogImg4,
  .blogImg2,
  .blogImg5 {
    top: 1004px;
    left: 340px;
  }
  .blogImg4,
  .blogImg2,
  .blogImg5 {
    top: 1391px;
  }
  .blogImg2,
  .blogImg5 {
    top: 1004px;
    left: 680px;
  }
  .blogImg5 {
    top: 1391px;
  }
  .blogTitle0,
  .blogTitle1,
  .blogTitle2,
  .blogTitle3,
  .blogTitle4,
  .blogTitle5 {
    position: absolute;
    top: 1256px;
    left: 20px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 27px;
    display: inline-block;
    font-family: var(--font-noto-sans);
    width: 279px;
    height: 48px;
  }
  .blogTitle1,
  .blogTitle2,
  .blogTitle3,
  .blogTitle4,
  .blogTitle5 {
    top: 1643px;
  }
  .blogTitle1,
  .blogTitle4,
  .blogTitle2,
  .blogTitle5 {
    top: 1256px;
    left: 360px;
  }
  .blogTitle4,
  .blogTitle2,
  .blogTitle5 {
    top: 1643px;
  }
  .blogTitle2,
  .blogTitle5 {
    top: 1256px;
    left: 700px;
  }
  .blogTitle5 {
    top: 1643px;
  }
  .blogDate0,
  .blogDate1,
  .blogDate2,
  .blogDate3,
  .blogDate4,
  .blogDate5 {
    position: absolute;
    top: 1229px;
    left: 20px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .blogDate1,
  .blogDate2,
  .blogDate3,
  .blogDate4,
  .blogDate5 {
    top: 1616px;
  }
  .blogDate1,
  .blogDate4,
  .blogDate2,
  .blogDate5 {
    top: 1229px;
    left: 360px;
  }
  .blogDate4,
  .blogDate2,
  .blogDate5 {
    top: 1616px;
  }
  .blogDate2,
  .blogDate5 {
    top: 1229px;
    left: 700px;
  }
  .blogDate5 {
    top: 1616px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .chevronLeftIcon1 {
    left: 391px;
    object-fit: contain;
  }
  .parent {
    position: absolute;
    top: 1821px;
    left: 292px;
    width: 415px;
    height: 26px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }
  .parentLow {
    top: 1631px !important;
  }
  .readMore {
    position: absolute;
    top: 787px;
    left: 512px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .blog {
    position: relative;
    width: 100%;
    height: 2024px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xs);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    a {
      color: var(--color-black);
    }
  }
  .blogTagBox0,
  .blogTagBox1,
  .blogTagBox2,
  .blogTagBox3,
  .blogTagBox4,
  .blogTagBox5 {
    position: absolute;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 280px;
    display: inline-flex;
    overflow: hidden;
  }
  .blogTagBox0 {
    top: 1325px;
    left: 20px;
  }
  .blogTagBox1 {
    top: 1325px;
    left: 360px;
  }
  .blogTagBox2 {
    top: 1325px;
    left: 700px;
  }
  .blogTagBox3 {
    top: 1872px;
    left: 20px;
    z-index: 1;
  }
  .blogTagBox4 {
    top: 1872px;
    left: 360px;
    z-index: 2;
  }
  .blogTagBox5 {
    top: 1872px;
    left: 700px;
    z-index: 2;
  }
  .blogTag {
    border-radius: var(--br-9980xl);
    border: 1px solid var(--color-black);
    background: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    font-size: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    padding: 5px 15px;

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .blog800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 919px;
  }
  .exploreTheWorld {
    position: absolute;
    top: 687px;
    left: 410px;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 26px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 373px;
    height: 84px;
    font-size: 14px;
  }
  .nowToSpot {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nowToSpotContainer {
    position: absolute;
    top: 687px;
    left: 15px;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 375px;
    height: 84px;
  }
  .og {
    font-family: var(--font-bodoni-72);
  }
  .blog {
    top: 112px;
    left: 0;
    font-size: 60px;
    color: var(--color-white);
  }
  .aFocusedInsightful,
  .blog,
  .nov172023 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .aFocusedInsightful {
    top: 176px;
    left: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .nov172023 {
    top: 796px;
    left: 17px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .blog800Item {
    position: absolute;
    top: 242px;
    left: calc(50% - 400px);
    border-radius: 33px;
    background-color: var(--color-gainsboro);
    width: 800px;
    height: 400px;
  }
  .blog800Inner {
    position: absolute;
    top: 796px;
    left: 410px;
    border-radius: 999px;
    background-color: #19d959;
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 120px;
    height: 36px;
  }
  .blog800Child1,
  .blog800Child2,
  .blog800Child3,
  .blog800Child4,
  .blog800Child5,
  .rectangleDiv {
    position: absolute;
    top: 1013px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 253px;
    height: 360px;
  }
  .blog800Child1,
  .blog800Child2,
  .blog800Child3,
  .blog800Child4,
  .blog800Child5 {
    left: 274px;
  }
  .blog800Child2,
  .blog800Child3,
  .blog800Child4,
  .blog800Child5 {
    left: 547px;
  }
  .blog800Child3,
  .blog800Child4,
  .blog800Child5 {
    top: 1560px;
  }
  .blog800Child4,
  .blog800Child5 {
    left: 273px;
  }
  .blog800Child5 {
    left: 0;
  }
  .b,
  .div,
  .div1,
  .div2,
  .div3,
  .div4 {
    position: absolute;
    top: 1px;
    letter-spacing: -0.02em;
  }
  .b {
    left: 101px;
    color: var(--color-black);
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4 {
    left: 142px;
    font-weight: 500;
  }
  .div1,
  .div2,
  .div3,
  .div4 {
    left: 258px;
  }
  .div2,
  .div3,
  .div4 {
    left: 297px;
  }
  .div3,
  .div4 {
    left: 183px;
  }
  .div4 {
    left: 220px;
    font-size: var(--font-size-base);
    letter-spacing: -0.06em;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .chevronLeftIcon1 {
    left: 391px;
    object-fit: contain;
  }
  .parent {
    position: absolute;
    top: 2148px;
    left: 193px;
    width: 415px;
    height: 26px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
  }
  .nowToSpotContainer1,
  .nowToSpotContainer2,
  .nowToSpotContainer3,
  .nowToSpotContainer4,
  .nowToSpotContainer5,
  .nowToSpotContainer6 {
    position: absolute;
    top: 1425px;
    left: 15px;
    font-size: var(--font-size-lg);
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-noto-sans);
    width: 223px;
    height: 48px;
  }
  .nowToSpotContainer2,
  .nowToSpotContainer3,
  .nowToSpotContainer4,
  .nowToSpotContainer5,
  .nowToSpotContainer6 {
    left: 289px;
  }
  .nowToSpotContainer3,
  .nowToSpotContainer4,
  .nowToSpotContainer5,
  .nowToSpotContainer6 {
    left: 562px;
  }
  .nowToSpotContainer4,
  .nowToSpotContainer5,
  .nowToSpotContainer6 {
    top: 1972px;
  }
  .nowToSpotContainer5,
  .nowToSpotContainer6 {
    left: 288px;
  }
  .nowToSpotContainer6 {
    left: 15px;
  }
  .nov1720231,
  .nov1720232,
  .nov1720233,
  .nov1720234,
  .nov1720235,
  .nov1720236 {
    position: absolute;
    top: 1398px;
    left: 15px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .nov1720232,
  .nov1720233,
  .nov1720234,
  .nov1720235,
  .nov1720236 {
    left: 289px;
  }
  .nov1720233,
  .nov1720234,
  .nov1720235,
  .nov1720236 {
    left: 562px;
  }
  .nov1720234,
  .nov1720235,
  .nov1720236 {
    top: 1945px;
  }
  .nov1720235,
  .nov1720236 {
    left: 288px;
  }
  .nov1720236 {
    left: 15px;
  }
  .blog800Child10,
  .blog800Child11,
  .blog800Child12,
  .blog800Child6,
  .blog800Child7,
  .blog800Child8,
  .blog800Child9 {
    position: absolute;
    top: 1337px;
    left: 20px;
    border-radius: var(--br-9980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 53px;
    height: 20px;
  }
  .blog800Child10,
  .blog800Child11,
  .blog800Child12,
  .blog800Child7,
  .blog800Child8,
  .blog800Child9 {
    left: 294px;
  }
  .blog800Child10,
  .blog800Child11,
  .blog800Child12,
  .blog800Child8,
  .blog800Child9 {
    left: 567px;
  }
  .blog800Child10,
  .blog800Child11,
  .blog800Child12,
  .blog800Child9 {
    top: 1884px;
  }
  .blog800Child10,
  .blog800Child11,
  .blog800Child12 {
    left: 293px;
  }
  .blog800Child11,
  .blog800Child12 {
    left: 20px;
  }
  .blog800Child12 {
    top: 1337px;
    left: 78px;
    width: 55px;
  }
  .blog800Child13,
  .blog800Child14,
  .blog800Child15,
  .blog800Child16,
  .blog800Child17,
  .blog800Child18,
  .blog800Child19,
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    position: absolute;
    top: 1337px;
    left: 352px;
    border-radius: var(--br-9980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 55px;
    height: 20px;
  }
  .blog800Child14,
  .blog800Child15,
  .blog800Child16,
  .blog800Child17,
  .blog800Child18,
  .blog800Child19,
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    left: 625px;
  }
  .blog800Child15,
  .blog800Child16,
  .blog800Child17,
  .blog800Child18,
  .blog800Child19,
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    top: 1884px;
  }
  .blog800Child16,
  .blog800Child17,
  .blog800Child18,
  .blog800Child19,
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    left: 351px;
  }
  .blog800Child17,
  .blog800Child18,
  .blog800Child19,
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    left: 78px;
  }
  .blog800Child18,
  .blog800Child19,
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    top: 1337px;
    left: 138px;
  }
  .blog800Child19,
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    left: 412px;
  }
  .blog800Child20,
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    left: 685px;
  }
  .blog800Child21,
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    top: 1884px;
  }
  .blog800Child22,
  .blog800Child23,
  .blog800Child24 {
    left: 411px;
  }
  .blog800Child23,
  .blog800Child24 {
    left: 138px;
  }
  .blog800Child24 {
    top: 1337px;
    left: 818px;
  }
  .dev,
  .dev1,
  .dev2,
  .dev3,
  .dev4,
  .dev5,
  .web3 {
    position: absolute;
    top: 1342px;
    left: 38px;
    letter-spacing: -0.02em;
  }
  .dev1,
  .dev2,
  .dev3,
  .dev4,
  .dev5,
  .web3 {
    left: 312px;
  }
  .dev2,
  .dev3,
  .dev4,
  .dev5,
  .web3 {
    left: 585px;
  }
  .dev3,
  .dev4,
  .dev5,
  .web3 {
    top: 1889px;
  }
  .dev4,
  .dev5,
  .web3 {
    left: 311px;
  }
  .dev5,
  .web3 {
    left: 38px;
  }
  .web3 {
    top: 1342px;
    left: 93px;
  }
  .design,
  .design1,
  .web31,
  .web32,
  .web33,
  .web34,
  .web35 {
    position: absolute;
    top: 1342px;
    left: 367px;
    letter-spacing: -0.02em;
  }
  .design,
  .design1,
  .web32,
  .web33,
  .web34,
  .web35 {
    left: 640px;
  }
  .design,
  .design1,
  .web33,
  .web34,
  .web35 {
    top: 1889px;
  }
  .design,
  .design1,
  .web34,
  .web35 {
    left: 366px;
  }
  .design,
  .design1,
  .web35 {
    left: 93px;
  }
  .design,
  .design1 {
    top: 1342px;
    left: 149px;
  }
  .design1 {
    left: 423px;
  }
  .design2,
  .design3,
  .design4,
  .design5,
  .design6,
  .readMore {
    position: absolute;
    top: 1342px;
    left: 696px;
    letter-spacing: -0.02em;
  }
  .design3,
  .design4,
  .design5,
  .design6,
  .readMore {
    top: 1889px;
  }
  .design4,
  .design5,
  .design6,
  .readMore {
    left: 422px;
  }
  .design5,
  .design6,
  .readMore {
    left: 149px;
  }
  .design6,
  .readMore {
    top: 1342px;
    left: 829px;
  }
  .readMore {
    top: 796px;
    left: 410px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .blog800 {
    position: relative;
    width: 100%;
    height: 2301px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xs);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    a {
      color: var(--color-black);
    }
  }
  .nowToSpot {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .blogImg1,
  .blogImg2,
  .blogImg3,
  .blogImg4,
  .blogImg5,
  .blogImg0 {
    position: absolute;
    top: 1004px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 253px;
    height: 200px;
  }
  .blogImg1,
  .blogImg2,
  .blogImg3,
  .blogImg4,
  .blogImg5 {
    top: 1391px;
  }
  .blogImg1,
  .blogImg4,
  .blogImg2,
  .blogImg5 {
    top: 1004px;
    left: 274px;
  }
  .blogImg4,
  .blogImg2,
  .blogImg5 {
    top: 1391px;
  }
  .blogImg2,
  .blogImg5 {
    top: 1004px;
    left: 547px;
  }
  .blogImg5 {
    top: 1391px;
  }
  .blogTitle0,
  .blogTitle1,
  .blogTitle2,
  .blogTitle3,
  .blogTitle4,
  .blogTitle5 {
    position: absolute;
    top: 1256px;
    left: 15px;
    letter-spacing: -0.02em;
    line-height: 27px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 223px;
    height: 48px;
    font-size: 18px;
  }
  .blogTitle1,
  .blogTitle2,
  .blogTitle3,
  .blogTitle4,
  .blogTitle5 {
    top: 1643px;
  }
  .blogTitle1,
  .blogTitle4,
  .blogTitle2,
  .blogTitle5 {
    top: 1256px;
    left: 294px;
  }
  .blogTitle4,
  .blogTitle2,
  .blogTitle5 {
    top: 1643px;
  }
  .blogTitle2,
  .blogTitle5 {
    top: 1256px;
    left: 567px;
  }
  .blogTitle5 {
    top: 1643px;
  }
  .blogDate0,
  .blogDate1,
  .blogDate2,
  .blogDate3,
  .blogDate4,
  .blogDate5 {
    position: absolute;
    top: 1229px;
    left: 15px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .blogDate1,
  .blogDate2,
  .blogDate3,
  .blogDate4,
  .blogDate5 {
    top: 1616px;
  }
  .blogDate1,
  .blogDate4,
  .blogDate2,
  .blogDate5 {
    top: 1229px;
    left: 294px;
  }
  .blogDate4,
  .blogDate2,
  .blogDate5 {
    top: 1616px;
  }
  .blogDate2,
  .blogDate5 {
    top: 1229px;
    left: 567px;
  }
  .blogDate5 {
    top: 1616px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .chevronLeftIcon1 {
    left: 391px;
    object-fit: contain;
  }
  .parent {
    position: absolute;
    top: 1821px;
    left: 192px;
    width: 415px;
    height: 26px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }
  .parentLow {
    top: 1631px !important;
  }
  .blogTagBox0,
  .blogTagBox1,
  .blogTagBox2,
  .blogTagBox3,
  .blogTagBox4,
  .blogTagBox5 {
    position: absolute;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 220px;
    display: inline-flex;
    overflow: hidden;
  }
  .blogTagBox0 {
    top: 1325px;
    left: 15px;
  }
  .blogTagBox1 {
    top: 1325px;
    left: 294px;
  }
  .blogTagBox2 {
    top: 1325px;
    left: 567px;
  }
  .blogTagBox3 {
    top: 1872px;
    left: 15px;
    z-index: 1;
  }
  .blogTagBox4 {
    top: 1872px;
    left: 294px;
    z-index: 2;
  }
  .blogTagBox5 {
    top: 1872px;
    left: 567px;
    z-index: 2;
  }
  .blogTag {
    border-radius: var(--br-9980xl);
    border: 1px solid var(--color-black);
    background: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    font-size: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    padding: 5px 15px;

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}

@media screen and (max-width: 819px) and (min-width: 500px) {
  .blog500Child {
    top: 1003px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    height: 200px;
  }
  .blog500Child,
  .blog500Inner,
  .blog500Item {
    position: absolute;
    width: 500px;
  }
  .blog500Item {
    top: 1343px;
    left: 1px;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    height: 200px;
  }
  .blog500Inner {
    top: 0;
    background-color: var(--color-black);
    height: 913px;
    width: 100%;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
  }
  .exploreTheWorld {
    position: absolute;
    top: 690px;
    left: 23px;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 440px;
    height: 84px;
    font-size: 16px;
  }
  .nowToSpot {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nowToSpotContainer {
    position: absolute;
    top: 545px;
    left: 23px;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 440px;
    height: 84px;
    font-size: 26px;
  }
  .og {
    font-family: var(--font-bodoni-72);
  }
  .blog {
    top: 75px;
    left: 0;
    font-size: 45px;
    color: var(--color-white);
  }
  .aFocusedInsightful,
  .blog,
  .nov172023 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .aFocusedInsightful {
    top: 134px;
    left: 0;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .nov172023 {
    top: 635px;
    left: 23px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .rectangleDiv {
    position: absolute;
    top: 200px;
    left: calc(50% - 250px);
    border-radius: 33px;
    background-color: var(--color-gainsboro);
    width: 500px;
    height: 300px;
  }
  .blog500Child1 {
    position: absolute;
    top: 799px;
    left: 23px;
    border-radius: 999px;
    background-color: #19d959;
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 120px;
    height: 36px;
  }
  .nowToSpotContainer1,
  .nowToSpotContainer2 {
    position: absolute;
    top: 1255px;
    left: 23px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-noto-sans);
    width: 434px;
  }
  .nowToSpotContainer2 {
    top: 1595px;
    left: 24px;
  }
  .nov1720231,
  .nov1720232 {
    position: absolute;
    top: 1228px;
    left: 23px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .nov1720232 {
    top: 1568px;
    left: 24px;
  }
  .blog500Child2,
  .blog500Child3,
  .blog500Child4,
  .blog500Child5 {
    position: absolute;
    top: 1167px;
    left: 20px;
    border-radius: var(--br-9980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 53px;
    height: 20px;
  }
  .blog500Child3,
  .blog500Child4,
  .blog500Child5 {
    top: 1507px;
    left: 21px;
  }
  .blog500Child4,
  .blog500Child5 {
    top: 1167px;
    left: 83px;
    width: 55px;
  }
  .blog500Child5 {
    top: 1507px;
    left: 84px;
  }
  .dev {
    position: absolute;
    top: 1172px;
    left: 38px;
    letter-spacing: -0.02em;
  }
  .dev,
  .dev1,
  .web3 {
    font-size: var(--font-size-3xs);
  }
  .dev1 {
    position: absolute;
    top: 1512px;
    left: 39px;
    letter-spacing: -0.02em;
  }
  .web3 {
    top: 1172px;
    left: 98px;
  }
  .readMore,
  .web3,
  .web31 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .web31 {
    top: 1512px;
    left: 99px;
    font-size: var(--font-size-3xs);
  }
  .readMore {
    top: 800px;
    left: 23px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .blog500 {
    position: relative;
    width: 100%;
    // height: 1972px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }

  .newsWrapper {
    position: relative;
    top: 913px;
    margin-bottom: 963px;
    a {
      text-decoration: none;
    }
  }
  .newsImg {
    width: 100%;
    height: 200px;
    border-radius: 30px;
    background-color: #d9d9d9;
    margin-top: 50px;
    margin-bottom: -40px;
  }
  .newsDate {
    margin-top: 65px;
    margin-left: 22px;
    color: #000;
    font-family: Geomanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-decoration: none;
  }
  .newsTitle {
    margin-top: 17px;
    margin-left: 22px;
    margin-right: 22px;
    color: #000;
    font-family: var(--font-inter);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.36px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .parent {
    width: 500px;
    height: 26px;
    margin-bottom: 145px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }

  .blogTagBox {
    position: absolute;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
    width: calc(100% - 44px);
    display: inline-flex;
    overflow: hidden;
    margin-left: 22px;
  }
  .blogTag {
    border-radius: var(--br-9980xl);
    border: 1px solid var(--color-black);
    background: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    font-size: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    padding: 5px 15px;

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
@media screen and (max-width: 500px) {
  .blog360Child,
  .blog360Item {
    position: absolute;
    top: 973px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 359px;
    height: 200px;
  }
  .blog360Item {
    top: 1313px;
    left: 1px;
  }
  .blog360Inner {
    position: absolute;
    top: 0;
    background-color: var(--color-black);
    width: 100%;
    height: 913px;
  }
  .exploreTheWorld {
    position: absolute;
    top: 690px;
    left: 11px;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 349px;
  }
  .nowToSpot {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nowToSpotContainer {
    position: absolute;
    top: 545px;
    left: 11px;
    font-size: 23px;
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    font-family: var(--font-inter);
    color: var(--color-white);
    width: 349px;
    height: 84px;
  }
  .og {
    font-family: var(--font-bodoni-72);
  }
  .blog {
    top: 79px;
    left: 0;
    font-size: 35px;
    color: var(--color-white);
  }
  .aFocusedInsightful,
  .blog,
  .nov172023 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .aFocusedInsightful {
    top: 134px;
    left: 0;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .nov172023 {
    top: 635px;
    left: 11px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: #adadad;
    display: flex;
    align-items: center;
    width: 91px;
  }
  .rectangleDiv {
    position: absolute;
    top: 200px;
    left: calc(50% - 180px);
    border-radius: 33px;
    background-color: var(--color-gainsboro);
    width: 360px;
    height: 300px;
  }
  .blog360Child1 {
    position: absolute;
    top: 799px;
    left: 11px;
    border-radius: 999px;
    background-color: #19d959;
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 120px;
    height: 36px;
  }
  .nowToSpotContainer1,
  .nowToSpotContainer2 {
    position: absolute;
    top: 1225px;
    left: 11px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-noto-sans);
    width: 434px;
  }
  .nowToSpotContainer2 {
    top: 1565px;
    left: 12px;
  }
  .nov1720231 {
    top: 1198px;
    left: 11px;
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;
    width: 91px;
  }
  .nov1720231,
  .nov1720232,
  .readMore {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .nov1720232 {
    top: 1538px;
    left: 12px;
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;
    width: 91px;
  }
  .readMore {
    top: 800px;
    left: 10px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTMwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTMwIDEwLjExMzlIMTI3LjUwOVY2LjI5MDJIMTI0LjYwOVYyLjUzMTI1SDEyMS4yOVYwTDguNzEwMzggMFYyLjUzMTI1SDUuMzkxNDdWNi4yOTAySDIuNDkwNTNWMTAuMTEzOUgwVjI1Ljg4NzZIMi40OTA1M1YyOS43MDk4SDUuMzkxNDdWMzMuNDY4OEg4LjcxMDM4VjM2SDEyMS4yOVYzMy40Njg4SDEyNC42MDlWMjkuNzA5OEgxMjcuNTA5VjI1Ljg4NzZIMTMwVjEwLjExMzlaIiBmaWxsPSIjMTlEOTU5Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 130px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .blog360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    // height: 1726px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }

  .newsWrapper {
    position: relative;
    top: 913px;
    margin-bottom: 963px;
    a {
      text-decoration: none;
    }
  }
  .newsImg {
    width: 100%;
    height: 200px;
    border-radius: 30px;
    background-color: #d9d9d9;
    margin-top: 50px;
    margin-bottom: -40px;
  }
  .newsDate {
    margin-top: 65px;
    margin-left: 22px;
    color: #000;
    font-family: Geomanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-decoration: none;
  }
  .newsTitle {
    margin-top: 17px;
    margin-left: 22px;
    margin-right: 22px;
    color: #000;
    font-family: var(--font-inter);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.36px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .parent {
    width: 100%;
    height: 26px;
    margin-bottom: 145px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }

  .blogTagBox {
    position: absolute;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
    width: calc(100% - 44px);
    display: inline-flex;
    overflow: hidden;
    margin-left: 22px;
  }
  .blogTag {
    border-radius: var(--br-9980xl);
    border: 1px solid var(--color-black);
    background: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    font-size: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    padding: 5px 15px;

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
a {
  color: var(--color-black);
}
