@media screen and (max-width: 819px) and (min-width: 500px) {
  .presskit500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 300px;
  }
  .exploreTheWorld {
    position: absolute;
    top: 182px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #d5d5d5;
    display: none;
    width: 457px;
  }
  .kit {
    font-family: var(--font-bodoni-72);
  }
  .pressKit {
    top: 75px;
    left: 0;
    font-size: 45px;
    color: var(--color-white);
  }
  .allYourMedia,
  .brandIdentity,
  .pressKit {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .allYourMedia {
    top: 135px;
    left: 0;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .brandIdentity {
    top: 381px;
    left: 6px;
    font-size: 32px;
    line-height: 47px;
    text-align: center;
  }
  .monoLogo,
  .signitureLogo {
    left: 6px;
    font-size: 28px;
    line-height: 35px;
  }
  .signitureLogo {
    position: absolute;
    top: 503px;
    letter-spacing: -0.02em;
    font-family: var(--font-inter);
  }
  .monoLogo {
    top: 1064px;
  }
  .aLogoCan,
  .characterLogo,
  .itCanBe,
  .itCanBe1,
  .monoLogo {
    position: absolute;
    letter-spacing: -0.02em;
    font-family: var(--font-inter);
  }
  .characterLogo {
    top: 1925px;
    left: 6px;
    font-size: 28px;
    line-height: 35px;
  }
  .aLogoCan,
  .itCanBe,
  .itCanBe1 {
    top: 570px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 500px;
  }
  .itCanBe,
  .itCanBe1 {
    top: 1131px;
  }
  .itCanBe1 {
    top: 1992px;
    font-size: 14px;
    line-height: 28px;
  }
  .presskit500Child1,
  .presskit500Inner,
  .presskit500Item,
  .rectangleDiv {
    position: absolute;
    top: 700px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-white);
    width: 500px;
    height: 280px;
  }
  .presskit500Child1,
  .presskit500Inner,
  .rectangleDiv {
    top: 2122px;
  }
  .presskit500Child1,
  .rectangleDiv {
    top: 1561px;
    background-color: var(--color-black);
  }
  .presskit500Child1 {
    top: 2422px;
    background-color: var(--color-white);
  }
  .download,
  .download1 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .download {
    top: 937px;
    left: 385px;
    text-decoration: underline;
    line-height: 47px;
    text-align: center;
  }
  .download1 {
    top: 1498px;
  }
  .download1,
  .download2,
  .download3 {
    left: 385px;
    text-decoration: underline;
    line-height: 47px;
    text-align: center;
  }
  .download2 {
    position: absolute;
    top: 2359px;
    letter-spacing: -0.02em;
  }
  .download3 {
    top: 1798px;
    color: var(--color-white);
  }
  .brandGuideBook,
  .download3,
  .download4,
  .ifYouWant {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .download4 {
    top: 2659px;
    left: 385px;
    text-decoration: underline;
    line-height: 47px;
    text-align: center;
  }
  .brandGuideBook,
  .ifYouWant {
    left: 0;
  }
  .brandGuideBook {
    top: 2830px;
    font-size: 34px;
    line-height: 47px;
  }
  .ifYouWant {
    top: 2899px;
    font-size: 14px;
    line-height: 28px;
    font-family: var(--font-inter);
    display: inline-block;
    width: 500px;
  }
  .maskGroupIcon {
    position: absolute;
    top: 3029px;
    left: 2px;
    width: 490px;
    height: 280px;
    object-fit: cover;
  }
  .download6 {
    position: absolute;
    top: 3266px;
    left: 377px;
    text-decoration: underline;
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .groupIcon,
  .presskit500Child2 {
    position: absolute;
    top: 1677px;
    left: 80px;
    width: 339.15px;
    height: 48.45px;
  }
  .presskit500Child2 {
    top: 816px;
  }
  .presskit500Child3 {
    position: absolute;
    top: 2511px;
    left: 55px;
    width: 390px;
    height: 102px;
  }
  .presskit500Child4 {
    position: absolute;
    top: 2177px;
    left: 145px;
    width: 209.8px;
    height: 169.52px;
  }
  .presskit500Child5 {
    position: absolute;
    height: 1.41%;
    width: 67.83%;
    top: 40.03%;
    right: 16.17%;
    bottom: 58.56%;
    left: 16%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .presskit500 {
    position: relative;
    width: 100%;
    height: 3440px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
}
@media screen and (max-width: 500px) {
  .presskit360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 300px;
  }
  .exploreTheWorld {
    position: absolute;
    top: 182px;
    left: 0;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #d5d5d5;
    display: none;
    width: 457px;
  }
  .kit {
    font-family: var(--font-bodoni-72);
  }
  .pressKit {
    top: 79px;
    left: 0;
    font-size: 35px;
    color: var(--color-white);
  }
  .allYourMedia,
  .brandIdentity,
  .pressKit {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .allYourMedia {
    top: 135px;
    left: 0;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .brandIdentity {
    top: 381px;
    left: 6px;
    font-size: 32px;
    line-height: 47px;
    text-align: center;
  }
  .characterLogo,
  .monoLogo,
  .signitureLogo {
    position: absolute;
    top: 458px;
    left: 0;
    font-size: 23px;
    letter-spacing: -0.02em;
    line-height: 35px;
    font-family: var(--font-inter);
  }
  .characterLogo,
  .monoLogo {
    top: 967px;
  }
  .characterLogo {
    top: 1681px;
    left: 6px;
  }
  .aLogoCan {
    margin: 0;
  }
  .aLogoCanContainer,
  .itCanBe,
  .itCanBe1 {
    position: absolute;
    left: 0;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 25px;
    font-family: var(--font-inter);
    display: inline-block;
    width: 100%;
  }
  .aLogoCanContainer {
    top: 525px;
    height: 96px;
    width: 100%;
  }
  .itCanBe,
  .itCanBe1 {
    top: 1034px;
  }
  .itCanBe1 {
    top: 1748px;
  }
  .presskit360Child1,
  .presskit360Child2,
  .presskit360Inner,
  .presskit360Item,
  .rectangleDiv {
    position: absolute;
    top: 681px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-white);
    width: 360px;
    left: calc(50% - 180px);
    height: 202px;
  }
  .presskit360Child1,
  .presskit360Child2,
  .presskit360Inner,
  .rectangleDiv {
    top: 1181px;
    height: 201px;
  }
  .presskit360Child1,
  .presskit360Child2,
  .rectangleDiv {
    top: 1895px;
  }
  .presskit360Child1,
  .presskit360Child2 {
    top: 1396px;
    background-color: var(--color-black);
  }
  .presskit360Child2 {
    top: 2111px;
    background-color: var(--color-white);
  }
  .download,
  .download1 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .download {
    top: 841px;
    left: calc(50% + 80px);
    text-decoration: underline;
    line-height: 47px;
    text-align: center;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .download1 {
    top: 1340px;
  }
  .download1,
  .download2,
  .download3 {
    left: calc(50% + 80px);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    text-decoration: underline;
    line-height: 47px;
    text-align: center;
  }
  .download2 {
    position: absolute;
    top: 1555px;
    letter-spacing: -0.02em;
    color: var(--color-white);
  }
  .download3 {
    top: 2053px;
  }
  .brandGuideBook,
  .download3,
  .download4,
  .ifYouWant {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .download4 {
    top: 2269px;
    left: calc(50% + 80px);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    text-decoration: underline;
    line-height: 47px;
    text-align: center;
  }
  .brandGuideBook,
  .ifYouWant {
    left: 0;
  }
  .brandGuideBook {
    top: 2396px;
    font-size: 34px;
    line-height: 47px;
  }
  .ifYouWant {
    top: 2465px;
    font-size: 14px;
    line-height: 25px;
    font-family: var(--font-inter);
    display: inline-block;
    width: 100%;
  }
  .maskGroupIcon {
    position: absolute;
    top: 2612px;
    left: calc(50% - 179px);
    width: 358px;
    height: 204.57px;
    object-fit: cover;
  }
  .download5 {
    position: absolute;
    top: 2773px;
    left: calc(50% + 80px);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    text-decoration: underline;
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .groupIcon {
    position: absolute;
    top: 762px;
    left: calc(50% - 143px);
    width: 286px;
    height: 40.85px;
  }
  .presskit360Child3 {
    position: absolute;
    height: 41px;
    width: 286px;
    left: calc(50% - 143px);
    top: 1261px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .presskit360Child4 {
    position: absolute;
    top: 1476px;
    left: 37px;
    width: 286px;
    left: calc(50% - 143px);
    height: 40.85px;
  }
  .presskit360Child5 {
    position: absolute;
    top: 1936px;
    left: calc(50% - 74px);
    width: 148px;
    height: 119.8px;
  }
  .presskit360Child6 {
    position: absolute;
    top: 2178.52px;
    left: calc(50% - 127px);
    width: 254px;
    height: 66.43px;
  }
  .presskit360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 2950px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-smi);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
}
