@media screen and (max-width: 819px) and (min-width: 500px) {
  .hippocratIsNot,
  .theExistenceOf {
    position: absolute;
    line-height: 25px;
    display: inline-block;
  }
  .hippocratIsNot {
    top: 1505px;
    left: 25px;
    width: 432px;
    height: 354px;
    font-size: 14px;
  }
  .theExistenceOf {
    top: 1039px;
    left: 23px;
    width: 434px;
    font-size: 14px;
  }
  .hippocratWithP2p,
  .whyP2pProtocol {
    position: absolute;
    top: 1405px;
    left: 23px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
  }
  .whyP2pProtocol {
    top: 929px;
  }
  .learn500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 869px;
  }
  .definitionOfP2p,
  .protocolIsPreDefined {
    position: absolute;
    top: 670px;
    left: 26px;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 431px;
    font-size: 14px;
  }
  .definitionOfP2p {
    top: 560px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    width: 390px;
  }
  .protocol {
    font-family: var(--font-bodoni-72);
  }
  .p2pProtocol,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .p2pProtocol {
    top: 75px;
    font-size: 45px;
    color: var(--color-white);
  }
  .theComingFuture {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn500Inner {
    position: absolute;
    top: 1344.5px;
    left: calc(50% - 227.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 455px;
    height: 1px;
  }
  .previousUseCaseContainer {
    position: absolute;
    top: 1880px;
    width: 300px;
    left: 81px;
    font-size: 27px;
    letter-spacing: -0.02em;
  }
  .healthLevel7 {
    position: absolute;
    top: 1925px;
    left: 187px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 1918px;
    left: 0;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .maskGroupIcon {
    position: absolute;
    top: 200px;
    left: calc(50% - 250px);
    width: 500px;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn500 {
    position: relative;
    width: 100%;
    height: 2155px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 500px) {
  .hippocratIsNot,
  .theExistenceOf {
    position: absolute;
    top: 1427px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .theExistenceOf {
    top: 956px;
  }
  .hippocratWithP2p,
  .whyP2pProtocol {
    position: absolute;
    top: 1352px;
    left: 0;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 35px;
  }
  .whyP2pProtocol {
    top: 881px;
  }
  .learn360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 821px;
  }
  .definitionOfP2p,
  .protocolIsPreDefined {
    position: absolute;
    top: 635px;
    left: 0;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .definitionOfP2p {
    top: 560px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 35px;
    width: 390px;
    font-weight: 700;
    font-family: var(--font-geomanist);
  }
  .protocol {
    font-family: var(--font-bodoni-72);
  }
  .p2pProtocol,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .p2pProtocol {
    top: 79px;
    font-size: 35px;
    color: var(--color-white);
  }
  .theComingFuture {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn360Item {
    position: absolute;
    top: 1291.5px;
    left: 0;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .previousUseCaseContainer {
    position: absolute;
    top: 1738px;
    width: 280px;
    left: 58px;
    font-size: 27px;
    letter-spacing: -0.02em;
  }
  .healthLevel7 {
    position: absolute;
    top: 1783px;
    left: 165px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 1776px;
    left: 0;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .maskGroupIcon {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 1957px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
}
