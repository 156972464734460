@media screen and (min-width: 1020px) {
  .maskGroupIcon {
    position: absolute;
    top: 2365px;
    left: 65px;
    width: 870px;
    height: 516px;
    object-fit: contain;
  }
  .p2pChild {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 2278px;
  }
  .efficientDataCommunication,
  .highSecurityWithout,
  .reducedNetworkTraffic {
    position: absolute;
    top: 1385px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    width: 270px;
    height: 100px;
  }
  .efficientDataCommunication,
  .reducedNetworkTraffic {
    left: 355px;
  }
  .reducedNetworkTraffic {
    left: 710px;
  }
  .theAdvantagesOf {
    margin: 0;
  }
  .technology {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theAdvantagesOfContainer1 {
    width: 100%;
  }
  .hippocratP2pStorageContainer,
  .theAdvantagesOfContainer {
    position: absolute;
    top: 1057px;
    left: 0;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 430px;
  }
  .hippocratP2pStorageContainer {
    top: 1637px;
  }
  .storage {
    font-family: var(--font-bodoni-72);
  }
  .p2pStorage {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 68px;
    letter-spacing: -0.02em;
  }
  .decentralizedStorageSystem {
    position: absolute;
    top: 167px;
    left: 0;
    font-size: var(--font-size-lg);
    letter-spacing: -0.02em;
    text-transform: capitalize;
    font-size: 22px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    text-align: center;
  }
  .p2pDataTransmission {
    position: absolute;
    top: 244px;
    left: 0;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    display: inline-block;
    width: 575px;
    height: 166px;
  }
  .boostsTheEfficiency,
  .providesAReliable {
    font-size: var(--font-size-lg);
    color: var(--color-gainsboro);
  }
  .boostsTheEfficiency {
    position: absolute;
    top: 2041px;
    left: 0;
    line-height: 28px;
    display: inline-block;
    width: 270px;
    font-size: 16px;
  }
  .providesAReliable {
    top: 2013px;
    left: 355px;
    font-size: 16px;
  }
  .highEfficiencyLargeFile,
  .providesAReliable,
  .utilizesP2pCommunication {
    position: absolute;
    line-height: 28px;
    display: inline-block;
    width: 270px;
  }
  .utilizesP2pCommunication {
    top: 2013px;
    left: 710px;
    font-size: var(--font-size-lg);
    color: var(--color-gainsboro);
    font-size: 16px;
  }
  .highEfficiencyLargeFile {
    top: 1965px;
    left: 0;
    letter-spacing: -0.02em;
  }
  .realTimeStreaming {
    top: 1965px;
    left: 355px;
    letter-spacing: -0.02em;
    width: 270px;
  }
  .distributedDataStorage,
  .p2pCommunicationUsed,
  .realTimeStreaming {
    position: absolute;
    line-height: 28px;
    display: inline-block;
  }
  .distributedDataStorage {
    top: 1965px;
    left: 710px;
    letter-spacing: -0.02em;
    width: 270px;
  }
  .p2pCommunicationUsed {
    top: 580px;
    left: 510px;
    font-size: 17px;
    color: var(--color-gainsboro);
    width: 490px;
    font-size: 17px;
  }
  .p2pInner,
  .p2pItem {
    position: absolute;
    top: 1742.5px;
    left: calc(50% - 500.5px);
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 1001px;
    height: 1px;
  }
  .p2pInner {
    top: 1162.5px;
  }
  .openSource {
    top: 2504px;
    left: 379px;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .developedOpenlyAcrossContainer,
  .github,
  .openSource {
    position: absolute;
    color: var(--color-black);
  }
  .openSourceImg {
    position: absolute;
    top: 2380px;
    left: 65px;
    width: 857px;
    height: 508px;
    object-fit: cover;
    border-radius: 30px;
  }
  .github {
    top: 2562px;
    left: 457px;
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .developedOpenlyAcrossContainer {
    top: 2618px;
    left: 115px;
    font-size: var(--font-size-15xl);
    line-height: 47px;
    display: inline-block;
    text-align: center;
    width: 754px;
    height: 209px;
  }
  .maskGroupIcon1 {
    position: absolute;
    width: 371px;
    height: 347px;
    top: 546px;
    left: calc(50% - 466px);
    object-fit: cover;
  }
  .maskGroupIcon7 {
    position: absolute;
    top: 1203px;
    left: 748px;
    width: 152px;
    height: 152px;
    object-fit: cover;
  }
  .maskGroupIcon2,
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5 {
    position: absolute;
    top: 1783px;
    left: 18px;
    width: 152px;
    height: 152px;
    object-fit: cover;
  }
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5 {
    top: 1784px;
    left: 377px;
  }
  .maskGroupIcon4,
  .maskGroupIcon5 {
    top: 1203px;
    left: 18px;
  }
  .maskGroupIcon5 {
    top: 1204px;
    left: 381px;
  }
  .maskGroupIcon6 {
    position: absolute;
    top: 1784px;
    left: 748px;
    width: 152px;
    height: 152px;
    object-fit: cover;
  }
  .p2p {
    position: relative;
    width: 100%;
    height: 3150px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);

    img {
      pointer-events: none;
    }
  }
  .maskGroupIcon1,
  .rectangleDiv {
    position: absolute;
    width: 371px;
    height: 347px;
  }
  .maskGroupIcon1 {
    top: 546px;
    left: calc(50% - 466px);
    object-fit: cover;
  }
  .rectangleDiv {
    top: 100px;
    left: calc(50% + 95px);
    border-radius: 18px;
    background-color: #f6f8f9;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .p2p800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 2230px;
  }
  .maskGroupIcon {
    position: absolute;
    top: 496px;
    left: calc(50% - 400px);
    width: 371px;
    height: 347px;
    object-fit: cover;
  }
  .maskGroupIcon1 {
    position: absolute;
    top: 2320px;
    left: 68px;
    width: 664px;
    height: 516px;
    object-fit: fill;
    border-radius: var(--br-11xl);
  }
  .efficientDataCommunication,
  .highSecurityWithout,
  .reducedNetworkTraffic {
    position: absolute;
    top: 1380px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    width: 253px;
    height: 100px;
  }
  .efficientDataCommunication,
  .reducedNetworkTraffic {
    left: 273px;
    width: 254px;
  }
  .reducedNetworkTraffic {
    left: 547px;
    width: 253px;
  }
  .theAdvantagesOf {
    margin: 0;
  }
  .technology {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theAdvantagesOfContainer1 {
    width: 100%;
  }
  .hippocratP2pStorageContainer,
  .theAdvantagesOfContainer {
    position: absolute;
    top: 1056px;
    left: 0;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    width: 390px;
  }
  .hippocratP2pStorageContainer {
    top: 1632px;
    width: 430px;
  }
  .storage {
    font-family: var(--font-bodoni-72);
  }
  .decentralizedStorageSystem,
  .p2pStorage {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 60px;
    letter-spacing: -0.02em;
  }
  .decentralizedStorageSystem {
    top: 164px;
    left: 0;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    text-align: center;
  }
  .p2pDataTransmission {
    position: absolute;
    top: 241px;
    left: 0;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 595px;
    height: 166px;
  }
  .boostsTheEfficiency,
  .providesAReliable,
  .utilizesP2pCommunication {
    position: absolute;
    top: 2032px;
    left: 0;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 253px;
    font-size: 14px;
  }
  .providesAReliable,
  .utilizesP2pCommunication {
    left: 273px;
    width: 254px;
  }
  .utilizesP2pCommunication {
    left: 547px;
    width: 253px;
  }
  .highEfficiencyLargeFile {
    position: absolute;
    top: 1956px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 28px;
    display: inline-block;
    width: 270px;
  }
  .realTimeStreaming {
    top: 1956px;
    left: 273px;
    letter-spacing: -0.02em;
    width: 270px;
  }
  .distributedDataStorage,
  .p2pCommunicationUsed,
  .realTimeStreaming {
    position: absolute;
    line-height: 28px;
    display: inline-block;
  }
  .distributedDataStorage {
    top: 1956px;
    left: 547px;
    letter-spacing: -0.02em;
    width: 270px;
  }
  .p2pCommunicationUsed {
    top: 577px;
    left: 410px;
    font-size: 19px;
    color: var(--color-gainsboro);
    width: 390px;
    font-size: 18px;
  }
  .p2p800Inner,
  .p2p800Item {
    position: absolute;
    top: 1733.5px;
    left: calc(50% - 400.5px);
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 801px;
    height: 1px;
  }
  .p2p800Inner {
    top: 1157.5px;
  }
  .openSource {
    top: 2447px;
    left: 305px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .developedOpenlyAcrossContainer,
  .github,
  .openSource {
    position: absolute;
    color: var(--color-black);
  }
  .github {
    top: 2505px;
    left: 373px;
    font-size: 22px;
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .developedOpenlyAcrossContainer {
    top: 2561px;
    left: calc(50% - 283px);
    font-size: var(--font-size-13xl);
    line-height: 46px;
    display: inline-block;
    text-align: center;
    width: 566px;
    height: 209px;
  }
  .p2p800 {
    position: relative;
    width: 100%;
    height: 3026px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
  .technology1 {
    position: absolute;
    top: 1200px;
    left: 0;
    width: 152px;
    height: 152px;
  }
  .technology2 {
    position: absolute;
    top: 1200px;
    left: 273px;
    width: 152px;
    height: 152px;
  }
  .technology3 {
    position: absolute;
    top: 1200px;
    left: 547px;
    width: 152px;
    height: 152px;
  }
  .technology4 {
    position: absolute;
    top: 1775px;
    left: 0;
    width: 152px;
    height: 152px;
  }
  .technology5 {
    position: absolute;
    top: 1775px;
    left: 273px;
    width: 152px;
    height: 152px;
  }
  .technology6 {
    position: absolute;
    top: 1775px;
    left: 547px;
    width: 152px;
    height: 152px;
  }
}
