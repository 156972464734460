@media screen and (max-width: 819px) and (min-width: 500px) {
  .ourPioneerJourney {
    position: absolute;
    top: 134px;
    left: calc(50% - 250px);
    color: #00d308;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .ourPioneerJourney,
  .r {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.07em;
  }
  .ad {
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .roadMap {
    position: absolute;
    top: 75px;
    left: calc(50% - 250px);
    font-size: 45px;
    color: var(--color-black);
  }
  .theJourneyOf {
    position: absolute;
    top: 325px;
    left: calc(50% - 250px);
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #484848;
    display: inline-block;
    width: 390px;
    height: 166px;
    font-size: 14px;
  }
  .joinTheJourney {
    margin: 0;
  }
  .joinTheJourneyContainer {
    position: absolute;
    top: 211px;
    left: calc(50% - 250px);
    font-size: 32px;
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    color: var(--color-black);
    width: 413px;
    height: 105px;
  }
  .rectangleDiv,
  .roadmap500Child,
  .roadmap500Child1,
  .roadmap500Inner,
  .roadmap500Item {
    position: absolute;
    top: 864px;
    left: 88px;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 325px;
    height: 390px;
  }
  .rectangleDiv,
  .roadmap500Child1,
  .roadmap500Inner,
  .roadmap500Item {
    top: 2472px;
  }
  .rectangleDiv,
  .roadmap500Child1,
  .roadmap500Inner {
    top: 1668px;
  }
  .rectangleDiv,
  .roadmap500Child1 {
    top: 1266px;
  }
  .roadmap500Child1 {
    top: 2070px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q,
  .q1,
  .q2,
  .q3,
  .q4 {
    position: absolute;
    top: 958px;
    left: 131px;
    letter-spacing: -0.02em;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q1,
  .q2,
  .q3,
  .q4 {
    top: 2566px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q2,
  .q3,
  .q4 {
    top: 1762px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q3,
  .q4 {
    top: 1360px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q4 {
    top: 2164px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4 {
    top: 921px;
    font-size: var(--font-size-6xl);
    font-weight: 300;
  }
  .div1,
  .div2,
  .div3,
  .div4 {
    top: 2529px;
  }
  .div2,
  .div3,
  .div4 {
    top: 1725px;
  }
  .div3,
  .div4 {
    top: 1323px;
  }
  .div4 {
    top: 2127px;
  }
  .proofOfConcept,
  .proofOfConcept1,
  .proofOfConcept2,
  .proofOfConcept3,
  .proofOfConcept4 {
    position: absolute;
    top: 1065px;
    left: 131px;
    font-size: var(--font-size-2xl);
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    color: var(--color-white);
    width: 239px;
  }
  .proofOfConcept1,
  .proofOfConcept2,
  .proofOfConcept3,
  .proofOfConcept4 {
    top: 2673px;
  }
  .proofOfConcept2,
  .proofOfConcept3,
  .proofOfConcept4 {
    top: 1869px;
  }
  .proofOfConcept3,
  .proofOfConcept4 {
    top: 1467px;
  }
  .proofOfConcept4 {
    top: 2271px;
  }
  .roadmap500Child2 {
    position: absolute;
    top: 533px;
    left: calc(50% - 154px);
    border-radius: 18px;
    background-color: #f6f8f9;
    width: 308px;
    height: 308px;
  }
  .roadmap500 {
    position: relative;
    width: 100%;
    // height: 3296px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-16xl);
    color: var(--color-limegreen);
    font-family: var(--font-geomanist);
    margin-top: -450px;
  }
  .roadmapWrapper {
    position: relative;
    width: 100%;
    margin-top: 600px;
    margin-bottom: 135px;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .roadmapBox {
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 325px;
    height: 390px;
    margin: 0 auto;
    img {
      width: 110px;
      height: 121px;
      float: right;
      pointer-events: none;
    }
    div {
      margin-top: 32px;
      margin-left: 43px;
      font-family: Geomanist;
      font-size: 25px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.5px;
    }
    b {
      margin-top: 12px;
      margin-left: 48px;
      color: #00d308;
      font-family: Geomanist;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.7px;
    }
    p {
      width: 240px;
      margin-top: 14px;
      margin-left: 43px;
      color: #fff;
      font-family: Geomanist;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 33px;
      letter-spacing: -0.42px;
      margin-bottom: 20px;
    }
    li {
      color: #fff;
      font-family: Geomanist;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.24px;
      margin: 0 43px 0 43px;
    }
  }
}
@media screen and (max-width: 500px) {
  .ourPioneerJourney {
    color: #00d308;
    position: absolute;
    top: 134px;
    left: 0;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .ourPioneerJourney,
  .r {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.07em;
  }
  .ad {
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .roadMap {
    position: absolute;
    top: 79px;
    left: 0;
    color: var(--color-black);
  }
  .theJourneyOf {
    position: absolute;
    top: 325px;
    left: 0;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #484848;
    display: inline-block;
    width: 100%;
    height: 166px;
  }
  .joinTheJourney {
    margin: 0;
  }
  .joinTheJourneyContainer {
    position: absolute;
    top: 211px;
    left: 0;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 40px;
    display: inline-block;
    color: var(--color-black);
    width: 359px;
    height: 105px;
  }
  .rectangleDiv,
  .roadmap500Child,
  .roadmap500Child1,
  .roadmap500Inner,
  .roadmap500Item {
    position: absolute;
    top: 864px;
    left: 88px;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 325px;
    height: 390px;
  }
  .rectangleDiv,
  .roadmap500Child1,
  .roadmap500Inner,
  .roadmap500Item {
    top: 2472px;
  }
  .rectangleDiv,
  .roadmap500Child1,
  .roadmap500Inner {
    top: 1668px;
  }
  .rectangleDiv,
  .roadmap500Child1 {
    top: 1266px;
  }
  .roadmap500Child1 {
    top: 2070px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q,
  .q1,
  .q2,
  .q3,
  .q4 {
    position: absolute;
    top: 958px;
    left: 131px;
    letter-spacing: -0.02em;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q1,
  .q2,
  .q3,
  .q4 {
    top: 2566px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q2,
  .q3,
  .q4 {
    top: 1762px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q3,
  .q4 {
    top: 1360px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4,
  .q4 {
    top: 2164px;
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4 {
    top: 921px;
    font-size: var(--font-size-6xl);
    font-weight: 300;
  }
  .div1,
  .div2,
  .div3,
  .div4 {
    top: 2529px;
  }
  .div2,
  .div3,
  .div4 {
    top: 1725px;
  }
  .div3,
  .div4 {
    top: 1323px;
  }
  .div4 {
    top: 2127px;
  }
  .proofOfConcept,
  .proofOfConcept1,
  .proofOfConcept2,
  .proofOfConcept3,
  .proofOfConcept4 {
    position: absolute;
    top: 1065px;
    left: 131px;
    font-size: var(--font-size-2xl);
    letter-spacing: -0.02em;
    line-height: 33px;
    display: inline-block;
    color: var(--color-white);
    width: 239px;
  }
  .proofOfConcept1,
  .proofOfConcept2,
  .proofOfConcept3,
  .proofOfConcept4 {
    top: 2673px;
  }
  .proofOfConcept2,
  .proofOfConcept3,
  .proofOfConcept4 {
    top: 1869px;
  }
  .proofOfConcept3,
  .proofOfConcept4 {
    top: 1467px;
  }
  .proofOfConcept4 {
    top: 2271px;
  }
  .roadmap500Child2 {
    position: absolute;
    top: 533px;
    left: calc(50% - 154px);
    border-radius: 18px;
    background-color: #f6f8f9;
    width: 308px;
    height: 308px;
  }
  .roadmap500 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    // height: 3296px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-16xl);
    color: var(--color-limegreen);
    font-family: var(--font-geomanist);
    margin-top: -450px;
  }
  .roadmapWrapper {
    position: relative;
    width: 100%;
    margin-top: 600px;
    margin-bottom: 135px;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .roadmapBox {
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 325px;
    height: 390px;
    margin: 0 auto;
    img {
      width: 110px;
      height: 121px;
      float: right;
      pointer-events: none;
    }
    div {
      margin-top: 32px;
      margin-left: 43px;
      font-family: Geomanist;
      font-size: 25px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.5px;
    }
    b {
      margin-top: 12px;
      margin-left: 48px;
      color: #00d308;
      font-family: Geomanist;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.7px;
    }
    p {
      width: 240px;
      margin-top: 14px;
      margin-left: 43px;
      color: #fff;
      font-family: Geomanist;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 33px;
      letter-spacing: -0.42px;
      margin-bottom: 20px;
    }
    li {
      color: #fff;
      font-family: Geomanist;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.24px;
      margin: 0 43px 0 43px;
    }
  }
}
