@media screen and (max-width: 819px) and (min-width: 500px) {
  .hippocratIsThe {
    margin: 0;
  }
  .hippocratIsTheContainer,
  .web10Container {
    position: absolute;
    top: 1916px;
    left: 25px;
    line-height: 25px;
    display: inline-block;
    width: 432px;
    font-size: 14px;
  }
  .web10Container {
    top: 1400px;
    left: 23px;
    width: 434px;
    font-size: 14px;
  }
  .whatIsWeb3,
  .whyHippocrat {
    position: absolute;
    top: 1816px;
    left: 23px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 35px;
  }
  .whatIsWeb3 {
    top: 1300px;
  }
  .learn500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1240px;
  }
  .blockchainIsA,
  .forMostOf,
  .inThisWay,
  .whatIsBlockchain {
    position: absolute;
    top: 660px;
    left: 26px;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 431px;
    font-size: 14px;
  }
  .blockchainIsA,
  .inThisWay,
  .whatIsBlockchain {
    top: 1052px;
    letter-spacing: -0.02em;
  }
  .blockchainIsA,
  .whatIsBlockchain {
    top: 906px;
    left: 23px;
  }
  .whatIsBlockchain {
    top: 560px;
    left: 26px;
    font-size: var(--font-size-16xl);
    line-height: 35px;
    width: 390px;
  }
  .chain {
    font-family: var(--font-bodoni-72);
  }
  .blockchainWeb3Container,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .blockchainWeb3Container {
    top: 75px;
    font-size: 45px;
    color: var(--color-white);
  }
  .theComingFuture {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn500Item {
    position: absolute;
    top: 200px;
    left: calc(50% - 250px);
    border-radius: var(--br-11xl);
    background-color: #d9d9d9;
    width: 500px;
    height: 300px;
  }
  .learn500Inner {
    position: absolute;
    top: 1755.5px;
    left: calc(50% - 227.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 455px;
    height: 1px;
  }
  .nextUseCaseContainer {
    position: absolute;
    top: 2291px;
    width: 200px;
    left: 240px;
    font-size: 27px;
    letter-spacing: -0.02em;
  }
  .selfSovereignHealthcareData {
    position: absolute;
    top: 2336px;
    width: 250px;
    left: 240px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 2329px;
    left: 475px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    object-fit: contain;
  }
  .maskGroupIcon {
    position: absolute;
    top: 200px;
    left: calc(50% - 250px);
    width: 500px;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn500 {
    position: relative;
    width: 100%;
    height: 2512px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 500px) {
  .hippocratIsThe {
    margin: 0;
  }
  .hippocratIsTheContainer,
  .web10Container {
    position: absolute;
    top: 1869px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .web10Container {
    top: 1373px;
  }
  .whatIsWeb3,
  .whyHippocrat {
    position: absolute;
    top: 1794px;
    left: 0;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 35px;
  }
  .whatIsWeb3 {
    top: 1298px;
  }
  .learn360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1238px;
  }
  .blockchainIsA,
  .forMostOf,
  .inThisWay,
  .whatIsBlockchain {
    position: absolute;
    top: 635px;
    left: 0;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 100%;
    font-family: var(--font-inter);
    font-size: 14px;
  }
  .blockchainIsA,
  .inThisWay,
  .whatIsBlockchain {
    top: 1027px;
    letter-spacing: -0.02em;
  }
  .blockchainIsA,
  .whatIsBlockchain {
    top: 881px;
  }
  .whatIsBlockchain {
    top: 560px;
    font-size: var(--font-size-6xl);
    line-height: 35px;
    font-weight: 700;
    font-family: var(--font-geomanist);
  }
  .chain {
    font-family: var(--font-bodoni-72);
  }
  .blockchainWeb3Container,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .blockchainWeb3Container {
    top: 79px;
    font-size: 35px;
    color: var(--color-white);
  }
  .theComingFuture {
    top: 134px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn360Item {
    position: absolute;
    top: 1733.5px;
    left: 0;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .nextUseCaseContainer {
    position: absolute;
    top: 2255px;
    width: 200px;
    right: 8px;
    font-size: 27px;
    letter-spacing: -0.02em;
  }
  .selfSovereignHealthcareData {
    position: absolute;
    top: 2300px;
    width: 200px;
    right: 8px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 2296px;
    right: 0;
    width: 30px;
    height: 30px;
    overflow: hidden;
    object-fit: contain;
  }
  .maskGroupIcon {
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
  }
  .learn360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 2458px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
}
