@media screen and (max-width: 819px) and (min-width: 500px) {
  .sdk500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 2110px;
  }
  .sdk500Item {
    position: absolute;
    top: 473px;
    left: calc(50% - 153px);
    border-radius: 18px;
    width: 303px;
    height: 283px;
  }
  .itFacilitatesThe,
  .itSupportsThe {
    position: absolute;
    top: 923px;
    left: 0;
    line-height: 25px;
    color: var(--color-gainsboro-100);
    display: inline-block;
    width: 410px;
    font-size: 15px;
  }
  .itFacilitatesThe {
    top: 1621px;
    font-size: 17px;
    width: 413px;
  }
  .technology {
    font-family: var(--font-bodoni-72);
  }
  .sdkTechnologyTxtContainer {
    width: 100%;
  }
  .sdkTechnology {
    position: absolute;
    top: 75px;
    left: 0;
    font-size: 45px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 575px;
  }
  .hipposOriginalTechnology,
  .theHippocratWalletSdkFacil {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .hipposOriginalTechnology {
    top: 134px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    width: 279px;
  }
  .theHippocratWalletSdkFacil {
    top: 211px;
    font-size: var(--font-size-13xl);
    line-height: 46px;
    color: var(--color-white);
    width: 457px;
    height: 131px;
  }
  .hpoDid {
    margin: 0;
  }
  .hpoDidContainer,
  .hpoTokenStorageContainer {
    position: absolute;
    top: 827px;
    left: 0;
    font-size: var(--font-size-8xl);
    letter-spacing: -0.02em;
    line-height: 37px;
    display: flex;
    color: var(--color-whitesmoke-100);
    align-items: center;
    width: 413px;
  }
  .hpoTokenStorageContainer {
    top: 1525px;
    height: 56px;
  }
  .pec {
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .detailSpec {
    position: absolute;
    top: 2173px;
    left: 4px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .createHippocratDid,
  .generateEncryptDecryptContainer,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    position: absolute;
    top: 2632px;
    left: 0;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 240px;
  }
  .createHippocratDid,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    top: 2783px;
  }
  .createHippocratDid,
  .makeTransactionsOn1 {
    top: 2481px;
    left: 261px;
  }
  .createHippocratDid {
    top: 2632px;
  }
  .eciesAesCtrWith {
    color: var(--color-darkgray);
  }
  .encryptDecryptContainer {
    position: absolute;
    top: 2783px;
    left: 261px;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 240px;
  }
  .lineDiv,
  .sdk500Inner {
    position: absolute;
    box-sizing: border-box;
    height: 1px;
  }
  .sdk500Inner {
    top: 2236.5px;
    left: -0.5px;
    border-top: 1px solid var(--color-black);
    width: 501px;
  }
  .lineDiv {
    top: 2585.5px;
    left: calc(50% - 256.5px);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 507px;
  }
  .lineDiv2 {
    position: absolute;
    top: 2745px;
    left: calc(50% - 256.5px);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 507px;
  }
  .image53Icon {
    position: absolute;
    top: 2277px;
    left: 0;
    border-radius: var(--br-11xl);
    width: 500px;
    height: 164px;
    object-fit: cover;
  }
  .tbd {
    position: absolute;
    top: 3019px;
    left: 4px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .sdk500Child1 {
    position: absolute;
    top: 3082.5px;
    left: calc(50% - 246.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 497px;
    height: 1px;
  }
  .provideTheFunctionality,
  .supportForSaving {
    position: absolute;
    top: 3613px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 238px;
    font-size: 14px;
  }
  .provideTheFunctionality {
    top: 3374px;
    width: 240px;
    height: 130px;
    font-size: 14px;
  }
  .p2pStorage,
  .transferChain {
    position: absolute;
    top: 3327px;
    left: 0;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .transferChain {
    top: 3566px;
  }
  .variousChainSupport {
    position: absolute;
    top: 3374px;
    left: 261px;
    line-height: 25px;
    display: inline-block;
    width: 240px;
    height: 130px;
    font-size: 14px;
  }
  .ion {
    position: absolute;
    top: 3327px;
    left: 260px;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .image54Icon {
    position: absolute;
    top: 3123px;
    left: 0;
    border-radius: var(--br-11xl);
    width: 500px;
    height: 164px;
    object-fit: cover;
  }
  .openSource {
    position: absolute;
    top: 3824px;
    left: 0;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .sdk500Child2 {
    position: absolute;
    top: 3887.5px;
    left: -0.5px;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 501px;
    height: 1px;
  }
  .anyoneGloballyCan {
    position: absolute;
    top: 4178px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 240px;
    font-size: 14px;
  }
  .github {
    position: absolute;
    top: 4132px;
    left: 0;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .npm,
  .youCanEasily {
    position: absolute;
    left: 260px;
  }
  .npm {
    top: 4132px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .youCanEasily {
    top: 4178px;
    line-height: 25px;
    display: inline-block;
    width: 240px;
    font-size: 14px;
  }
  .image55 {
    position: absolute;
    top: 3928px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: #e4e4e4;
    width: 500px;
    height: 164px;
  }
  .rectangleDiv,
  .sdk500Child3 {
    position: absolute;
    top: 4329px;
    left: 260px;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .sdk500Child3 {
    left: 0;
  }
  .letsGo,
  .letsGo1 {
    position: absolute;
    top: 4329px;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .letsGo1 {
    left: 261px;
  }
  .deriveAccountAddressContainer {
    position: absolute;
    top: 2481px;
    left: 0;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 240px;
  }
  .maskGroupIcon {
    position: absolute;
    top: 2277px;
    left: 0;
    width: 500px;
    height: 164px;
    object-fit: contain;
    border-radius: var(--br-11xl);
  }
  .maskGroupIcon1,
  .maskGroupIcon2 {
    position: absolute;
    top: 3928px;
    left: 0;
    width: 500px;
    height: 164px;
    object-fit: cover;
    border-radius: var(--br-11xl);
  }
  .maskGroupIcon2 {
    top: 3123px;
    left: 2px;
    width: 498px;
    object-fit: contain;
  }
  .maskGroupIcon3,
  .maskGroupIcon4 {
    position: absolute;
    top: 1147px;
    left: calc(50% - 250px);
    width: 303px;
    height: 283px;
    object-fit: cover;
  }
  .maskGroupIcon4 {
    top: 1733px;
    left: calc(50% - 72px);
    object-fit: contain;
  }
  .sdk500 {
    position: relative;
    width: 100%;
    height: 4584px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
}
@media screen and (max-width: 500px) {
  .sdk360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1973px;
  }
  .cubeIcon {
    position: absolute;
    top: 1127px;
    left: 0;
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
  .sdk360Item {
    position: absolute;
    top: 473px;
    left: calc(50% - 153px);
    border-radius: 18px;
    width: 303px;
    height: 283px;
  }
  .itFacilitatesThe,
  .itSupportsThe {
    position: absolute;
    left: 0;
    line-height: 25px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 360px;
  }
  .itSupportsThe {
    top: 923px;
    font-size: var(--font-size-base);
    font-size: 14px;
  }
  .itFacilitatesThe {
    top: 1516px;
    font-size: 17px;
    font-size: 14px;
  }
  .technology {
    font-family: var(--font-bodoni-72);
  }
  .sdkTechnologyTxtContainer {
    width: 100%;
  }
  .sdkTechnology {
    position: absolute;
    top: 79px;
    left: 0;
    font-size: 35px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 361px;
  }
  .hipposOriginalTechnology,
  .theHippocratWalletSdkFacil {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .hipposOriginalTechnology {
    top: 134px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    width: 279px;
  }
  .theHippocratWalletSdkFacil {
    top: 211px;
    font-size: var(--font-size-9xl);
    line-height: 40px;
    color: var(--color-white);
    width: 360px;
    height: 131px;
  }
  .hpoDid {
    margin: 0;
  }
  .hpoDidContainer,
  .hpoTokenStorageContainer {
    position: absolute;
    top: 832px;
    left: 0;
    font-size: var(--font-size-2xl);
    letter-spacing: -0.02em;
    line-height: 31px;
    display: flex;
    color: var(--color-whitesmoke-100);
    align-items: center;
    width: 360px;
  }
  .hpoTokenStorageContainer {
    top: 1420px;
    width: 413px;
    height: 56px;
  }
  .pec {
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .detailSpec {
    position: absolute;
    top: 2088px;
    left: 4px;
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .createHippocratDid,
  .generateEncryptDecryptContainer,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    position: absolute;
    top: 2547px;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    width: 170px;
  }
  .createHippocratDid,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    top: 2698px;
  }
  .createHippocratDid,
  .makeTransactionsOn1 {
    top: 2396px;
    right: 0;
  }
  .createHippocratDid {
    top: 2547px;
  }
  .eciesAesCtrWith {
    color: var(--color-darkgray);
  }
  .encryptDecryptContainer {
    position: absolute;
    top: 2698px;
    right: 0;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    width: 170px;
  }
  .sdk360Inner {
    top: 2151.5px;
    left: 0;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .lineDiv,
  .sdk360Inner,
  .tbd {
    position: absolute;
  }
  .lineDiv {
    top: 2500.5px;
    left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .lineDiv2 {
    position: absolute;
    top: 2650px;
    left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .tbd {
    top: 2938px;
    left: 8px;
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .sdk360Child1 {
    position: absolute;
    top: 3001px;
    left: 0;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .provideTheFunctionality,
  .supportForSaving {
    position: absolute;
    top: 3531px;
    left: 0;
    line-height: 20px;
    display: inline-block;
    width: 170px;
    font-size: 12px;
  }
  .provideTheFunctionality {
    top: 3292px;
  }
  .p2pStorage,
  .transferChain {
    position: absolute;
    top: 3246px;
    left: 0;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .transferChain {
    top: 3485px;
  }
  .variousChainSupport {
    position: absolute;
    top: 3292px;
    right: 0;
    line-height: 20px;
    display: inline-block;
    width: 170px;
    font-size: 12px;
  }
  .ion,
  .openSource {
    position: absolute;
    top: 3246px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .ion {
    width: 170px;
    right: 0;
  }
  .openSource {
    top: 3731px;
    left: 13px;
    font-size: var(--font-size-9xl);
    line-height: 47px;
    text-align: center;
  }
  .sdk360Child2 {
    position: absolute;
    top: 3794.5px;
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 100%;
    height: 1px;
  }
  .anyoneGloballyCan {
    position: absolute;
    top: 4085px;
    left: 0;
    line-height: 20px;
    display: inline-block;
    width: 170px;
    font-size: 12px;
  }
  .github {
    position: absolute;
    top: 4039px;
    left: 0;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .npm,
  .youCanEasily {
    position: absolute;
  }
  .npm {
    top: 4039px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
    right: 0;
    width: 170px;
  }
  .youCanEasily {
    top: 4085px;
    line-height: 20px;
    display: inline-block;
    width: 170px;
    right: 0;
    font-size: 12px;
  }
  .rectangleDiv {
    position: absolute;
    top: 4255px;
    left: 0;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .sdk360Child3 {
    position: absolute;
    top: 4255px;
    right: 0;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .letsGo {
    position: absolute;
    top: 4255px;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .letsGo1 {
    right: 0;
    position: absolute;
    top: 4255px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .deriveAccountAddressContainer {
    position: absolute;
    top: 2396px;
    left: 0;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    width: 170px;
  }
  .maskGroupIcon,
  .maskGroupIcon1 {
    position: absolute;
    top: 3835px;
    left: 0;
    width: 100%;
    height: 164px;
    object-fit: cover;
    border-radius: 30px;
  }
  .maskGroupIcon1 {
    top: 3042px;
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
  }
  .cubeIcon1,
  .maskGroupIcon2 {
    position: absolute;
    object-fit: cover;
  }
  .maskGroupIcon2 {
    top: 1605px;
    left: calc(50% - 103px);
    width: 283.33px;
    height: 265px;
  }
  .cubeIcon1 {
    top: 1126.66px;
    left: 26px;
    width: 333.68px;
    height: 333.68px;
  }
  .sdk360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 4450px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
  .web4 {
    position: absolute;
    top: 2190px;
    left: 0;
    width: 100%;
    height: 164px;
    object-fit: cover;
    border-radius: var(--br-11xl);
  }
}
