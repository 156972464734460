@media screen and (min-width: 1020px) {
  .sdkChild {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1490px;
  }
  .itSupportsThe {
    margin: 0;
  }
  .itFacilitatesThe,
  .itSupportsTheContainer {
    position: absolute;
    top: 708px;
    left: 510px;
    font-size: 17px;
    line-height: 28px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 490px;
  }
  .itFacilitatesThe {
    top: 1161px;
    left: 0;
  }
  .technology {
    font-family: var(--font-bodoni-72);
  }
  .sdkTechnologyTxtContainer {
    width: 100%;
  }
  .sdkTechnology {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 68px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 575px;
  }
  .hipposOriginalTechnology,
  .theHippocratWalletSdkFacil {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .hipposOriginalTechnology {
    top: 167px;
    font-size: 22px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    width: 450px;
  }
  .theHippocratWalletSdkFacil {
    top: 244px;
    font-size: var(--font-size-15xl);
    line-height: 47px;
    color: var(--color-white);
    width: 575px;
    height: 131px;
  }
  .hpoDidContainer {
    position: absolute;
    top: 607px;
    left: 510px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    display: flex;
    color: var(--color-whitesmoke);
    align-items: center;
    width: 490px;
  }
  .maskGroupIcon,
  .maskGroupIcon1 {
    position: absolute;
    top: 572px;
    left: calc(50% - 466px);
    width: 371px;
    height: 347px;
    object-fit: cover;
  }
  .maskGroupIcon1 {
    top: 1005px;
    left: calc(50% + 95px);
  }
  .hpoTokenStorageContainer {
    position: absolute;
    top: 1065px;
    left: 0;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    display: flex;
    color: var(--color-whitesmoke);
    align-items: center;
    width: 490px;
    height: 56px;
  }
  .pec {
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .detailSpec {
    position: absolute;
    top: 1565px;
    left: 0;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .deriveAccountAddressContainer,
  .generateEncryptDecryptContainer,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    position: absolute;
    top: 2024px;
    left: 0;
    line-height: 28px;
    font-weight: 500;
    display: inline-block;
    width: 270px;
    height: 100px;
  }
  .deriveAccountAddressContainer,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    top: 1873px;
    left: 1px;
  }
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    left: 355px;
  }
  .makeTransactionsOn1 {
    left: 710px;
  }
  .eciesAesCtrWith {
    color: var(--color-darkgray);
  }
  .createHippocratDid,
  .encryptDecryptContainer {
    position: absolute;
    top: 2024px;
    left: 710px;
    line-height: 28px;
    font-weight: 500;
    display: inline-block;
    width: 270px;
    height: 100px;
  }
  .createHippocratDid {
    left: 355px;
  }
  .tbd {
    position: absolute;
    top: 2251px;
    left: 0;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .sdkItem {
    top: 1628.5px;
    left: calc(50% - 500.5px);
    border-top: 1px solid var(--color-black);
    width: 1001px;
  }
  .lineDiv,
  .sdkInner,
  .sdkItem {
    position: absolute;
    box-sizing: border-box;
    height: 1px;
  }
  .sdkInner {
    top: 2314.5px;
    left: calc(50% - 500.5px);
    border-top: 1px solid var(--color-black);
    width: 1001px;
  }
  .lineDiv {
    top: 1977.5px;
    left: calc(50% - 488.5px);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 981px;
  }
  .provideTheFunctionality,
  .supportForSaving {
    position: absolute;
    top: 2607px;
    left: 710px;
    line-height: 28px;
    display: inline-block;
    width: 270px;
    height: 130px;
    font-size: 16px;
  }
  .provideTheFunctionality {
    left: 0;
  }
  .p2pStorage,
  .transferChain {
    position: absolute;
    top: 2559px;
    left: 0;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .transferChain {
    left: 710px;
  }
  .ion,
  .variousChainSupport {
    position: absolute;
    left: 355px;
    line-height: 28px;
  }
  .variousChainSupport {
    top: 2607px;
    display: inline-block;
    width: 270px;
    height: 130px;
    font-size: 16px;
  }
  .ion {
    top: 2560px;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
  }
  .image53Icon {
    position: absolute;
    top: 1669px;
    left: 0;
    border-radius: var(--br-11xl);
    width: 1000px;
    height: 164px;
    object-fit: cover;
  }
  .openSource {
    position: absolute;
    top: 2882px;
    left: 0;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .sdkChild1 {
    position: absolute;
    top: 2945.5px;
    left: calc(50% - 500.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 1001px;
    height: 1px;
  }
  .anyoneGloballyCan {
    top: 3238px;
    left: 0;
    display: inline-block;
    width: 464px;
    height: 69px;
    font-size: 16px;
  }
  .anyoneGloballyCan,
  .github,
  .npm,
  .youCanEasily {
    position: absolute;
    line-height: 28px;
  }
  .github {
    top: 3186px;
    left: 0;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
  }
  .npm,
  .youCanEasily {
    left: 510px;
  }
  .npm {
    top: 3190px;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
  }
  .youCanEasily {
    top: 3238px;
    display: inline-block;
    width: 464px;
    height: 69px;
    font-size: 16px;
  }
  .rectangleDiv,
  .sdkChild2 {
    position: absolute;
    top: 3340px;
    left: 510px;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .sdkChild2 {
    left: 0;
  }
  .letsGo,
  .letsGo1 {
    position: absolute;
    top: 3340px;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .letsGo1 {
    left: 510px;
  }
  .maskGroupIcon2,
  .maskGroupIcon3,
  .maskGroupIcon4 {
    position: absolute;
    top: 1669px;
    left: 0;
    width: 1000px;
    height: 164px;
    object-fit: contain;
    border-radius: 30px;
  }
  .maskGroupIcon3,
  .maskGroupIcon4 {
    top: 2355px;
  }
  .maskGroupIcon4 {
    top: 2986px;
    object-fit: cover;
  }
  .sdk {
    position: relative;
    width: 100%;
    height: 3552px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    img {
      pointer-events: none;
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .sdk800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1384px;
  }
  .maskGroupIcon,
  .maskGroupIcon1 {
    position: absolute;
    top: 503px;
    left: calc(50% - 400px);
    width: 371px;
    height: 347px;
    object-fit: cover;
  }
  .maskGroupIcon1 {
    top: 930px;
    left: calc(50% + 20px);
  }
  .itFacilitatesThe,
  .itSupportsThe {
    position: absolute;
    top: 656px;
    left: 410px;
    font-size: 17px;
    line-height: 28px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 390px;
  }
  .itFacilitatesThe {
    top: 1091px;
    left: 0;
  }
  .hipposOriginalTechnology,
  .theHippocratWalletSdkFacil {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .hipposOriginalTechnology {
    top: 164px;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    width: 400px;
  }
  .theHippocratWalletSdkFacil {
    top: 241px;
    font-size: var(--font-size-13xl);
    line-height: 46px;
    color: var(--color-white);
    width: 594px;
    height: 131px;
  }
  .hpoDid {
    margin: 0;
  }
  .hpoDidContainer,
  .hpoTokenStorageContainer {
    position: absolute;
    top: 523px;
    left: 410px;
    font-size: var(--font-size-8xl);
    letter-spacing: -0.02em;
    line-height: 37px;
    display: flex;
    color: var(--color-whitesmoke);
    align-items: center;
    width: 390px;
  }
  .hpoTokenStorageContainer {
    top: 995px;
    left: 0;
    height: 56px;
  }
  .s {
    font-family: var(--font-bodoni-72);
  }
  .pec {
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .detailSpec {
    position: absolute;
    top: 1459px;
    left: 0;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .deriveAccountAddressContainer,
  .generateEncryptDecryptContainer,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    position: absolute;
    top: 1918px;
    left: 0;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 252px;
    height: 100px;
  }
  .deriveAccountAddressContainer,
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    top: 1767px;
    left: 1px;
  }
  .makeTransactionsOn,
  .makeTransactionsOn1 {
    left: 273px;
    width: 254px;
  }
  .makeTransactionsOn1 {
    left: 548px;
    width: 252px;
  }
  .eciesAesCtrWith {
    color: var(--color-darkgray);
  }
  .createHippocratDid,
  .encryptDecryptContainer {
    position: absolute;
    top: 1918px;
    left: 548px;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 252px;
    height: 100px;
  }
  .createHippocratDid {
    left: 273px;
    width: 254px;
  }
  .sdkTechnologyTxtContainer {
    width: 100%;
  }
  .sdkTechnology,
  .tbd {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .sdkTechnology {
    top: 100px;
    left: 0;
    font-size: 60px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 575px;
  }
  .tbd {
    top: 2145px;
    left: 2px;
    font-size: var(--font-size-13xl);
    line-height: 47px;
    text-align: center;
  }
  .sdk800Item {
    top: 1522.5px;
    border-top: 1px solid var(--color-black);
  }
  .lineDiv,
  .sdk800Inner,
  .sdk800Item {
    position: absolute;
    left: calc(50% - 400.5px);
    box-sizing: border-box;
    width: 801px;
    height: 1px;
  }
  .sdk800Inner {
    top: 2208.5px;
    border-top: 1px solid var(--color-black);
  }
  .lineDiv {
    top: 1871.5px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .provideTheFunctionality,
  .supportForSaving {
    position: absolute;
    top: 2501px;
    left: 548px;
    line-height: 25px;
    display: inline-block;
    width: 252px;
    height: 130px;
    font-size: 14px;
  }
  .provideTheFunctionality {
    left: 0;
    width: 253px;
  }
  .p2pStorage,
  .transferChain {
    position: absolute;
    top: 2453px;
    left: 0;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .transferChain {
    left: 548px;
  }
  .ion,
  .variousChainSupport {
    position: absolute;
    left: 273px;
  }
  .variousChainSupport {
    top: 2501px;
    line-height: 25px;
    display: inline-block;
    width: 254px;
    height: 130px;
    font-size: 14px;
  }
  .ion {
    top: 2454px;
    font-size: var(--font-size-3xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .maskGroupIcon2,
  .maskGroupIcon3 {
    position: absolute;
    top: 1567px;
    left: 0;
    width: 800px;
    object-fit: contain;
    border-radius: var(--br-11xl);
  }
  .maskGroupIcon3 {
    top: 2247px;
  }
  .openSource {
    position: absolute;
    top: 2776px;
    left: 6px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .sdk800Child1 {
    position: absolute;
    top: 2839.5px;
    left: calc(50% - 400.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 801px;
    height: 1px;
  }
  .anyoneGloballyCan {
    position: absolute;
    top: 3129px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 390px;
    height: 69px;
    font-size: 14px;
  }
  .github {
    position: absolute;
    top: 3082px;
    left: 0;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .npm,
  .youCanEasily {
    position: absolute;
    left: 410px;
  }
  .npm {
    top: 3082px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .youCanEasily {
    top: 3129px;
    line-height: 25px;
    display: inline-block;
    width: 390px;
    height: 69px;
    font-size: 14px;
  }
  .maskGroupIcon4 {
    position: absolute;
    top: 2878px;
    left: 1px;
    width: 800px;
    object-fit: cover;
  }
  .rectangleDiv,
  .sdk800Child2 {
    position: absolute;
    top: 3237px;
    left: 410px;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .sdk800Child2 {
    left: 0;
  }
  .letsGo,
  .letsGo1 {
    position: absolute;
    top: 3237px;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .letsGo1 {
    left: 410px;
  }
  .sdk800 {
    position: relative;
    width: 100%;
    height: 3484px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    img {
      pointer-events: none;
    }
  }
}
