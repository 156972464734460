@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Thin.eot');
  src:
    local('Geomanist Thin'),
    local('Geomanist-Thin'),
    url('/assets/font/Geomanist-Thin.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Thin.woff2') format('woff2'),
    url('/assets/font/Geomanist-Thin.woff') format('woff'),
    url('/assets/font/Geomanist-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Black.eot');
  src:
    local('Geomanist Black'),
    local('Geomanist-Black'),
    url('/assets/font/Geomanist-Black.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Black.woff2') format('woff2'),
    url('/assets/font/Geomanist-Black.woff') format('woff'),
    url('/assets/font/Geomanist-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Medium.eot');
  src:
    local('Geomanist Medium'),
    local('Geomanist-Medium'),
    url('/assets/font/Geomanist-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Medium.woff2') format('woff2'),
    url('/assets/font/Geomanist-Medium.woff') format('woff'),
    url('/assets/font/Geomanist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Bold.eot');
  src:
    local('Geomanist Bold'),
    local('Geomanist-Bold'),
    url('/assets/font/Geomanist-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Bold.woff2') format('woff2'),
    url('/assets/font/Geomanist-Bold.woff') format('woff'),
    url('/assets/font/Geomanist-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist Book';
  src: url('/assets/font/Geomanist-Book.eot');
  src:
    local('Geomanist Book'),
    local('Geomanist-Book'),
    url('/assets/font/Geomanist-Book.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Book.woff2') format('woff2'),
    url('/assets/font/Geomanist-Book.woff') format('woff'),
    url('/assets/font/Geomanist-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist Ultra';
  src: url('/assets/font/Geomanist-Ultra.eot');
  src:
    local('Geomanist Ultra'),
    local('Geomanist-Ultra'),
    url('/assets/font/Geomanist-Ultra.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Ultra.woff2') format('woff2'),
    url('/assets/font/Geomanist-Ultra.woff') format('woff'),
    url('/assets/font/Geomanist-Ultra.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Regular.eot');
  src:
    local('Geomanist Regular'),
    local('Geomanist-Regular'),
    url('/assets/font/Geomanist-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Regular.woff2') format('woff2'),
    url('/assets/font/Geomanist-Regular.woff') format('woff'),
    url('/assets/font/Geomanist-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-ExtraLight.eot');
  src:
    local('Geomanist ExtraLight'),
    local('Geomanist-ExtraLight'),
    url('/assets/font/Geomanist-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/font/Geomanist-ExtraLight.woff2') format('woff2'),
    url('/assets/font/Geomanist-ExtraLight.woff') format('woff'),
    url('/assets/font/Geomanist-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Light.eot');
  src:
    local('Geomanist Light'),
    local('Geomanist-Light'),
    url('/assets/font/Geomanist-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Light.woff2') format('woff2'),
    url('/assets/font/Geomanist-Light.woff') format('woff'),
    url('/assets/font/Geomanist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Inter');
// @import url('https://fonts.cdnfonts.com/css/geograph-edit-black');

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-noto-sans: 'Noto Sans';
  --font-bodoni-72-oldstyle: 'Bodoni 72 Oldstyle';
  --font-bodoni-72: 'Bodoni 72';
  --font-inter: 'Inter';
  --font-geomanist: 'Geomanist';
  --font-spoqa-han-sans-neo: 'Spoqa Han Sans Neo';
  --font-geomanist-ultra: 'Geomanist Ultra';

  /* font sizes */
  --font-size-smi: 13px;
  --font-size-sm: 14px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-mid: 17px;
  --font-size-lg: 18px;
  --font-size-lgi: 19px;
  --font-size-xl: 20px;
  --font-size-2xl: 21px;
  --font-size-3xl: 22px;
  --font-size-5xl: 24px;
  --font-size-6xl: 25px;
  --font-size-7xl: 26px;
  --font-size-8xl: 27px;
  --font-size-9xl: 28px;
  --font-size-10xl: 29px;
  --font-size-11xl: 30px;
  --font-size-13xl: 32px;
  --font-size-15xl: 34px;
  --font-size-16xl: 35px;
  --font-size-23xl: 42px;
  --font-size-24xl: 43px;
  --font-size-26xl: 45px;
  --font-size-28xl: 47px;
  --font-size-31xl: 50px;
  --font-size-36xl: 55px;
  --font-size-41xl: 60px;
  --font-size-49xl: 68px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-dimgray: #4e4e4e;
  --color-palegreen: #89ffa5;
  --color-limegreen: #00d308;
  --color-limegreen-100: #19d959;
  // --color-limegreen-100: #76f15c;
  --color-limegreen-200: #00d308;
  --color-gainsboro: #ddd;
  --color-gainsboro-100: #ddd;
  --color-gainsboro-200: #d9d9d9;
  --color-whitesmoke: #f6f8f9;
  --color-whitesmoke-100: #f6f8f9;
  --color-whitesmoke-200: #f5f5f5;
  --color-header-bg: #191919;
  --color-gray: #8f8f8f;
  --color-gray-100: #929292;
  --color-gray-200: #818181;
  --color-lightgray: #d5d5d5;
  --color-darkgray: #b4b4b4;
  --color-darkgray-100: #ababab;
  --color-darkgray-200: #8b9e8b;
  --color-darkslategray: #4b6247;
  --color-cornflowerblue: #1ca9fe;

  /* Paddings */
  --padding-mid: 17px;

  /* Border radiuses */
  --br-sm: 14px;
  --br-lg: 18px;
  --br-xl: 20px;
  --br-6xl: 25px;
  --br-11xl: 30px;
  --br-24xl: 43px;
  --br-980xl: 999px;
  --br-9980xl: 9999px;

  --vh: 1vh;
}

/* layout style */
@media screen and (min-width: 1020px) {
  .layout1000 {
    position: relative;
    width: 1000px;
    margin: 0 auto;
  }
  .layout800 {
    display: none;
  }
  .layout500 {
    display: none;
  }
  .layout360 {
    display: none;
  }
  .layoutContainer {
    position: relative;
    margin-top: 94px;
    overflow: hidden;
  }
  .only1000 {
    display: block;
  }
  .only800 {
    display: none;
  }
  .only500 {
    display: none;
  }
  .only360 {
    display: none;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .layout1000 {
    display: none;
  }
  .layout800 {
    position: relative;
    width: 800px;
    margin: 0 auto;
  }
  .layout500 {
    display: none;
  }
  .layout360 {
    display: none;
  }
  .layoutContainer {
    position: relative;
    margin-top: 94px;
    overflow: hidden;
  }
  .only1000 {
    display: none;
  }
  .only800 {
    display: block;
  }
  .only500 {
    display: none;
  }
  .only360 {
    display: none;
  }
}
@media screen and (max-width: 819px) and (min-width: 500px) {
  .layout1000 {
    display: none;
  }
  .layout800 {
    display: none;
  }
  .layout500 {
    position: relative;
    width: 500px;
    margin: 0 auto;
  }
  .layout360 {
    display: none;
  }
  .layoutContainer {
    position: relative;
    margin-top: 94px;
    overflow: hidden;
  }
  .only1000 {
    display: none;
  }
  .only800 {
    display: none;
  }
  .only500 {
    display: block;
  }
  .only360 {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .layout1000 {
    display: none;
  }
  .layout800 {
    display: none;
  }
  .layout500 {
    display: none;
  }
  .layout360 {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  .layoutContainer {
    position: relative;
    margin-top: 94px;
    overflow: hidden;
  }
  .only1000 {
    display: none;
  }
  .only800 {
    display: none;
  }
  .only500 {
    display: none;
  }
  .only360 {
    display: block;
  }
}

/* accordion style */
.accordion-faq {
  padding: 48px 0;
  border-bottom: 0.7px solid var(--color-white);

  img {
    width: 28px;
    height: 24px;
    margin-top: 5px;
  }

  b {
    display: flex;
    justify-content: space-between;
    font-family: Geomanist;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.44px;
    cursor: pointer;
  }

  p {
    margin: 0;
    margin-top: 48px;
    font-family: var(--font-inter);
  }
}

.basic-video-img {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  margin-top: -94px;

  video {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
  }
}

.basic-img-width-500 {
  width: 500px;
  video {
    width: 500px;
  }
}
.basic-img-width-400 {
  width: 400px;
  video {
    width: 400px;
  }
}
.basic-img-width-100 {
  width: 100%;
  video {
    width: 100%;
  }
}

.basic-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.basic-news-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.basic-blog-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.basic-blog-main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.basic-img-1000 {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  margin-top: -94px;

  video {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
  }
}
.basic-text-1000 {
  position: absolute;
  width: 1000px;
  margin-left: calc(50% - 500px);
  top: 0;
  font-family: var(--font-geomanist);
  z-index: 2;
}
.basic-img-800 {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  margin-top: -94px;

  video {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
  }
}
.basic-text-800 {
  position: absolute;
  width: 800px;
  margin-left: calc(50% - 400px);
  top: 0;
  font-family: var(--font-geomanist);
  z-index: 2;
}

.basic-img-500 {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  margin-top: -94px;

  video {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
  }
}
.basic-text-500 {
  position: absolute;
  width: 500px;
  margin-left: calc(50% - 250px);
  top: 0;
  font-family: var(--font-geomanist);
  z-index: 2;
}

.basic-img-360 {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  margin-top: -94px;

  video {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
  }
}
.basic-text-360 {
  position: absolute;
  width: 360px;
  margin-left: calc(50% - 180px);
  top: 0;
  font-family: var(--font-geomanist);
  z-index: 2;
}

video {
  mask-image: radial-gradient(white, black);
  backface-visibility: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
