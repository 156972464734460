@media screen and (min-width: 1020px) {
  .hpoToken1000Child {
    position: absolute;
    top: 525px;
    left: calc(50% - 500px);
    border-radius: 30px;
    background-color: var(--color-white);
    width: 1000px;
    height: 1173px;
  }
  .sustainableBenefit {
    position: absolute;
    top: 177px;
    left: calc(50% - 110px);
    letter-spacing: -0.02em;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .div,
  .totalTokenSupply {
    position: absolute;
    left: calc(50% + 43px);
  }
  .totalTokenSupply {
    top: 979px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
  }
  .div {
    top: 1017px;
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
    font-weight: 300;
  }
  .annotation,
  .hpoIsA {
    position: absolute;
    top: 1126px;
    letter-spacing: -0.02em;
    font-family: var(--font-inter);
    color: var(--color-dimgray);
  }
  .hpoIsA {
    left: calc(50% - 289px);
    font-size: 14px;
    line-height: 23px;
    display: inline-block;
    width: 635px;
    height: 35px;
  }
  .annotation {
    left: calc(50% - 415px);
    text-transform: capitalize;
  }
  .daoCommunity {
    top: 729px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
  }
  .b,
  .b1,
  .daoCommunity,
  .earlyEcosystemParticipants {
    position: absolute;
    left: calc(50% + 42px);
  }
  .earlyEcosystemParticipants {
    top: 854px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
  }
  .b,
  .b1 {
    top: 767px;
    font-size: var(--font-size-11xl);
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
  }
  .b1 {
    top: 892px;
  }
  .hipp {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.11em;
  }
  .crat {
    color: #fff;
    font-size: 68px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -4.76px;
  }
  .crat,
  .n {
    font-family: var(--font-bodoni-72);
  }
  .hippocratToken,
  .hpoTokenFuels {
    position: absolute;
    color: var(--color-white);
    text-align: center;
  }
  .hippocratToken {
    top: 100px;
    left: calc(50% - 285px);
    font-size: 68px;
    white-space: pre-wrap;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .hpoTokenFuels {
    top: 243px;
    left: 113px;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    display: inline-block;
    width: 774px;
    height: 187px;
  }
  .hpoToken1000Inner,
  .hpoToken1000Item {
    position: absolute;
    top: 738px;
    left: 510px;
    background-color: #19d64a;
    width: 13px;
    height: 13px;
  }
  .hpoToken1000Inner {
    top: 864px;
    background-color: #05a1ff;
  }
  .listingExchange,
  .tokenAllocation {
    position: absolute;
    top: 601px;
    left: 83px;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    display: inline-block;
    width: 464px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .listingExchange {
    top: 1333px;
  }
  .image54Icon,
  .image55Icon,
  .image56Icon {
    position: absolute;
    top: 1440px;
    left: 615px;
    width: 194px;
    height: 56px;
    object-fit: cover;
  }
  .image54Icon,
  .image56Icon {
    top: 1447px;
    left: 191px;
    width: 144px;
    height: 42px;
  }
  .image56Icon {
    top: 1440px;
    left: 363px;
    width: 233px;
    height: 51px;
  }
  .rectangleDiv {
    position: absolute;
    top: 696px;
    left: calc(50% - 417px);
    border-radius: 18px;
    // background-color: #f6f8f9;
    width: 371px;
    height: 347px;
  }
  .hpoToken1000 {
    position: relative;
    width: 100%;
    height: 1972px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -300px;

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
  .cation {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .sustainableBenefit {
    position: absolute;
    top: 164px;
    left: calc(50% - 100px);
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .hipp,
  .sustainableBenefit {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.11em;
  }
  .crat {
    letter-spacing: -0.07em;
  }
  .crat,
  .n {
    font-family: var(--font-bodoni-72);
  }
  .hippocratToken,
  .theHpoToken {
    position: absolute;
    color: var(--color-white);
    text-align: center;
  }
  .hippocratToken {
    top: 100px;
    left: calc(50% - 236px);
    font-size: 60px;
    white-space: pre-wrap;
    line-height: 42px;
  }
  .theHpoToken {
    top: 240px;
    left: 68px;
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 664px;
    height: 187px;
    color: #fff;

    text-align: center;
    font-family: Geomanist;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 143.75% */
    letter-spacing: -0.64px;
  }
  .token800Child {
    position: absolute;
    top: 525px;
    left: calc(50% - 400px);
    border-radius: 30px;
    background-color: var(--color-white);
    width: 800px;
    height: 1173px;
  }
  .div,
  .totalTokenSupply {
    position: absolute;
    left: calc(50% + 43px);
  }
  .totalTokenSupply {
    top: 979px;
    font-size: 23px;
    letter-spacing: -0.02em;
  }
  .div {
    top: 1017px;
    font-size: var(--font-size-lg);
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
  }
  .annotation,
  .hpoIsA {
    position: absolute;
    top: 1126px;
    letter-spacing: -0.02em;
    font-family: var(--font-inter);
    color: var(--color-dimgray);
  }
  .hpoIsA {
    left: calc(50% - 206px);
    font-size: 13px;
    line-height: 23px;
    display: inline-block;
    width: 538px;
    height: 35px;
  }
  .annotation {
    left: calc(50% - 332px);
    font-size: 17px;
    text-transform: capitalize;
  }
  .daoCommunity {
    top: 729px;
    font-size: var(--font-size-5xl);
    letter-spacing: -0.02em;
  }
  .b,
  .b1,
  .daoCommunity,
  .earlyEcosystemParticipants {
    position: absolute;
    left: calc(50% + 42px);
  }
  .earlyEcosystemParticipants {
    top: 854px;
    font-size: var(--font-size-5xl);
    letter-spacing: -0.02em;
  }
  .b,
  .b1 {
    top: 767px;
    font-size: var(--font-size-9xl);
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
  }
  .b1 {
    top: 892px;
  }
  .token800Inner,
  .token800Item {
    position: absolute;
    top: 738px;
    left: 410px;
    background-color: #19d64a;
    width: 13px;
    height: 13px;
  }
  .token800Inner {
    top: 863px;
    background-color: #05a1ff;
  }
  .listingExchange,
  .tokenAllocation {
    position: absolute;
    top: 601px;
    left: 68px;
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .listingExchange {
    top: 1333px;
  }
  .image54Icon,
  .image55Icon,
  .image56Icon {
    position: absolute;
    top: 1444px;
    left: 535px;
    width: 167px;
    height: 48px;
    object-fit: cover;
  }
  .image54Icon,
  .image56Icon {
    top: 1450px;
    left: 107px;
    width: 124px;
    height: 36px;
  }
  .image56Icon {
    top: 1444px;
    left: 290px;
    width: 200px;
    height: 44px;
  }
  .token800 {
    position: relative;
    background-color: var(--color-black);
    width: 100%;
    height: 1848px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-13xl);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .hippoVideo {
    position: absolute;
    top: 700px;
    object-fit: cover;
  }
}
