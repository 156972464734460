@media screen and (min-width: 1020px) {
  .communityWrapepr {
    display: flex;
    padding: 100px 0px 143px 0px;
    flex-direction: column;
    background: #000;
  }
  .communityTitle {
    color: #76f15c;
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px; /* 100% */
    letter-spacing: -1.2px;
    text-transform: uppercase;
    margin-bottom: 140px;
  }
  .communityDesc {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    margin-bottom: 200px;
  }
  .overviewTitle {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.88px;
    span {
      color: #fff;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Bodoni 72 Oldstyle';
      font-size: 44px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -1.76px;
    }
  }
  .overviewText {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    margin-top: 40px;
  }
  .mintingTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    .mintingHeader {
      display: flex;
      height: 60px;
      padding: 8px 0px;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 20px 20px 0px 0px;
      background: #5a5a5a;
    }
    .mintingHeaderTitle {
      display: flex;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      color: #fff;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.07px;
    }
    .mintingBody {
      display: flex;
      height: 56px;
      padding: 8px 0px;
      align-items: flex-start;
      align-self: stretch;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
    }
    .mintingBodyTitle {
      display: flex;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.07px;
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .communityWrapepr {
    display: flex;
    padding: 100px 0px 143px 0px;
    flex-direction: column;
    background: #000;
  }
  .communityTitle {
    color: #76f15c;
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px; /* 100% */
    letter-spacing: -1.2px;
    text-transform: uppercase;
    margin-bottom: 140px;
  }
  .communityDesc {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    margin-bottom: 200px;
  }
  .overviewTitle {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.88px;
    span {
      color: #fff;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Bodoni 72 Oldstyle';
      font-size: 44px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -1.76px;
    }
  }
  .overviewText {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    margin-top: 40px;
  }
  .mintingTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    .mintingHeader {
      display: flex;
      height: 60px;
      padding: 8px 0px;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 20px 20px 0px 0px;
      background: #5a5a5a;
    }
    .mintingHeaderTitle {
      display: flex;
      width: 100px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      color: #fff;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.07px;
    }
    .mintingBody {
      display: flex;
      height: 56px;
      padding: 8px 0px;
      align-items: flex-start;
      align-self: stretch;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
    }
    .mintingBodyTitle {
      display: flex;
      width: 100px;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      color: #fff;
      text-align: center;
      color: #fff;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.06px;
    }
  }
}
