.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100 * var(--vh));
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.outerCircle,
.middleCircle,
.innerCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: fixed;
}

.outerCircle {
  height: 300vw;
  width: 300vw;
  background-color: antiquewhite;
}

.middleCircle {
  height: 300vw;
  width: 300vw;
  background-color: #fff;
}

.innerCircle {
  height: 200vw;
  width: 200vw;
  background-color: #00d332;
}

.logo {
  width: 300px; // 로고의 크기 조정
  height: auto;
}

@media screen and (max-width: 600px) {
  .outerCircle,
  .middleCircle,
  .innerCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: fixed;
  }

  .outerCircle {
    height: calc(300 * var(--vh));
    width: calc(300 * var(--vh));
    background-color: antiquewhite;
  }

  .middleCircle {
    height: calc(300 * var(--vh));
    width: calc(300 * var(--vh));
    background-color: #fff;
  }

  .innerCircle {
    height: calc(200 * var(--vh));
    width: calc(200 * var(--vh));
    background-color: #00d332;
  }

  .logo {
    width: 250px; // 로고의 크기 조정
    height: auto;
  }
}
