@media screen and (min-width: 1020px) {
  .protocol1000Child {
    position: absolute;
    top: 481px;
    left: 0;
    border-radius: var(--br-lg);
    width: 320px;
    height: 410px;
  }
  .first {
    position: absolute;
    top: 510px;
    left: 30px;
    font-size: var(--font-size-base);
    letter-spacing: -0.02em;
  }
  .protocol1000Inner,
  .protocol1000Item {
    position: absolute;
    top: 559px;
    left: 680px;
    border-radius: var(--br-lg);
    width: 320px;
    height: 410px;
  }
  .protocol1000Inner {
    top: 518px;
    left: 340px;
  }
  .second,
  .third {
    position: absolute;
    top: 547px;
    left: 370px;
    font-size: var(--font-size-base);
    letter-spacing: -0.02em;
  }
  .third {
    top: 588px;
    left: 710px;
  }
  .o {
    font-family: var(--font-bodoni-72);
    font-weight: 700;
  }
  .layer {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .col {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .protocol {
    top: 100px;
    left: 330px;
    font-size: 68px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .hippocratEcosystem,
  .hippocratEstablishesSustaina,
  .protocol {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .hippocratEcosystem {
    top: 167px;
    left: 380px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    font-size: 22px;
  }
  .hippocratEstablishesSustaina {
    top: 243px;
    left: 179px;
    font-size: 34px;
    line-height: 47px;
    display: inline-block;
    width: 642px;
    height: 118px;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates,
  .healthcareDataRequires {
    position: absolute;
    top: 703px;
    left: 30px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 261px;
    height: 187px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates {
    top: 739px;
    left: 370px;
  }
  .healthcareDataDemands {
    top: 780px;
    left: 710px;
  }
  .firstImg {
    position: absolute;
    top: 540px;
    left: 55px;
    width: 157px;
    height: 157px;
  }
  .secondImg {
    position: absolute;
    top: 575px;
    left: 395px;
    width: 157px;
    height: 157px;
  }
  .thirdImg {
    position: absolute;
    top: 615px;
    left: 735px;
    width: 157px;
    height: 157px;
  }

  .icon,
  .image17Icon {
    position: absolute;
    object-fit: cover;
  }
  .image17Icon {
    top: 945px;
    left: 399px;
    width: 1px;
    height: 1px;
  }
  .icon {
    top: 985px;
    left: 0;
    width: 1000px;
    height: 450px;
  }
  .rectangleDiv {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: var(--br-xl) var(--br-xl) 0 0;
    background-color: var(--color-black);
    width: 100%;
    // + 100px
    height: 1119px;
  }
  .protocol1000Child1,
  .protocol1000Child2,
  .protocol1000Child3 {
    position: absolute;
    top: 1578px;
    left: calc(50% - 415px);
    width: 283px;
    height: 264px;
  }
  .protocol1000Child2,
  .protocol1000Child3 {
    top: 2189px;
  }
  .protocol1000Child3 {
    left: calc(50% - 468px);
    top: 1875px;
    width: 390px;
  }
  .theHippocratWallet,
  .theUseOf,
  .utilizingTheHighly {
    position: absolute;
    top: 1999px;
    left: 425px;
    letter-spacing: -0.02em;
    line-height: 32px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 480px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .theHippocratWallet,
  .utilizingTheHighly {
    top: 2337px;
  }
  .utilizingTheHighly {
    top: 1771px;
  }
  .leveraging {
    margin: 0;
  }
  .leveragingTheBitcoinContainer1 {
    width: 100%;
  }
  .implementingDidContainer,
  .leveragingTheBitcoinContainer {
    position: absolute;
    left: 425px;
    font-size: var(--font-size-31xl);
    letter-spacing: -0.02em;
    color: var(--color-white);
    display: flex;
    align-items: center;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .leveragingTheBitcoinContainer {
    top: 1641px;
    line-height: 55px;
    width: 400px;
  }
  .implementingDidContainer {
    top: 1924px;
    width: 575px;
  }
  .wallet {
    font-family: var(--font-geomanist);
  }
  .providingTheHippocratContainer {
    position: absolute;
    top: 2207px;
    left: 425px;
    font-size: var(--font-size-31xl);
    letter-spacing: -0.02em;
    line-height: 55px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .protocol1000 {
    position: relative;
    // background-color: var(--color-white);
    width: 100%;
    height: 2604px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -300px;
    img {
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 1019px) and (min-width: 820px) {
  .protocol800Child {
    position: absolute;
    top: 476px;
    left: 0;
    border-radius: var(--br-lg);
    background-color: var(--color-white);
    width: 253px;
    height: 410px;
  }
  .first {
    position: absolute;
    top: 505px;
    left: 30px;
    letter-spacing: -0.02em;
  }
  .protocol800Item,
  .rectangleDiv {
    position: absolute;
    top: 554px;
    left: 547px;
    border-radius: var(--br-lg);
    background-color: var(--color-white);
    width: 253px;
    height: 410px;
  }
  .rectangleDiv {
    top: 513px;
    left: 273px;
  }
  .second,
  .third {
    position: absolute;
    top: 542px;
    left: 303px;
    letter-spacing: -0.02em;
  }
  .third {
    top: 583px;
    left: 577px;
  }
  .o {
    font-family: var(--font-bodoni-72);
  }
  .protocol {
    top: 103px;
    left: 250px;
    font-size: 60px;
    line-height: 42px;
  }
  .hippocratEcosystem,
  .hippocratEstablishesSustaina,
  .protocol {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .hippocratEcosystem {
    top: 167px;
    left: 291px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .hippocratEstablishesSustaina {
    top: 243px;
    left: calc(50% - 332px);
    font-size: 32px;
    line-height: 46px;
    display: inline-block;
    width: 664px;
    height: 118px;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates,
  .healthcareDataRequires {
    position: absolute;
    top: 698px;
    left: 30px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 193px;
    font-size: 14px;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates {
    top: 734px;
    left: 303px;
  }
  .healthcareDataDemands {
    top: 775px;
    left: 577px;
  }
  .image17Icon {
    position: absolute;
    top: 947px;
    left: 399px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .protocol800Child1 {
    position: absolute;
    bottom: 0;
    border-radius: var(--br-xl) var(--br-xl) 0 0;
    background-color: var(--color-black);
    width: 100%;
    height: 1135px;
  }
  .maskGroupIcon,
  .maskGroupIcon1 {
    position: absolute;
    top: 1607px;
    left: calc(50% - 361px);
    width: 283px;
    height: 264px;
    object-fit: cover;
  }
  .maskGroupIcon1 {
    top: 2186px;
    left: calc(50% - 337px);
    width: 259.42px;
    height: 242px;
  }
  .ob8Icon {
    position: absolute;
    top: 1824px;
    left: 0;
    width: 385.83px;
    height: 385.83px;
    object-fit: contain;
  }
  .theHippocratWallet,
  .theUseOf,
  .utilizingTheHighly {
    position: absolute;
    top: 2044px;
    left: 342px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 375px;
    font-size: 14px;
  }
  .theHippocratWallet,
  .utilizingTheHighly {
    top: 2339px;
  }
  .utilizingTheHighly {
    top: 1773px;
  }
  .leveraging {
    margin: 0;
  }
  .leveragingTheBitcoinContainer1 {
    width: 100%;
  }
  .leveragingTheBitcoinContainer {
    position: absolute;
    top: 1645px;
    left: 342px;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.02em;
    line-height: 55px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 458px;
  }
  .didVc {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .implementingDidContainer {
    position: absolute;
    top: 1915px;
    left: 342px;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.02em;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 458px;
  }
  .wallet {
    font-family: var(--font-geomanist);
  }
  .providingTheHippocratContainer {
    position: absolute;
    top: 2211px;
    left: 342px;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.02em;
    line-height: 55px;
    color: var(--color-white);
    display: flex;
    align-items: center;
  }
  .maskGroupIcon2,
  .maskGroupIcon3,
  .pilsIcon {
    position: absolute;
    top: 531px;
    left: 45px;
    width: 140px;
    height: 140px;
    object-fit: cover;
  }
  .maskGroupIcon2,
  .maskGroupIcon3 {
    top: 609px;
    left: 592px;
  }
  .maskGroupIcon3 {
    top: 568px;
    left: 318px;
  }
  .protocol800 {
    position: relative;
    width: 100%;
    height: 2622px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -400px;
    img {
      pointer-events: none;
    }
  }
  .icon {
    position: absolute;
    top: 985px;
    left: 0;
    width: 800px;
    height: 360px;
  }
}
