@media screen and (min-width: 1020px) {
  .dao1000Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 524px;
  }
  .theGovernance {
    margin: 0;
  }
  .process {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theGovernanceProcessContainer1 {
    width: 100%;
  }
  .theGovernanceProcessContainer {
    position: absolute;
    top: 635px;
    left: 0;
    font-size: var(--font-size-49xl);
    letter-spacing: -0.02em;
    color: var(--color-black);
    display: flex;
    align-items: center;
    width: 844px;
  }
  .dao1000Inner,
  .dao1000Item,
  .rectangleDiv {
    position: absolute;
    top: 837px;
    left: 340px;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 320px;
    height: 515px;
  }
  .dao1000Inner,
  .rectangleDiv {
    left: 0;
  }
  .rectangleDiv {
    top: 1482px;
    width: 999px;
  }
  .dao1000Child1,
  .dao1000Child2,
  .vectorIcon {
    position: absolute;
    top: 1745px;
    left: 320px;
    width: 21px;
    height: 59px;
  }
  .dao1000Child1,
  .dao1000Child2 {
    left: 658px;
  }
  .dao1000Child2 {
    top: 837px;
    left: 679px;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 320px;
    height: 515px;
  }
  .toSecureRegionalContainer,
  .toVerifyTheContainer {
    top: 1003px;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 265px;
    height: 69px;
  }
  .toVerifyTheContainer {
    position: absolute;
    left: 368px;
    letter-spacing: -0.02em;
  }
  .toSecureRegionalContainer {
    left: 28px;
  }
  .exOneYearContainer,
  .phase1,
  .toSecureRegionalContainer {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .exOneYearContainer {
    top: 1157px;
    left: 368px;
    font-size: var(--font-size-mini);
    line-height: 23px;
    font-family: var(--font-inter);
    color: var(--color-gainsboro);
    display: inline-block;
    width: 260px;
    height: 115px;
  }
  .phase1 {
    top: 7px;
    left: 15px;
  }
  .groupChild {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-sm);
    border: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 75px;
    height: 28px;
  }
  .phase1Parent,
  .phase2Parent,
  .phase3Parent {
    position: absolute;
    top: 871px;
    left: 28px;
    width: 75px;
    height: 28px;
    font-size: 14px;
  }
  .phase2Parent,
  .phase3Parent {
    left: 368px;
  }
  .phase3Parent {
    left: 707px;
  }
  .exRecruitingDaoParticipati {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: var(--padding-xl);
    font-size: 14px;
  }
  .exRecruitingDaoContainer {
    position: absolute;
    top: 1157px;
    left: 28px;
    font-size: var(--font-size-mini);
    letter-spacing: -0.02em;
    line-height: 23px;
    font-family: var(--font-inter);
    color: var(--color-gainsboro);
    display: inline-block;
    width: 260px;
    height: 137px;
  }
  .asclepiosStance,
  .hippocratesStance,
  .keironStance {
    position: absolute;
    top: 924px;
    left: 368px;
    font-size: var(--font-size-10xl);
    letter-spacing: -0.02em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen-200);
  }
  .asclepiosStance,
  .hippocratesStance {
    left: 28px;
  }
  .hippocratesStance {
    left: 707px;
  }
  .dao1000Child3,
  .dao1000Child4,
  .dao1000Child5,
  .dao1000Child6,
  .dao1000Child7 {
    position: absolute;
    top: 1648px;
    left: 51px;
    border-radius: var(--br-6xl);
    background-color: var(--color-darkslategray);
    width: 205px;
    height: 50px;
  }
  .dao1000Child4,
  .dao1000Child5,
  .dao1000Child6,
  .dao1000Child7 {
    top: 1837px;
  }
  .dao1000Child5,
  .dao1000Child6,
  .dao1000Child7 {
    top: 1900px;
  }
  .dao1000Child6,
  .dao1000Child7 {
    top: 1711px;
  }
  .dao1000Child7 {
    top: 1774px;
  }
  .dao,
  .phase1Group {
    position: absolute;
    left: 28px;
  }
  .dao {
    top: 1569px;
    font-size: var(--font-size-10xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
  }
  .phase1Group {
    top: 1516px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .governanceProtocol {
    position: absolute;
    top: 1569px;
    left: 368px;
    font-size: var(--font-size-10xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
  }
  .dao1000Child10,
  .dao1000Child8,
  .dao1000Child9 {
    position: absolute;
    top: 1648px;
    left: 384px;
    border-radius: var(--br-24xl);
    background-color: var(--color-white);
    width: 208px;
    height: 84px;
  }
  .dao1000Child10,
  .dao1000Child9 {
    top: 1756px;
  }
  .dao1000Child10 {
    top: 1866px;
  }
  .dao1000Child11,
  .polygonIcon {
    position: absolute;
    top: 1844px;
    left: 476.25px;
    width: 23.32px;
    height: 15px;
    object-fit: contain;
  }
  .dao1000Child11 {
    top: 1735px;
  }
  .phase2Group {
    position: absolute;
    top: 1516px;
    left: 368px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .softwareProtocol {
    position: absolute;
    top: 1569px;
    left: 707px;
    font-size: var(--font-size-10xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
  }
  .bitcoin,
  .execute,
  .github {
    position: absolute;
    font-size: var(--font-size-2xl);
    letter-spacing: -0.02em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 79.07px;
  }
  .execute {
    top: 1895px;
    left: 789px;
    font-weight: 500;
    font-family: var(--font-inter);
    color: var(--color-black);
  }
  .bitcoin,
  .github {
    top: 1902px;
    left: 796.87px;
  }
  .bitcoin {
    top: 1766px;
  }
  .phase3Group {
    position: absolute;
    top: 1516px;
    left: 707px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .exThisFinalContainer,
  .toDisableUsageContainer {
    position: absolute;
    left: 707px;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .toDisableUsageContainer {
    top: 1003px;
    line-height: 25px;
    font-weight: 500;
    width: 265px;
    height: 92px;
  }
  .exThisFinalContainer {
    top: 1157px;
    font-size: var(--font-size-mini);
    line-height: 23px;
    font-family: var(--font-inter);
    color: var(--color-gainsboro);
    width: 260px;
    height: 137px;
  }
  .o {
    font-family: var(--font-bodoni-72);
  }
  .dao1 {
    top: 100px;
    left: 430px;
    font-size: var(--font-size-49xl);
  }
  .dao1,
  .hippocratDaoStrivesContainer,
  .theCoolestForm {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .theCoolestForm {
    top: 167px;
    left: calc(50% - 187px);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen-200);
    font-size: 22px;
  }
  .hippocratDaoStrivesContainer {
    top: 244px;
    left: calc(50% - 387px);
    font-size: 34px;
    line-height: 47px;
    display: inline-block;
    width: 774px;
    height: 172px;
  }
  .bitcoin1Icon,
  .githubMarkWhite1Icon {
    position: absolute;
    top: 1808px;
    left: 797px;
    width: 86px;
    height: 84px;
    object-fit: cover;
  }
  .bitcoin1Icon {
    top: 1674px;
    left: 794px;
    width: 85px;
    height: 85px;
  }
  .dao1000Child12 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 0, 0, 0.2);
    width: 1000px;
    height: 524px;
  }
  .editdaoIcon {
    position: absolute;
    top: 1616px;
    left: 17px;
    width: 272px;
    height: 358px;
    object-fit: cover;
  }
  .appsWg,
  .compilanceSig,
  .policySig,
  .techWg,
  .treasuryWg {
    position: absolute;
    top: 1850px;
    left: 112px;
    letter-spacing: -0.02em;
    color: var(--color-limegreen-100);
  }
  .appsWg,
  .compilanceSig,
  .policySig,
  .treasuryWg {
    top: 1912px;
    left: 107px;
  }
  .appsWg,
  .compilanceSig,
  .treasuryWg {
    top: 1721px;
    left: 110px;
  }
  .compilanceSig,
  .treasuryWg {
    top: 1786px;
    left: 87px;
  }
  .treasuryWg {
    top: 1657px;
    left: 100px;
  }
  .editdaoIcon1 {
    position: absolute;
    top: 1628px;
    left: 368px;
    width: 262px;
    height: 346px;
    object-fit: cover;
  }
  .informalDiscussionTxtContainer {
    line-break: anywhere;
    width: 100%;
  }
  .execute1,
  .informalDiscussion,
  .roughConsensus {
    position: absolute;
    top: 1665px;
    left: 442px;
    letter-spacing: -0.02em;
    color: var(--color-black);
    text-align: center;
    display: flex;
    align-items: center;
    width: 105.43px;
  }
  .execute1,
  .roughConsensus {
    top: 1777px;
    width: 106.44px;
  }
  .execute1 {
    top: 1900px;
    left: 457px;
    justify-content: center;
    width: 79.07px;
  }
  .dao1000 {
    position: relative;
    width: 100%;
    height: 2064px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    margin-top: -500px;

    img {
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 1019px) and (min-width: 820px) {
  .dao800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 524px;
  }
  .o {
    font-family: var(--font-bodoni-72);
  }
  .dao {
    top: 100px;
    left: 349px;
    font-size: var(--font-size-41xl);
  }
  .dao,
  .hippocratDaoStrives,
  .theCoolestForm {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .theCoolestForm {
    top: 167px;
    left: calc(50% - 170px);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    font-size: 20px;
    color: var(--color-limegreen-200);
  }
  .hippocratDaoStrives {
    top: 244px;
    left: calc(50% - 332px);
    font-size: 32px;
    line-height: 46px;
    display: inline-block;
    width: 664px;
    height: 172px;
  }
  .theGovernance {
    margin: 0;
  }
  .process {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theGovernanceProcessContainer1 {
    width: 100%;
  }
  .theGovernanceProcessContainer {
    position: absolute;
    top: 643px;
    left: 0;
    font-size: var(--font-size-41xl);
    letter-spacing: -0.02em;
    color: var(--color-black);
    display: flex;
    align-items: center;
    width: 844px;
  }
  .dao800Inner,
  .dao800Item,
  .rectangleDiv {
    position: absolute;
    top: 837px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 253px;
    height: 498px;
  }
  .dao800Inner,
  .rectangleDiv {
    left: 273px;
  }
  .rectangleDiv {
    left: 547px;
  }
  .toSecureRegionalContainer,
  .toVerifyTheContainer {
    top: 989px;
    font-size: var(--font-size-base);
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 197px;
  }
  .toSecureRegionalContainer {
    position: absolute;
    left: 28px;
    letter-spacing: -0.02em;
    height: 67px;
  }
  .toVerifyTheContainer {
    left: 301px;
    height: 92px;
  }
  .phase1,
  .toDisableUsageContainer,
  .toVerifyTheContainer {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .toDisableUsageContainer {
    top: 989px;
    left: 575px;
    font-size: var(--font-size-base);
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 197px;
    height: 145px;
  }
  .phase1 {
    top: 5px;
    left: 15px;
  }
  .groupChild {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-sm);
    border: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 75px;
    height: 28px;
  }
  .phase1Parent,
  .phase2Parent,
  .phase3Parent {
    position: absolute;
    top: 871px;
    left: 28px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .phase2Parent,
  .phase3Parent {
    left: 301px;
  }
  .phase3Parent {
    left: 575px;
  }
  .exRecruitingDaoParticipati {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: var(--padding-mid);
  }
  .exOneYearContainer,
  .exRecruitingDaoContainer,
  .exRecruitingDaoContainer1 {
    position: absolute;
    top: 1159px;
    left: 28px;
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 20px;
    font-family: var(--font-inter);
    color: var(--color-gainsboro-100);
    display: inline-block;
    width: 197px;
  }
  .exOneYearContainer,
  .exRecruitingDaoContainer1 {
    left: 301px;
  }
  .exRecruitingDaoContainer1 {
    left: 575px;
  }
  .asclepiosStance,
  .hippocratesStance,
  .keironStance {
    position: absolute;
    top: 924px;
    left: 28px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen-200);
  }
  .hippocratesStance,
  .keironStance {
    left: 301px;
  }
  .hippocratesStance {
    left: 575px;
  }
  .dao800Child1 {
    position: absolute;
    top: 1443px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 800px;
    height: 515px;
  }
  .dao800Child2,
  .dao800Child3 {
    position: absolute;
    top: 1609px;
    left: 28px;
    border-radius: var(--br-6xl);
    background-color: var(--color-darkslategray);
    width: 205px;
    height: 50px;
  }
  .dao800Child3 {
    top: 1798px;
  }
  .dao800Child4 {
    position: absolute;
    top: 1861px;
    left: 28px;
  }
  .dao800Child4,
  .dao800Child5,
  .dao800Child6 {
    border-radius: var(--br-6xl);
    background-color: var(--color-darkslategray);
    width: 205px;
    height: 50px;
  }
  .dao800Child5 {
    position: absolute;
    top: 1672px;
    left: 28px;
  }
  .dao800Child6 {
    top: 1735px;
  }
  .dao1,
  .dao800Child6,
  .phase1Group {
    position: absolute;
    left: 28px;
  }
  .dao1 {
    top: 1530px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
  }
  .phase1Group {
    top: 1477px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .governanceProtocol {
    position: absolute;
    top: 1530px;
    left: 294px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
  }
  .dao800Child7 {
    top: 1609px;
    left: 297px;
    background-color: var(--color-white);
    width: 208px;
  }
  .dao800Child10,
  .dao800Child7,
  .dao800Child8,
  .dao800Child9 {
    position: absolute;
    border-radius: var(--br-24xl);
    height: 84px;
  }
  .dao800Child8 {
    top: 1628px;
    left: 608px;
    background-color: #d9d9d9;
    width: 84px;
  }
  .dao800Child10,
  .dao800Child9 {
    top: 1717px;
    left: 297px;
    background-color: var(--color-white);
    width: 208px;
  }
  .dao800Child10 {
    top: 1827px;
  }
  .bitcoin,
  .github {
    position: absolute;
    top: 1720px;
    left: 597px;
    letter-spacing: -0.02em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 106.44px;
  }
  .github {
    top: 1850px;
  }
  .dao800Child11,
  .polygonIcon {
    position: absolute;
    top: 1805px;
    left: 389.25px;
    width: 23.32px;
    height: 15px;
    object-fit: contain;
  }
  .dao800Child11 {
    top: 1696px;
  }
  .phase2Group {
    position: absolute;
    top: 1477px;
    left: 294px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .softwareProtocol {
    position: absolute;
    top: 1530px;
    left: 559px;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
  }
  .phase3Group {
    position: absolute;
    top: 1477px;
    left: 560px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .editdaoIcon {
    position: absolute;
    top: 1592px;
    left: 273px;
    width: 262px;
    height: 346px;
    object-fit: cover;
  }
  .informalDiscussionTxtContainer {
    line-break: anywhere;
    width: 100%;
  }
  .execute,
  .informalDiscussion,
  .roughConsensus {
    position: absolute;
    top: 1629px;
    left: 348px;
    letter-spacing: -0.02em;
    color: var(--color-black);
    text-align: center;
    display: flex;
    align-items: center;
    width: 105.43px;
  }
  .execute,
  .roughConsensus {
    top: 1742px;
    width: 106.44px;
  }
  .execute {
    top: 1864px;
    left: 361px;
    justify-content: center;
    width: 79.07px;
  }
  .editdaoIcon1 {
    position: absolute;
    top: 1581px;
    left: -6px;
    width: 267px;
    height: 351px;
    object-fit: cover;
  }
  .dao800Child12,
  .vectorIcon {
    position: absolute;
    top: 1706px;
    left: 527px;
    width: 21px;
    height: 59px;
  }
  .dao800Child12 {
    left: 255px;
  }
  .appsWg,
  .compilanceSig,
  .policySig,
  .techWg,
  .treasuryWg {
    position: absolute;
    top: 1621px;
    left: 72px;
    letter-spacing: -0.02em;
    color: var(--color-limegreen-100);
  }
  .appsWg,
  .compilanceSig,
  .policySig,
  .techWg {
    top: 1810px;
    left: 89px;
  }
  .appsWg,
  .compilanceSig,
  .policySig {
    top: 1873px;
    left: 84px;
  }
  .appsWg,
  .compilanceSig {
    top: 1684px;
    left: 87px;
  }
  .compilanceSig {
    top: 1747px;
    left: 60px;
  }
  .bitcoin2Icon,
  .githubMarkWhite2Icon {
    position: absolute;
    top: 1628px;
    left: 608px;
    width: 85px;
    height: 85px;
    object-fit: cover;
  }
  .githubMarkWhite2Icon {
    top: 1757px;
    left: 607px;
    width: 86px;
    height: 84px;
  }
  .dao800 {
    position: relative;
    width: 100%;
    height: 2110px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    margin-top: -500px;
    img {
      pointer-events: none;
    }
  }
}
