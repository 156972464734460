@media screen and (min-width: 1020px) {
  .faqChild {
    position: absolute;
    top: 0;
    background-color: #000;
    width: 1000px;
    height: 1149px;
  }
  .forMostOf,
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .forMostOf {
    top: 357px;
    font-size: 20px;
    line-height: 28px;
    width: 568px;
    height: 131px;
  }
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 295px;
    line-height: 35px;
    width: 462px;
  }
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 584px;
  }
  .whenBitcoinAnd1 {
    top: 696px;
  }
  .q {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .faq1,
  .frequentlyAskedQuestions {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 68px;
    letter-spacing: -0.02em;
  }
  .faq1 {
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .frequentlyAskedQuestions {
    top: 167px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .faqChild1,
  .faqInner,
  .faqItem,
  .lineDiv {
    position: absolute;
    top: 246.65px;
    left: -0.35px;
    border-top: 0.7px solid var(--color-white);
    box-sizing: border-box;
    width: 575.7px;
    height: 0.7px;
  }
  .faqChild1,
  .faqInner,
  .lineDiv {
    top: 535.65px;
  }
  .faqChild1,
  .lineDiv {
    top: 647.65px;
  }
  .faqChild1 {
    top: 759.65px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    position: absolute;
    top: 295px;
    left: 547px;
    width: 28px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    top: 584px;
  }
  .chevronLeftIcon2 {
    top: 696px;
  }
  .rectangleDiv {
    position: absolute;
    left: 627px;
    img {
      width: 100%;
    }
  }
  .rectangleDiv {
    top: 100px;
  }
  .faq {
    position: relative;
    width: 100%;
    height: 1149px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
  .footerBg {
    background-color: var(--color-black);
    width: 100%;
    height: 100px;
    margin-bottom: -50px;
  }

  .faqListWrap {
    border-top: 0.7px solid var(--color-white);
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
    top: 247px;
    font-size: 18px;
    line-height: 28px;
    width: 568px;
    height: 131px;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .maskGroupIcon {
    position: absolute;
    top: 56px;
    left: 458.64px;
    width: 341.82px;
    height: 434px;
    object-fit: cover;
  }
  .forMostOf,
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .forMostOf {
    top: 354px;
    font-size: 20px;
    line-height: 28px;
    width: 452px;
    height: 131px;
  }
  .whatIsHippocrat,
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 292px;
    line-height: 35px;
    width: 462px;
  }
  .whenBitcoinAnd,
  .whenBitcoinAnd1 {
    top: 581px;
  }
  .whenBitcoinAnd1 {
    top: 693px;
  }
  .q {
    font-family: var(--font-bodoni-72);
  }
  .faq,
  .frequentlyAskedQuestions {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 60px;
    letter-spacing: -0.02em;
  }
  .frequentlyAskedQuestions {
    top: 164px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .faq800Child,
  .faq800Inner,
  .faq800Item,
  .lineDiv {
    position: absolute;
    top: 243.65px;
    left: -0.35px;
    border-top: 0.7px solid var(--color-white);
    box-sizing: border-box;
    width: 458.7px;
    height: 0.7px;
  }
  .faq800Inner,
  .faq800Item,
  .lineDiv {
    top: 532.65px;
  }
  .faq800Inner,
  .lineDiv {
    top: 644.65px;
  }
  .lineDiv {
    top: 756.65px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    position: absolute;
    top: 288px;
    left: 430px;
    width: 28px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronLeftIcon1,
  .chevronLeftIcon2 {
    top: 577px;
  }
  .chevronLeftIcon2 {
    top: 689px;
  }
  .faq800 {
    position: relative;
    background-color: #000;
    width: 100%;
    height: 1100px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-3xl);
    color: var(--color-white);
    font-family: var(--font-geomanist);
  }

  .faqListWrap {
    border-top: 0.7px solid var(--color-white);
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
    top: 247px;
    font-size: 18px;
    line-height: 28px;
    width: 452px;
    height: 131px;
  }
}
