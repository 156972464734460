@media screen and (min-width: 1020px) {
  .hippocratIsThe {
    margin: 0;
  }
  .hippocratIsTheContainer,
  .web10Container,
  .whatIsWeb3,
  .whyHippocrat {
    position: absolute;
    top: 2133px;
    left: 511px;
    line-height: 28px;
    display: inline-block;
    width: 488px;
    height: 354px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .web10Container,
  .whatIsWeb3,
  .whyHippocrat {
    top: 1534px;
    height: 363px;
  }
  .whatIsWeb3,
  .whyHippocrat {
    top: 2133px;
    left: 0;
    font-size: var(--font-size-28xl);
    letter-spacing: -0.02em;
    line-height: 35px;
    width: 473px;
    height: 84px;
    font-weight: 700;
    font-family: var(--font-geomanist);
  }
  .whatIsWeb3 {
    top: 1534px;
    width: 490px;
    font-weight: 700;
    font-family: var(--font-geomanist);
  }
  .learn01Child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1438px;
  }
  .blockchainIsA,
  .forMostOf,
  .inThisWay,
  .whatIsBlockchain {
    position: absolute;
    top: 687px;
    left: 510px;
    line-height: 28px;
    color: var(--color-white);
    display: inline-block;
    width: 489px;
    font-size: 16px;
  }
  .blockchainIsA,
  .forMostOf,
  .inThisWay {
    font-family: var(--font-inter);
  }
  .blockchainIsA,
  .inThisWay,
  .whatIsBlockchain {
    top: 1203px;
    left: 511px;
    letter-spacing: -0.02em;
  }
  .blockchainIsA,
  .whatIsBlockchain {
    top: 1000px;
  }
  .whatIsBlockchain {
    top: 687px;
    left: 0;
    font-size: var(--font-size-28xl);
    line-height: 35px;
    width: 490px;
    height: 84px;
    font-weight: 700;
  }
  .chain {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .eb3 {
    font-family: var(--font-bodoni-72);
    font-weight: 700;
  }
  .blockchainWeb3Container,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .blockchainWeb3Container {
    top: 100px;
    font-size: 68px;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .theComingFuture {
    top: 167px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn01Item {
    position: absolute;
    top: 243px;
    left: calc(50% - 500px);
    width: 1000px;
    height: 400px;
    img {
      width: 100%;
      border-radius: var(--br-11xl);
    }
  }
  .learn01Inner {
    position: absolute;
    top: 2045.5px;
    left: calc(50% - 500.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 1001px;
    height: 1px;
  }
  .nextUseCaseContainer {
    position: absolute;
    top: 2530px;
    left: 613px;
    font-size: 34px;
    letter-spacing: -0.02em;
    font-weight: 700;
  }
  .selfSovereignHealthcareData {
    position: absolute;
    top: 2579px;
    left: 613px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 2571px;
    left: 881px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    object-fit: contain;
  }
  .learn01 {
    position: relative;
    width: 100%;
    height: 2820px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .hippocratIsThe {
    margin: 0;
  }
  .hippocratIsTheContainer,
  .web10Container {
    position: absolute;
    top: 2130px;
    left: 411px;
    line-height: 25px;
    display: inline-block;
    width: 373px;
    font-size: 14px;
  }
  .web10Container {
    top: 1531px;
    height: 363px;
    font-size: 14px;
  }
  .whatIsWeb3,
  .whyHippocrat {
    position: absolute;
    left: 15px;
    font-size: var(--font-size-23xl);
    letter-spacing: -0.02em;
    line-height: 35px;
    display: inline-block;
    width: 375px;
  }
  .whyHippocrat {
    top: 2130px;
    height: 84px;
  }
  .whatIsWeb3 {
    top: 1531px;
  }
  .learn800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1438px;
  }
  .blockchainIsA,
  .forMostOf,
  .inThisWay,
  .whatIsBlockchain {
    position: absolute;
    top: 683px;
    left: 411px;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 374px;
    font-size: 14px;
  }
  .blockchainIsA,
  .inThisWay,
  .whatIsBlockchain {
    top: 1200px;
    letter-spacing: -0.02em;
  }
  .blockchainIsA,
  .whatIsBlockchain {
    top: 997px;
  }
  .whatIsBlockchain {
    top: 684px;
    left: 15px;
    font-size: var(--font-size-23xl);
    line-height: 35px;
    width: 375px;
  }
  .chain {
    font-family: var(--font-bodoni-72);
  }
  .blockchainWeb3Container,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .blockchainWeb3Container {
    top: 100px;
    font-size: 60px;
    color: var(--color-white);
  }
  .theComingFuture {
    top: 164px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn800Item,
  .maskGroupIcon {
    position: absolute;
    top: 240px;
    height: 400px;
  }
  .learn800Item {
    left: calc(50% - 400px);
    border-radius: var(--br-11xl);
    background-color: #d9d9d9;
    width: 800px;
  }
  .maskGroupIcon {
    width: 800px;
    object-fit: cover;
    border-radius: var(--br-11xl);
  }
  .learn800Inner {
    position: absolute;
    top: 2042.5px;
    left: calc(50% - 400.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 801px;
    height: 1px;
  }
  .nextUseCaseContainer {
    position: absolute;
    top: 2516px;
    left: 410px;
    font-size: 30px;
    letter-spacing: -0.02em;
  }
  .selfSovereignHealthcareData {
    position: absolute;
    top: 2563px;
    left: 410px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 2556px;
    left: 682px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    object-fit: contain;
  }
  .learn800 {
    position: relative;
    width: 100%;
    height: 2777px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
