@media screen and (max-width: 819px) and (min-width: 500px) {
  .about500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-xl) var(--br-xl);
    background-color: var(--color-black);
    width: 100%;
    height: 780px;
  }
  .weAreNot {
    margin: 0;
  }
  .or {
    color: var(--color-white);
  }
  .platformOrService,
  .service {
    color: var(--color-black);
  }
  .platformOrService {
    margin: 0;
  }
  .weAreNotContainer {
    position: absolute;
    top: 152px;
    left: calc(50% - 204px);
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    display: flex;
    align-items: center;
    width: 408px;
    text-shadow:
      1px 0 0#fff,
      0 1px 0#fff,
      -1px 0 0#fff,
      0-1px 0#fff;
    color: var(--color-white);
  }
  .weAreA {
    font-family: var(--font-geomanist);
  }
  .ch {
    font-family: var(--font-bodoni-72);
  }
  .weAreAHippoCh {
    letter-spacing: -0.02em;
  }
  .weAreAContainer {
    position: absolute;
    top: 297px;
    left: calc(50% - 207px);
    font-size: var(--font-size-16xl);
    line-height: 45px;
    background: radial-gradient(50% 50%at 50% 50%, #80ff45 3.89%, #00dab2);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 414px;
  }
  .b {
    letter-spacing: -0.06em;
  }
  .o1 {
    letter-spacing: -0.07em;
  }
  .p1 {
    letter-spacing: -0.1em;
  }
  .aboutHippoTxtContainer {
    width: 100%;
  }
  .aboutHippo {
    position: absolute;
    top: -68px;
    left: 1px;
    font-size: 27px;
    color: var(--color-white);
    white-space: pre-wrap;
    text-align: left;
    display: flex;
    align-items: center;
    width: 180px;
    height: 19.51px;
    font-family: var(--font-bodoni-72);
  }
  .icon,
  .icon1 {
    position: absolute;
    top: -72px;
    left: 431px;
    width: 30.38px;
    height: 30.38px;
    overflow: hidden;
  }
  .icon1 {
    left: 469.48px;
  }
  .about500Item {
    position: absolute;
    top: -60px;
    left: 177.5px;
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 245px;
    height: 1px;
  }
  .didYouKnowContainer,
  .hippocratsIdentityDraws,
  .theSpecialHippoContainer {
    position: absolute;
    top: 875px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 46px;
    text-align: left;
    display: inline-block;
    width: 392px;
    height: 118px;
  }
  .hippocratsIdentityDraws,
  .theSpecialHippoContainer {
    top: 1478px;
    font-size: var(--font-size-base);
    line-height: 25px;
    width: 413px;
    height: 224px;
  }
  .theSpecialHippoContainer {
    top: 2232px;
    left: 3px;
    width: 410px;
  }
  .about500Inner {
    position: absolute;
    top: 2617px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: #76f15c;
    width: 500px;
    height: 432px;
  }
  .toRestoreIndividuals {
    position: absolute;
    top: 2763px;
    left: calc(50% - 207px);
    font-size: 18px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 414px;
  }
  .ion {
    letter-spacing: -0.04em;
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .mission {
    position: absolute;
    top: 2708px;
    left: 186px;
    text-align: left;
  }
  .rectangleDiv {
    position: absolute;
    top: 3069px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 500px;
    height: 304px;
  }
  .toForgeA,
  .vision {
    position: absolute;
    color: var(--color-white);
  }
  .toForgeA {
    top: 3226px;
    left: calc(50% - 206px);
    font-size: 18px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 413px;
  }
  .vision {
    top: 3171px;
    left: 198px;
  }
  .about500Child1 {
    position: absolute;
    top: 3393px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: #199fff;
    width: 500px;
    height: 304px;
  }
  .patientCentricEquityTrans {
    position: absolute;
    top: 3550px;
    left: calc(50% - 206px);
    font-size: 18px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 413px;
  }
  .coreValues {
    position: absolute;
    top: 3495px;
    left: 156px;
    line-height: 58px;
  }
  .partnership,
  .wePrioritizePatients {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .wePrioritizePatients {
    top: 3819px;
    left: 43px;
    line-height: 46px;
    display: inline-block;
    width: 414px;
    height: 131px;
  }
  .partnership {
    top: 4220px;
    left: calc(50% - 250px);
    width: 500px;
    line-height: 58px;
    text-align: center;
  }
  .about500Child2,
  .about500Child3 {
    position: absolute;
    top: 1033px;
    left: calc(50% - 252px);
    border-radius: var(--br-lg);
    background-color: var(--color-whitesmoke);
    width: 435px;
    height: 405px;
  }
  .about500Child3 {
    top: 1787px;
  }
  .about500 {
    position: relative;
    width: 100%;
    height: 4350px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-13xl);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .aboutUsVideo {
    position: absolute;
    top: -70px;
  }
  .about1000Child1 {
    position: absolute;
    top: 900px;
    left: calc(50% - 252px);
    width: 435px;
  }
  .about1000Child2 {
    position: absolute;
    top: 1750px;
    left: calc(50% - 600px);
    border-radius: var(--br-lg);
    width: 1100px;
  }
  .image55Icon {
    position: absolute;
    top: 4320px;
    left: 184px;
    width: 125px;
    height: 27px;
  }
  .image56Icon {
    position: absolute;
    top: 4421px;
    left: 255px;
    width: 125px;
    height: 27px;
  }
  .image57Icon {
    position: absolute;
    top: 4410px;
    left: 140px;
    width: 107px;
    height: 48px;
  }
  .image58Icon {
    position: absolute;
    top: 4320px;
    left: 70px;
    width: 90px;
    height: 27px;
  }
  .image59Icon {
    position: absolute;
    top: 4320px;
    left: 330px;
    width: 101px;
    height: 29px;
  }

  .partnerImageWrapper {
    position: absolute;
    top: 4300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .partnerImageBox {
    display: flex;
    gap: 7px;
    img {
      cursor: pointer !important;
      width: 120px;
      height: 48px;
    }
  }

  .image68Icon {
    height: 27px;
    object-fit: cover;
  }
  .image69Icon {
    height: 27px;
    object-fit: cover;
  }
  .image70Icon {
    height: 27px;
    object-fit: cover;
  }
  .image71Icon {
    height: 27px;
    object-fit: cover;
  }
  .partnerImageWrapper2 {
    position: absolute;
    top: 4420px;
    width: 100%;
    display: flex;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }

  .partnerIconWrapper {
    position: absolute;
    top: 4320px;
    width: 100%;
    display: flex;
    gap: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .partnerIconWrapper2 {
    position: absolute;
    top: 4370px;
    width: 100%;
    display: flex;
    gap: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .partnerIconWrapper3 {
    position: absolute;
    top: 4440px;
    width: 100%;
    display: flex;
    gap: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .image100Icon {
    height: 27px;
    object-fit: cover;
  }
  .image101Icon {
    height: 27px;
    object-fit: cover;
  }
  .image102Icon {
    height: 27px;
    object-fit: cover;
  }
  .image103Icon {
    height: 50px;
    object-fit: cover;
  }
  .image104Icon {
    height: 27px;
    object-fit: cover;
  }
  .image105Icon {
    height: 22px;
    object-fit: cover;
  }
  .image106Icon {
    height: 27px;
    object-fit: cover;
  }
  .image107Icon {
    height: 24px;
    object-fit: cover;
  }
}
@media screen and (max-width: 500px) {
  .about360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-xl) var(--br-xl);
    background-color: var(--color-black);
    width: 100%;
    height: 615px;
  }
  .weAreNot {
    margin: 0;
  }
  .or {
    color: var(--color-white);
  }
  .platformOrService,
  .service {
    color: var(--color-black);
  }
  .platformOrService {
    margin: 0;
  }
  .weAreNotContainer {
    position: absolute;
    top: 157px;
    left: calc(50% - 204px);
    letter-spacing: -0.02em;
    line-height: 40px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    display: flex;
    align-items: center;
    width: 408px;
    text-shadow:
      1px 0 0#fff,
      0 1px 0#fff,
      -1px 0 0#fff,
      0-1px 0#fff;
    color: var(--color-white);
  }
  .weAreA {
    font-family: var(--font-geomanist);
  }
  .ch {
    font-family: var(--font-bodoni-72);
  }
  .weAreAHippoCh {
    letter-spacing: -0.02em;
  }
  .weAreAContainer {
    position: absolute;
    top: 297px;
    left: calc(50% - 207px);
    font-size: 35px;
    line-height: 45px;
    background: radial-gradient(50% 50%at 50% 50%, #80ff45 3.89%, #00dab2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 414px;
  }
  .b {
    letter-spacing: -0.06em;
  }
  .o1 {
    letter-spacing: -0.07em;
  }
  .p1 {
    letter-spacing: -0.1em;
  }
  .aboutHippoTxtContainer {
    width: 100%;
  }
  .aboutHippo {
    position: absolute;
    top: 132px;
    left: 1px;
    font-size: 25px;
    color: var(--color-white);
    white-space: pre-wrap;
    text-align: left;
    display: flex;
    align-items: center;
    width: 180px;
    height: 19.51px;
    font-family: var(--font-bodoni-72);
  }
  .icon,
  .icon1 {
    position: absolute;
    top: 132px;
    right: 22px;
    width: 21px;
    height: 21px;
    overflow: hidden;
  }
  .icon1 {
    right: 0;
  }
  .about360Item {
    position: absolute;
    top: 140.5px;
    left: 160.5px;
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: calc(100% - 208px);
    height: 1px;
  }
  .didYouKnowContainer,
  .hippocratsIdentityDraws,
  .theSpecialHippoContainer {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: left;
    display: inline-block;
  }
  .didYouKnowContainer {
    top: 893px;
    left: 0;
    line-height: 40px;
    width: 360px;
  }
  .hippocratsIdentityDraws,
  .theSpecialHippoContainer {
    font-size: var(--font-size-base);
    line-height: 25px;
  }
  .hippocratsIdentityDraws {
    top: 1433px;
    left: 0;
    width: 360px;
  }
  .theSpecialHippoContainer {
    top: 2179px;
    left: calc(50% - 179px);
    width: 357px;
  }
  .about360Inner {
    position: absolute;
    top: 2603px;
    left: calc(50% - 180px);
    border-radius: var(--br-11xl);
    background-color: #76f15c;
    width: 360px;
    height: 432px;
  }
  .toRestoreIndividuals {
    position: absolute;
    top: 2735px;
    left: calc(50% - 151px);
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 300px;
  }
  .ion {
    letter-spacing: -0.04em;
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .mission {
    position: absolute;
    top: 2681px;
    left: calc(50% - 50px);
    text-align: left;
  }
  .rectangleDiv {
    position: absolute;
    top: 3055px;
    left: calc(50% - 180px);
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 360px;
    height: 304px;
  }
  .toForgeA,
  .vision {
    position: absolute;
    color: var(--color-white);
  }
  .toForgeA {
    top: 3182px;
    left: calc(50% - 148px);
    font-size: 18px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 300px;
  }
  .vision {
    top: 3128px;
    left: calc(50% - 45px);
  }
  .about360Child1 {
    position: absolute;
    top: 3379px;
    left: calc(50% - 180px);
    border-radius: var(--br-11xl);
    background-color: #199fff;
    width: 360px;
    height: 304px;
  }
  .patientCentricEquityTrans {
    position: absolute;
    top: 3536px;
    left: calc(50% - 151px);
    font-size: 18px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 300px;
  }
  .coreValues {
    position: absolute;
    top: 3464px;
    left: calc(50% - 82px);
    line-height: 58px;
  }
  .partnership,
  .wePrioritizePatients {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .wePrioritizePatients {
    top: 3806px;
    left: calc(50% - 180px);
    font-size: 21px;
    line-height: 31px;
    display: inline-block;
    width: 360px;
  }
  .partnership {
    top: 4091px;
    left: calc(50% - 160px);
    width: 320px;
    line-height: 58px;
    text-align: center;
  }
  .about360Child2,
  .about360Child3 {
    position: absolute;
    top: 972px;
    left: calc(50% - 180px);
    width: 360px;
    height: 360px;
  }
  .about360Child3 {
    top: 1600px;
    width: 1000px;
    left: calc(50% - 535px);
  }
  .about360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 4000px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-9xl);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .image55Icon {
    position: absolute;
    top: 4265px;
    left: calc(50% - 135px);
    width: 123px;
    height: 27px;
  }
  .image56Icon {
    position: absolute;
    top: 4322px;
    left: calc(50% - 135px);
    width: 125px;
    height: 27px;
  }
  .image57Icon {
    position: absolute;
    top: 4190px;
    left: calc(50% + 10px);
    width: 107px;
    height: 48px;
  }
  .image58Icon {
    position: absolute;
    top: 4200px;
    left: calc(50% - 115px);
    width: 90px;
    height: 27px;
  }
  .image59Icon {
    position: absolute;
    top: 4262px;
    left: calc(50% + 10px);
    width: 101px;
    height: 29px;
  }
  .aboutUsVideo {
    position: absolute;
    top: 0;
  }
  .partnerImageWrapper {
    position: absolute;
    top: 4180px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .partnerImageBox {
    display: flex;
    gap: 7px;
    img {
      cursor: pointer !important;
      width: 85px;
      height: 34px;
    }
  }

  .image100Icon {
    height: 27px;
    object-fit: cover;
  }
  .image101Icon {
    height: 27px;
    object-fit: cover;
  }
  .image102Icon {
    height: 27px;
    object-fit: cover;
  }
  .image103Icon {
    height: 50px;
    object-fit: cover;
  }
  .image104Icon {
    height: 27px;
    object-fit: cover;
  }
  .image105Icon {
    height: 19px;
    object-fit: cover;
  }
  .image106Icon {
    height: 27px;
    object-fit: cover;
  }
  .image107Icon {
    height: 22px;
    object-fit: cover;
  }
}
