@media screen and (min-width: 1020px) {
  .communityWrapepr {
    display: flex;
    padding: 100px 0px 143px 0px;
    flex-direction: column;
    background: #000;
  }
  .communityTitle {
    color: #76f15c;
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px; /* 100% */
    letter-spacing: -1.2px;
    text-transform: uppercase;
    margin-bottom: 140px;
  }
  .overviewTitle {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.88px;
    span {
      color: #fff;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Bodoni 72 Oldstyle';
      font-size: 44px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -1.76px;
    }
  }
  .overviewText {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    margin-top: 40px;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .communityWrapepr {
    display: flex;
    padding: 100px 0px 143px 0px;
    flex-direction: column;
    background: #000;
  }
  .communityTitle {
    color: #76f15c;
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px; /* 100% */
    letter-spacing: -1.2px;
    text-transform: uppercase;
    margin-bottom: 140px;
  }
  .overviewTitle {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.88px;
    span {
      color: #fff;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Bodoni 72 Oldstyle';
      font-size: 44px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -1.76px;
    }
  }
  .overviewText {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Geomanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    margin-top: 40px;
  }
}
