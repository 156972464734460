@media screen and (max-width: 819px) and (min-width: 500px) {
  .sustainableBenefit {
    position: absolute;
    top: 134px;
    left: calc(50% - 89px);
    font-size: var(--font-size-lg);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    text-align: center;
  }
  .hipp,
  .sustainableBenefit {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.11em;
  }
  .crat {
    letter-spacing: -0.07em;
  }
  .crat,
  .n {
    font-family: var(--font-bodoni-72);
  }
  .hippocratTokenTxtContainer {
    width: 100%;
  }
  .hippocratToken,
  .theHpoToken {
    position: absolute;
    color: var(--color-white);
    text-align: center;
  }
  .hippocratToken {
    top: 75px;
    left: calc(50% - 207px);
    font-size: 45px;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 413px;
  }
  .theHpoToken {
    top: 210px;
    left: 43px;
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 414px;
    font-size: 32px;
  }
  .token500Child {
    position: absolute;
    top: 534px;
    left: calc(50% - 250px);
    border-radius: 30px;
    background-color: var(--color-white);
    width: 500px;
    height: 1449px;
  }
  .token500Item {
    position: absolute;
    top: 686px;
    left: calc(50% - 154px);
    border-radius: 18px;
    background-color: #f6f8f9;
    width: 308px;
    height: 259px;
  }
  .totalTokenSupply {
    position: absolute;
    top: 1249px;
    left: calc(50% - 179px);
    font-size: 23px;
    letter-spacing: -0.02em;
  }
  .div {
    position: absolute;
    top: 1287px;
    left: calc(50% - 166px);
    font-size: var(--font-size-lg);
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
  }
  .annotation,
  .hpoIsA {
    position: absolute;
    left: calc(50% - 207px);
    letter-spacing: -0.02em;
    font-family: var(--font-inter);
    color: var(--color-dimgray);
    display: inline-block;
    width: 414px;
  }
  .hpoIsA {
    top: 1414px;
    font-size: 13px;
    line-height: 23px;
    height: 35px;
  }
  .annotation {
    top: 1377px;
    font-size: 17px;
    text-transform: capitalize;
  }
  .daoCommunity,
  .earlyEcosystemParticipants {
    position: absolute;
    top: 999px;
    left: calc(50% - 175px);
    font-size: var(--font-size-5xl);
    letter-spacing: -0.02em;
  }
  .earlyEcosystemParticipants {
    top: 1124px;
  }
  .b,
  .b1 {
    position: absolute;
    top: 1037px;
    left: calc(50% - 162px);
    font-size: var(--font-size-9xl);
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
  }
  .b1 {
    top: 1162px;
    left: calc(50% - 149px);
  }
  .rectangleDiv,
  .token500Inner {
    position: absolute;
    top: 1007px;
    left: 43px;
    background-color: #19d64a;
    width: 13px;
    height: 13px;
  }
  .rectangleDiv {
    top: 1133px;
    background-color: #05a1ff;
  }
  .listingExchange,
  .tokenAllocation {
    position: absolute;
    top: 610px;
    left: 43px;
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .listingExchange {
    top: 1646px;
  }
  .image54Icon,
  .image55Icon,
  .image56Icon {
    position: absolute;
    top: 1768px;
    left: 330px;
    width: 142px;
    height: 41px;
    object-fit: cover;
  }
  .image54Icon,
  .image56Icon {
    top: 1773px;
    left: 23px;
    width: 105px;
    height: 31px;
  }
  .image56Icon {
    left: 160px;
    width: 147px;
    height: 32px;
  }
  .followingBip3244 {
    color: #b4b4b4;
  }
  .deriveAccountAddressContainer {
    position: absolute;
    top: 634px;
    left: 1245px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 240px;
  }
  .token500 {
    position: relative;
    width: 100%;
    height: 2132px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-13xl);
    font-family: var(--font-geomanist);
    margin-top: -300px;
  }
}
@media screen and (max-width: 500px) {
  .sustainableBenefit {
    position: absolute;
    top: 134px;
    left: calc(50% - 89px);
    font-size: var(--font-size-lg);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
    text-align: center;
  }
  .hipp,
  .sustainableBenefit {
    letter-spacing: -0.02em;
  }
  .o {
    letter-spacing: -0.11em;
  }
  .crat {
    letter-spacing: -0.07em;
  }
  .crat,
  .n {
    font-family: var(--font-bodoni-72);
  }
  .hippocratTokenTxtContainer {
    width: 100%;
  }
  .hippocratToken {
    position: absolute;
    top: 79px;
    left: calc(50% - 207px);
    font-size: 35px;
    color: var(--color-white);
    white-space: pre-wrap;
    text-align: center;
    display: flex;
    align-items: center;
    width: 413px;
  }
  .theHpoToken,
  .token360Child {
    position: absolute;
    left: calc(50% - 180px);
    width: 360px;
  }
  .theHpoToken {
    top: 210px;
    letter-spacing: -0.02em;
    line-height: 40px;
    display: inline-block;
    color: var(--color-white);
    text-align: center;
    font-size: 28px;
  }
  .token360Child {
    top: 534px;
    border-radius: 30px;
    background-color: var(--color-white);
    height: 1522px;
  }
  .token360Item {
    position: absolute;
    top: 686px;
    left: calc(50% - 154px);
    border-radius: 18px;
    background-color: #f6f8f9;
    width: 308px;
    height: 259px;
  }
  .div,
  .totalTokenSupply {
    position: absolute;
    left: calc(50% - 128px);
  }
  .totalTokenSupply {
    top: 1249px;
    font-size: 23px;
    letter-spacing: -0.02em;
  }
  .div {
    top: 1287px;
    font-size: var(--font-size-lg);
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
  }
  .annotation,
  .hpoIsA {
    position: absolute;
    left: 10px;
    letter-spacing: -0.02em;
    font-family: var(--font-inter);
    color: var(--color-dimgray);
    display: inline-block;
    width: calc(100% - 20px);
  }
  .hpoIsA {
    top: 1414px;
    font-size: 14px;
    line-height: 23px;
    height: 35px;
  }
  .annotation {
    top: 1377px;
    font-size: 17px;
    text-transform: capitalize;
  }
  .daoCommunity {
    top: 999px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
  }
  .b,
  .b1,
  .daoCommunity,
  .earlyEcosystemParticipants {
    position: absolute;
    left: calc(50% - 128px);
  }
  .earlyEcosystemParticipants {
    top: 1124px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
  }
  .b,
  .b1 {
    top: 1037px;
    text-transform: capitalize;
    font-family: var(--font-spoqa-han-sans-neo);
  }
  .b1 {
    top: 1162px;
  }
  .rectangleDiv,
  .token360Inner {
    position: absolute;
    top: 999px;
    left: calc(50% - 180px);
    background-color: #19d64a;
    width: 13px;
    height: 13px;
    margin-top: 5px;
    margin-left: 26px;
  }
  .rectangleDiv {
    top: 1124px;
    background-color: #05a1ff;
  }
  .listingExchange,
  .tokenAllocation {
    position: absolute;
    top: 610px;
    margin-left: 26px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .listingExchange {
    top: 1646px;
  }
  .image54Icon,
  .image55Icon,
  .image56Icon {
    position: absolute;
    top: 1886px;
    left: calc(50% - 71px);
    width: 142px;
    height: 41px;
    object-fit: cover;
  }
  .image54Icon,
  .image56Icon {
    top: 1734px;
    left: calc(50% - 52px);
    width: 105px;
    height: 31px;
  }
  .image56Icon {
    top: 1813px;

    left: calc(50% - 73px);
    width: 147px;
    height: 32px;
  }
  .followingBip3244 {
    color: #b4b4b4;
  }
  .deriveAccountAddressContainer {
    position: absolute;
    top: 634px;
    left: 1105px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 240px;
  }
  .token360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 2200px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-9xl);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -400px;
  }
}
