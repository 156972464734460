@media screen and (min-width: 1020px) {
  .aboutUsVideo {
    position: absolute;
    top: 0;
  }
  .about1000Child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 var(--br-xl) var(--br-xl);
    background-color: var(--color-black);
    width: 100%;
    height: 750px;
  }
  .weAreNot {
    margin: 0;
  }
  .weAreNot300 {
    margin: 0;
    letter-spacing: -0.36px;
    font-family: var(--font-inter);
  }
  .or {
    color: var(--color-white);
  }
  .platformOrService,
  .service {
    color: var(--color-black);
  }
  .platformOrService {
    margin: 0;
  }
  .weAreNotContainer {
    position: absolute;
    top: 141px;
    left: calc(50% - 363px);
    font-size: var(--font-size-36xl);
    letter-spacing: -0.02em;
    line-height: 65px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    display: flex;
    align-items: center;
    width: 727px;
    text-shadow:
      1px 0 0#fff,
      0 1px 0#fff,
      -1px 0 0#fff,
      0-1px 0#fff;
    color: var(--color-white);
  }
  .weAreA {
    font-family: var(--font-geomanist);
  }
  .weAreAHippo {
    font-family: var(--font-geomanist);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .ch {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .weAreAHippoCh {
    letter-spacing: -0.02em;
  }
  .boxTitle {
    letter-spacing: -0.02em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .weAreAContainer {
    position: absolute;
    top: 342px;
    left: calc(50% - 415px);
    font-size: var(--font-size-36xl);
    line-height: 65px;
    background: radial-gradient(50% 50%at 50% 50%, #80ff45 3.89%, #00dab2);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 832px;
  }
  .icon {
    position: absolute;
    top: 0;
    left: 916.89px;
    width: 37.99px;
    height: 37.99px;
    overflow: hidden;
    object-fit: cover;
  }
  .b {
    letter-spacing: -0.06em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .a {
    letter-spacing: -0.02em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .hip {
    font-family: var(--font-geomanist);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .o1 {
    letter-spacing: -0.07em;
  }
  .p1 {
    letter-spacing: -0.1em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .aboutHippoTxtContainer {
    width: 100%;
  }
  .aboutHippo {
    position: absolute;
    top: 8.22px;
    left: 0;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 180px;
    height: 19.51px;
  }
  .groupChild {
    position: absolute;
    top: 17.98px;
    left: 189.45px;
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 707.41px;
    height: 1px;
  }
  .icon1 {
    position: absolute;
    top: 0;
    left: 960.01px;
    width: 37.99px;
    height: 37.99px;
    overflow: hidden;
    object-fit: cover;
  }
  .parent,
  .partnership {
    position: absolute;
    text-align: left;
  }
  .parent {
    top: 23px;
    left: 0;
    width: 998px;
    height: 37.99px;
    font-size: 27px;
    color: var(--color-white);
    font-family: var(--font-bodoni-72);
  }
  .partnership {
    top: 3056px;
    left: calc(50% - 250px);
    width: 500px;
    text-align: center;
    font-size: var(--font-size-31xl);
    letter-spacing: -0.02em;
    line-height: 58px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .about1000Inner,
  .about1000Item,
  .rectangleDiv {
    position: absolute;
    border-radius: var(--br-11xl);
    height: 304px;
  }
  .about1000Item {
    top: 1921px;
    left: 0;
    background-color: #76f15c;
    width: 1000px;
  }
  .about1000Inner,
  .rectangleDiv {
    top: 2245px;
    width: 490px;
  }
  .about1000Inner {
    left: 0;
    background-color: var(--color-black);
  }
  .rectangleDiv {
    left: 510px;
    background-color: #199fff;
  }
  .patientCentricEquityTransContainer,
  .toCreateA,
  .toEmpowerPatientsContainer {
    position: absolute;
    top: 2383px;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 35px;
    display: inline-block;
    width: 393px;
    height: 97px;
  }
  .toCreateA {
    left: calc(50% - 451px);
    color: var(--color-white);
    font-weight: 300;
  }
  .patientCentricEquityTransContainer,
  .toEmpowerPatientsContainer {
    left: calc(50% + 59px);
  }
  .toEmpowerPatientsContainer {
    top: 2060px;
    left: calc(50% - 420px);
    width: 840px;
    height: 96px;
  }
  .ion {
    letter-spacing: -0.04em;
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .mission {
    position: absolute;
    top: 1990px;
    left: 432px;
    text-align: left;
  }
  .coreValues,
  .vision {
    position: absolute;
    top: 2313px;
  }
  .vision {
    left: 190px;
    color: var(--color-white);
  }
  .coreValues {
    left: 655px;
    line-height: 58px;
  }
  .wePrioritizePatients {
    position: absolute;
    top: 2709px;
    left: 85px;
    letter-spacing: -0.02em;
    line-height: 47px;
    display: inline-block;
    width: 830px;
    height: 131px;
  }
  .doYouKnow,
  .hippocratsIdentityIsContainer,
  .theSpecialHippoContainer {
    position: absolute;
    top: 986px;
    left: 510px;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 30px;
    text-align: left;
    display: inline-block;
    width: 490px;
    height: 224px;
  }
  .doYouKnow,
  .theSpecialHippoContainer {
    top: 1442px;
    left: 0;
  }
  .doYouKnow {
    top: 821px;
    font-size: var(--font-size-31xl);
    line-height: 65px;
    width: 747px;
    height: 118px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .image55Icon {
    position: absolute;
    top: 3170px;
    left: 361px;
    width: 252px;
    height: 56px;
    object-fit: cover;
  }
  .image56Icon {
    position: absolute;
    top: 3258px;
    left: 529px;
    width: 202px;
    height: 58px;
    object-fit: cover;
  }
  .image57Icon,
  .image58Icon,
  .image59Icon {
    position: absolute;
    top: 3245px;
    left: 308px;
    width: 186px;
    height: 81px;
    object-fit: cover;
  }
  .image58Icon,
  .image59Icon {
    top: 3178px;
    left: 200px;
    width: 144px;
    height: 42px;
  }
  .image59Icon {
    top: 3169px;
    left: 624px;
    width: 194px;
    height: 56px;
  }

  .image100Icon {
    position: absolute;
    top: 3178px;
    left: 0;
    width: 133px;
    height: 39px;
    object-fit: cover;
  }
  .image101Icon {
    position: absolute;
    top: 3178px;
    left: 195px;
    width: 184px;
    height: 39px;
    object-fit: cover;
  }
  .image102Icon {
    position: absolute;
    top: 3180px;
    left: 426px;
    width: 148px;
    height: 36px;
    object-fit: cover;
  }
  .image103Icon {
    position: absolute;
    top: 3161px;
    left: 618px;
    width: 160px;
    height: 73px;
    object-fit: cover;
  }
  .image104Icon {
    position: absolute;
    top: 3177px;
    left: 807px;
    width: 188px;
    height: 42px;
    object-fit: cover;
  }
  .image105Icon {
    position: absolute;
    top: 3274px;
    left: 122px;
    width: 234px;
    height: 26px;
    object-fit: cover;
  }
  .image106Icon {
    position: absolute;
    top: 3268px;
    left: 406px;
    width: 187px;
    height: 40px;
    object-fit: cover;
  }
  .image107Icon {
    position: absolute;
    top: 3275px;
    left: 637px;
    width: 227px;
    height: 29px;
    object-fit: cover;
  }
  .partnerImageWrapper {
    position: absolute;
    top: 3150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    justify-content: center;
  }
  .partnerImageBox {
    display: flex;
    gap: 66px;
    img {
      cursor: pointer !important;
      width: 200px;
      height: 80px;
    }
  }
  .image68Icon {
    height: 56px;
    object-fit: cover;
  }
  .image69Icon {
    height: 56px;
    object-fit: cover;
  }
  .image70Icon {
    height: 56px;
    object-fit: cover;
  }
  .image71Icon {
    height: 56px;
    object-fit: cover;
  }
  .partnerImageWrapper2 {
    position: absolute;
    top: 3320px;
    width: 100%;
    display: flex;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .about1000Child1 {
    position: absolute;
    top: 900px;
    left: calc(50% - 500px);
    width: 476px;
  }
  .about1000Child2 {
    position: absolute;
    top: 1313px;
    left: calc(50% - 400px);
    border-radius: var(--br-lg);
    width: 1300px;
  }
  .about1000 {
    position: relative;
    width: 100%;
    height: 3100px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-15xl);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
  .margin50 {
    position: relative;
    height: 100px;
    width: 100%;
    margin-top: 58px;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .about800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-xl) var(--br-xl);
    background-color: var(--color-black);
    width: 100%;
    height: 600px;
  }
  .icon {
    position: absolute;
    top: 40.38px;
    left: 729.15px;
    width: 30.38px;
    height: 30.38px;
    overflow: hidden;
  }
  .about800Item {
    position: absolute;
    top: 55.51px;
    left: 186.5px;
    border-top: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 531px;
    height: 1px;
  }
  .icon1 {
    position: absolute;
    top: 40.38px;
    left: 767.62px;
    width: 30.38px;
    height: 30.38px;
    overflow: hidden;
  }
  .weAreNot {
    margin: 0;
  }
  .or {
    color: var(--color-white);
  }
  .platformOrService,
  .service {
    color: var(--color-black);
  }
  .platformOrService {
    margin: 0;
  }
  .weAreNotContainer {
    position: absolute;
    top: 151px;
    left: calc(50% - 293px);
    letter-spacing: -0.02em;
    line-height: 55px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    display: flex;
    align-items: center;
    width: 606px;
    text-shadow:
      1px 0 0#fff,
      0 1px 0#fff,
      -1px 0 0#fff,
      0-1px 0#fff;
    color: var(--color-white);
  }
  .weAreA {
    font-family: var(--font-geomanist);
  }
  .ch {
    font-family: var(--font-bodoni-72);
  }
  .weAreAHippoCh {
    letter-spacing: -0.02em;
  }
  .weAreAContainer {
    position: absolute;
    top: 275px;
    left: calc(50% - 337px);
    line-height: 55px;
    background: radial-gradient(50% 50%at 50% 50%, #80ff45 3.89%, #00dab2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    width: 695px;
  }
  .partnershipTxt {
    line-break: anywhere;
    width: 100%;
  }
  .partnership {
    position: absolute;
    top: 2815px;
    left: calc(50% - 250px);
    text-align: center;
    letter-spacing: -0.02em;
    line-height: 58px;
    text-align: center;
    display: flex;
    align-items: center;
    width: 500px;
    height: 34px;
  }
  .about800Child1,
  .about800Inner,
  .rectangleDiv {
    position: absolute;
    border-radius: var(--br-11xl);
    height: 304px;
  }
  .about800Inner {
    top: 1671px;
    left: 0;
    background-color: #76f15c;
    width: 800px;
  }
  .about800Child1,
  .rectangleDiv {
    top: 1995px;
    width: 392px;
  }
  .rectangleDiv {
    left: 0;
    background-color: var(--color-black);
  }
  .about800Child1 {
    left: 408px;
    background-color: #199fff;
  }
  .patientCentricEquityTransContainer,
  .toForgeA,
  .toRestoreIndividuals {
    position: absolute;
    top: 2126px;
    font-size: 18px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
  }
  .toForgeA {
    left: calc(50% - 329px);
    color: var(--color-white);
    width: 251px;
  }
  .patientCentricEquityTransContainer,
  .toRestoreIndividuals {
    left: calc(50% + 77px);
    width: 255px;
  }
  .toRestoreIndividuals {
    top: 1797px;
    left: calc(50% - 332px);
    width: 664px;
    height: 96px;
    font-size: 18px;
  }
  .ion {
    letter-spacing: -0.04em;
    font-family: var(--font-bodoni-72-oldstyle);
  }
  .mission {
    position: absolute;
    top: 1742px;
    left: 337px;
    font-size: var(--font-size-13xl);
    text-align: left;
  }
  .coreValues,
  .vision,
  .wePrioritizePatients {
    position: absolute;
    top: 2071px;
    font-size: var(--font-size-13xl);
  }
  .vision {
    left: 144px;
    color: var(--color-white);
  }
  .coreValues,
  .wePrioritizePatients {
    left: 510px;
    line-height: 58px;
  }
  .wePrioritizePatients {
    top: 2464px;
    left: 68px;
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 664px;
  }
  .didYouKnowContainer,
  .hippocratsIdentityDraws,
  .theSpecialHippoContainer {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: left;
    display: inline-block;
  }
  .hippocratsIdentityDraws {
    top: 871px;
    left: 410px;
    font-size: 14px;
    line-height: 25px;
    width: 390px;
  }
  .didYouKnowContainer,
  .theSpecialHippoContainer {
    left: 0;
  }
  .theSpecialHippoContainer {
    top: 1295px;
    font-size: 14px;
    line-height: 25px;
    width: 390px;
  }
  .didYouKnowContainer {
    top: 706px;
    line-height: 59px;
    white-space: pre-wrap;
    width: 632px;
    height: 118px;
  }
  .b {
    letter-spacing: -0.06em;
  }
  .o1 {
    letter-spacing: -0.07em;
  }
  .p1 {
    letter-spacing: -0.1em;
  }
  .aboutHippoTxtContainer {
    width: 100%;
  }
  .aboutHippo {
    position: absolute;
    top: 46.01px;
    left: 0;
    font-size: 27px;
    color: var(--color-white);
    white-space: pre-wrap;
    text-align: left;
    display: flex;
    align-items: center;
    width: 180px;
    height: 19.51px;
    font-family: var(--font-bodoni-72);
  }
  .image55Icon {
    position: absolute;
    top: 2920px;
    left: 303px;
    width: 174px;
    height: 38px;
    object-fit: cover;
  }
  .image67Icon {
    position: absolute;
    top: 2920px;
    left: 500px;
    width: 176px;
    height: 38px;
    object-fit: cover;
  }
  .image66Icon {
    position: absolute;
    top: 3000px;
    left: 390px;
    width: 176px;
    height: 38px;
    object-fit: cover;
  }
  .image65Icon {
    position: absolute;
    top: 2985px;
    left: 210px;
    width: 150px;
    height: 67px;
    object-fit: cover;
  }
  .image64Icon {
    position: absolute;
    top: 2920px;
    left: 140px;
    width: 126px;
    height: 38px;
    object-fit: cover;
  }
  .partnerImageWrapper {
    position: absolute;
    top: 2900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .partnerImageBox {
    display: flex;
    gap: 40px;
    img {
      cursor: pointer !important;
      width: 170px;
      height: 68px;
    }
  }
  .image68Icon {
    height: 36px;
    object-fit: cover;
  }
  .image69Icon {
    height: 36px;
    object-fit: cover;
  }
  .image70Icon {
    height: 36px;
    object-fit: cover;
  }
  .image71Icon {
    height: 36px;
    object-fit: cover;
  }
  .partnerImageWrapper2 {
    position: absolute;
    top: 3040px;
    width: 100%;
    display: flex;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .about800 {
    position: relative;
    width: 100%;
    height: 2850px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-24xl);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .hippoVideo {
    position: absolute;
    top: 800px;
    left: 0;
    width: 360px;
  }
  .cratVideo {
    position: absolute;
    top: 1150px;
    left: calc(50% - 300px);
    border-radius: var(--br-lg);
    width: 900px;
  }

  .image100Icon {
    position: absolute;
    top: 2920px;
    left: 0;
    width: 125px;
    object-fit: cover;
  }
  .image101Icon {
    position: absolute;
    top: 2920px;
    left: 155px;
    width: 164px;
    object-fit: cover;
  }
  .image102Icon {
    position: absolute;
    top: 2924px;
    left: 346px;
    width: 128px;
    object-fit: cover;
  }
  .image103Icon {
    position: absolute;
    top: 2904px;
    left: 482px;
    width: 140px;
    object-fit: cover;
  }
  .image104Icon {
    position: absolute;
    top: 2920px;
    left: 630px;
    width: 168px;
    object-fit: cover;
  }
  .image105Icon {
    position: absolute;
    top: 3000px;
    left: 60px;
    width: 210px;
    object-fit: cover;
  }
  .image106Icon {
    position: absolute;
    top: 2993px;
    left: 306px;
    width: 167px;
    object-fit: cover;
  }
  .image107Icon {
    position: absolute;
    top: 2997px;
    left: 505px;
    width: 207px;
    object-fit: cover;
  }
}
