@media screen and (min-width: 1020px) {
  .newsChild {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 340px;
  }
  .ws {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .news1 {
    top: 100px;
    font-size: var(--font-size-49xl);
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .announcementPress,
  .exploreTheWorld,
  .news1 {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .announcementPress {
    top: 167px;
    font-size: 22px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen);
  }
  .exploreTheWorld {
    top: 214px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: var(--color-lightgray);
    display: inline-block;
    width: 496px;
    font-family: var(--font-inter);
  }
  .newsImg4,
  .newsImg2,
  .newsImg5,
  .newsImg3,
  .newsImg0,
  .newsImg1 {
    position: absolute;
    top: 406px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 320px;
    height: 200px;
  }
  .newsImg4,
  .newsImg2,
  .newsImg5,
  .newsImg3,
  .newsImg1 {
    top: 795px;
  }
  .newsImg4,
  .newsImg2,
  .newsImg5,
  .newsImg1 {
    top: 406px;
    left: 340px;
  }
  .newsImg4,
  .newsImg2,
  .newsImg5 {
    top: 795px;
  }
  .newsImg2,
  .newsImg5 {
    top: 406px;
    left: 680px;
  }
  .newsImg5 {
    top: 795px;
  }
  .nowToSpot {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .newsTitle0,
  .newsTitle4,
  .newsTitle2,
  .newsTitle3,
  .newsTitle1,
  .newsTitle5 {
    position: absolute;
    top: 662px;
    left: 20px;
    letter-spacing: -0.02em;
    line-height: 27px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 279px;
    height: 48px;
    font-size: 18px;
  }
  .newsTitle4,
  .newsTitle2,
  .newsTitle3,
  .newsTitle1,
  .newsTitle5 {
    top: 1051px;
  }
  .newsTitle2,
  .newsTitle4,
  .newsTitle1,
  .newsTitle5 {
    top: 662px;
    left: 360px;
  }
  .newsTitle4,
  .newsTitle2,
  .newsTitle5 {
    top: 1051px;
  }
  .newsTitle2,
  .newsTitle5 {
    top: 662px;
    left: 700px;
  }
  .newsTitle5 {
    top: 1051px;
  }
  .newsDate0,
  .newsDate4,
  .newsDate2,
  .newsDate3,
  .newsDate1,
  .newsDate5 {
    position: absolute;
    top: 631px;
    left: 20px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 279px;
    height: 14px;
  }
  .newsDate4,
  .newsDate2,
  .newsDate3,
  .newsDate1,
  .newsDate5 {
    top: 1020px;
  }
  .newsDate2,
  .newsDate4,
  .newsDate1,
  .newsDate5 {
    top: 631px;
    left: 360px;
  }
  .newsDate4,
  .newsDate2,
  .newsDate5 {
    top: 1020px;
  }
  .newsDate2,
  .newsDate5 {
    top: 631px;
    left: 700px;
  }
  .newsDate5 {
    top: 1020px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .chevronLeftIcon1 {
    left: 391px;
    object-fit: contain;
  }
  .parent {
    position: absolute;
    top: 1227px;
    left: 292px;
    width: 415px;
    height: 26px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }
  .parentLow {
    top: 837px !important;
  }
  .image14Icon,
  .image18Icon,
  .image19Icon,
  .image20Icon,
  .image21Icon {
    position: absolute;
    top: 616px;
    left: 399px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .image18Icon,
  .image19Icon,
  .image20Icon,
  .image21Icon {
    top: 1005px;
  }
  .image18Icon,
  .image19Icon,
  .image21Icon {
    top: 616px;
    left: 739px;
  }
  .image19Icon,
  .image21Icon {
    top: 1005px;
  }
  .image19Icon {
    top: 616px;
    left: 1079px;
  }
  .news {
    position: relative;
    width: 100%;
    height: 1440px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    a {
      color: var(--color-black);
    }

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .news800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 340px;
  }
  .announcementPress,
  .exploreTheWorld {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .announcementPress {
    top: 164px;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .exploreTheWorld {
    top: 211px;
    font-size: var(--font-size-base);
    line-height: 25px;
    color: #d5d5d5;
    display: inline-block;
    width: 496px;
  }
  .news800Inner,
  .news800Item,
  .rectangleDiv {
    position: absolute;
    top: 406px;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 253px;
    height: 200px;
  }
  .news800Item {
    left: 0;
  }
  .news800Inner,
  .rectangleDiv {
    left: calc(50% - 127px);
  }
  .rectangleDiv {
    top: 795px;
  }
  .news800Child1 {
    top: 795px;
    left: calc(50% + 147px);
  }
  .news800Child1,
  .news800Child2,
  .news800Child3 {
    position: absolute;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 253px;
    height: 200px;
  }
  .news800Child2 {
    top: 795px;
    left: calc(50% - 400px);
  }
  .news800Child3 {
    top: 406px;
    left: 547px;
  }
  .nowToSpot {
    margin: 0;
  }
  .nowToSpotContainer,
  .nowToSpotContainer1,
  .nowToSpotContainer2,
  .nowToSpotContainer3,
  .nowToSpotContainer4,
  .nowToSpotContainer5 {
    position: absolute;
    top: 662px;
    left: 15px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 223px;
    height: 48px;
  }
  .nowToSpotContainer1,
  .nowToSpotContainer2,
  .nowToSpotContainer3,
  .nowToSpotContainer4,
  .nowToSpotContainer5 {
    left: 288px;
  }
  .nowToSpotContainer2,
  .nowToSpotContainer3,
  .nowToSpotContainer4,
  .nowToSpotContainer5 {
    top: 1051px;
  }
  .nowToSpotContainer3,
  .nowToSpotContainer4,
  .nowToSpotContainer5 {
    left: 562px;
  }
  .nowToSpotContainer4,
  .nowToSpotContainer5 {
    left: 15px;
  }
  .nowToSpotContainer5 {
    top: 662px;
    left: 562px;
  }
  .nov172023,
  .nov1720231,
  .nov1720232,
  .nov1720233,
  .nov1720234,
  .nov1720235 {
    position: absolute;
    top: 631px;
    left: 15px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .nov1720231,
  .nov1720232,
  .nov1720233,
  .nov1720234,
  .nov1720235 {
    left: 288px;
  }
  .nov1720232,
  .nov1720233,
  .nov1720234,
  .nov1720235 {
    top: 1020px;
  }
  .nov1720233,
  .nov1720234,
  .nov1720235 {
    left: 562px;
  }
  .nov1720234,
  .nov1720235 {
    left: 15px;
  }
  .nov1720235 {
    top: 631px;
    left: 562px;
  }
  .b,
  .div,
  .div1,
  .div2,
  .div3,
  .div4 {
    position: absolute;
    top: 1px;
    letter-spacing: -0.02em;
  }
  .b {
    left: 101px;
    color: var(--color-black);
  }
  .div,
  .div1,
  .div2,
  .div3,
  .div4 {
    left: 142px;
    font-weight: 500;
  }
  .div1,
  .div2,
  .div3,
  .div4 {
    left: 258px;
  }
  .div2,
  .div3,
  .div4 {
    left: 297px;
  }
  .div3,
  .div4 {
    left: 183px;
  }
  .div4 {
    left: 220px;
    font-size: var(--font-size-base);
    letter-spacing: -0.06em;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
  .chevronLeftIcon1 {
    left: 391px;
    object-fit: contain;
  }
  .parent {
    position: absolute;
    top: 1227px;
    left: 193px;
    width: 415px;
    height: 26px;
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
  }
  .image14Icon,
  .image18Icon,
  .image25Icon,
  .image27Icon,
  .image31Icon {
    position: absolute;
    top: 616px;
    left: 399px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .image18Icon,
  .image25Icon,
  .image27Icon,
  .image31Icon {
    top: 1005px;
  }
  .image18Icon,
  .image27Icon,
  .image31Icon {
    left: 673px;
  }
  .image18Icon,
  .image27Icon {
    left: 126px;
  }
  .image18Icon {
    top: 616px;
    left: 739px;
  }
  .ws {
    font-family: var(--font-bodoni-72);
  }
  .news {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 60px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    line-height: 42px;
  }
  .news800 {
    position: relative;
    width: 100%;
    height: 1377px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
    }

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }

  .newsImg4,
  .newsImg2,
  .newsImg5,
  .newsImg3,
  .newsImg0,
  .newsImg1 {
    position: absolute;
    top: 406px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 253px;
    height: 200px;
  }
  .newsImg4,
  .newsImg2,
  .newsImg5,
  .newsImg3,
  .newsImg1 {
    top: 795px;
  }
  .newsImg4,
  .newsImg2,
  .newsImg5,
  .newsImg1 {
    top: 406px;
    left: 273px;
  }
  .newsImg4,
  .newsImg2,
  .newsImg5 {
    top: 795px;
  }
  .newsImg2,
  .newsImg5 {
    top: 406px;
    left: 547px;
  }
  .newsImg5 {
    top: 795px;
  }
  .nowToSpot {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .newsTitle0,
  .newsTitle4,
  .newsTitle2,
  .newsTitle3,
  .newsTitle1,
  .newsTitle5 {
    position: absolute;
    top: 662px;
    left: 15px;
    letter-spacing: -0.02em;
    line-height: 27px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 223px;
    height: 48px;
    font-size: 16px;
  }
  .newsTitle4,
  .newsTitle2,
  .newsTitle3,
  .newsTitle1,
  .newsTitle5 {
    top: 1051px;
  }
  .newsTitle2,
  .newsTitle4,
  .newsTitle1,
  .newsTitle5 {
    top: 662px;
    left: 288px;
  }
  .newsTitle4,
  .newsTitle2,
  .newsTitle5 {
    top: 1051px;
  }
  .newsTitle2,
  .newsTitle5 {
    top: 662px;
    left: 547px;
  }
  .newsTitle5 {
    top: 1051px;
  }
  .newsDate0,
  .newsDate4,
  .newsDate2,
  .newsDate3,
  .newsDate1,
  .newsDate5 {
    position: absolute;
    top: 631px;
    left: 15px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 223px;
    height: 14px;
  }
  .newsDate4,
  .newsDate2,
  .newsDate3,
  .newsDate1,
  .newsDate5 {
    top: 1020px;
  }
  .newsDate2,
  .newsDate4,
  .newsDate1,
  .newsDate5 {
    top: 631px;
    left: 288px;
  }
  .newsDate4,
  .newsDate2,
  .newsDate5 {
    top: 1020px;
  }
  .newsDate2,
  .newsDate5 {
    top: 631px;
    left: 547px;
  }
  .newsDate5 {
    top: 1020px;
  }
  .chevronLeftIcon,
  .chevronLeftIcon1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .chevronLeftIcon1 {
    left: 391px;
    object-fit: contain;
  }
  .parent {
    position: absolute;
    top: 1227px;
    left: 192px;
    width: 415px;
    height: 26px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }
  .parentLow {
    top: 837px !important;
  }
  .image14Icon,
  .image18Icon,
  .image19Icon,
  .image20Icon,
  .image21Icon {
    position: absolute;
    top: 616px;
    left: 399px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .image18Icon,
  .image19Icon,
  .image20Icon,
  .image21Icon {
    top: 1005px;
  }
  .image18Icon,
  .image19Icon,
  .image21Icon {
    top: 616px;
    left: 739px;
  }
  .image19Icon,
  .image21Icon {
    top: 1005px;
  }
  .image19Icon {
    top: 616px;
    left: 1079px;
  }
}
@media screen and (max-width: 819px) and (min-width: 500px) {
  .news500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 300px;
  }
  .announcementPress,
  .exploreTheWorld {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .announcementPress {
    top: 134px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .exploreTheWorld {
    top: 181px;
    font-size: 16px;
    line-height: 25px;
    color: #d5d5d5;
    display: inline-block;
    width: 496px;
  }
  .news500Inner,
  .news500Item {
    position: absolute;
    top: 345px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 500px;
    height: 200px;
  }
  .news500Inner {
    top: 689px;
  }
  .nowToSpot {
    margin: 0;
  }
  .nowToSpotContainer {
    top: 601px;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 455px;
  }
  .nov172023,
  .nov1720231,
  .nowToSpotContainer,
  .nowToSpotContainer1 {
    position: absolute;
    left: 22px;
    letter-spacing: -0.02em;
  }
  .nowToSpotContainer1 {
    top: 945px;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 455px;
  }
  .nov172023,
  .nov1720231 {
    top: 570px;
    font-size: var(--font-size-sm);
    font-weight: 500;
  }
  .nov1720231 {
    top: 914px;
  }
  .image14Icon,
  .image18Icon,
  .image25Icon {
    position: absolute;
    top: 555px;
    left: 249px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .image18Icon,
  .image25Icon {
    top: 899px;
  }
  .image18Icon {
    top: 553px;
    left: 464px;
  }
  .ws {
    font-family: var(--font-bodoni-72);
  }
  .news {
    position: absolute;
    top: 75px;
    left: 0;
    font-size: 45px;
    letter-spacing: -0.02em;
    color: var(--color-white);
  }
  .news500 {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }

  .newsWrapper {
    position: relative;
    top: 300px;
    margin-bottom: 350px;
    a {
      text-decoration: none;
    }
  }
  .newsImg {
    width: 100%;
    height: 200px;
    border-radius: 30px;
    background-color: #d9d9d9;
    margin-top: 50px;
  }
  .newsDate {
    margin-top: 25px;
    margin-left: 22px;
    color: #000;
    font-family: Geomanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-decoration: none;
  }
  .newsTitle {
    margin-top: 17px;
    margin-left: 22px;
    margin-right: 22px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.36px;
  }
  .parent {
    width: 500px;
    height: 26px;
    margin-bottom: 145px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }
}
@media screen and (max-width: 500px) {
  .news360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 300px;
  }
  .announcementPress,
  .exploreTheWorld {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .announcementPress {
    top: 134px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .exploreTheWorld {
    top: 181px;
    font-size: 16px;
    line-height: 25px;
    color: #d5d5d5;
    display: inline-block;
    width: 100%;
  }
  .news360Inner,
  .news360Item {
    position: absolute;
    top: 345px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-gainsboro);
    width: 361px;
    height: 200px;
  }
  .news360Inner {
    top: 689px;
  }
  .nowToSpot {
    margin: 0;
  }
  .nowToSpotContainer {
    top: 601px;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 339px;
  }
  .nov172023,
  .nov1720231,
  .nowToSpotContainer,
  .nowToSpotContainer1 {
    position: absolute;
    left: 22px;
    letter-spacing: -0.02em;
  }
  .nowToSpotContainer1 {
    top: 945px;
    line-height: 25px;
    display: inline-block;
    font-family: var(--font-inter);
    width: 339px;
  }
  .nov172023,
  .nov1720231 {
    top: 570px;
    font-size: var(--font-size-sm);
    font-weight: 500;
  }
  .nov1720231 {
    top: 914px;
  }
  .image14Icon,
  .image18Icon,
  .image25Icon {
    position: absolute;
    top: 555px;
    left: 179px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .image18Icon,
  .image25Icon {
    top: 899px;
  }
  .image18Icon {
    top: 553px;
    left: 336px;
  }
  .ws {
    font-family: var(--font-bodoni-72);
  }
  .news {
    position: absolute;
    top: 79px;
    left: 0;
    font-size: 35px;
    letter-spacing: -0.02em;
    color: var(--color-white);
  }
  .news360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }

  .newsWrapper {
    position: relative;
    top: 300px;
    margin-bottom: 350px;
    a {
      text-decoration: none;
    }
  }
  .newsImg {
    width: 100%;
    height: 200px;
    border-radius: 30px;
    background-color: #d9d9d9;
    margin-top: 50px;
  }
  .newsDate {
    margin-top: 25px;
    margin-left: 22px;
    color: #000;
    font-family: Geomanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-decoration: none;
  }
  .newsTitle {
    margin-top: 17px;
    margin-left: 22px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.36px;
  }
  .parent {
    width: 100%;
    height: 26px;
    margin-bottom: 145px;
    font-size: var(--font-size-lg);
    color: var(--color-gray);
    font-family: var(--font-noto-sans);
    text-align: center;

    button {
      color: #8f8f8f;
      font-family: var(--font-noto-sans);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: 0 15px;
      border: none;
      background: none;
      cursor: pointer;
    }
    .active {
      color: #000;
      font-weight: 700;
    }
  }
}
