@media screen and (min-width: 1020px) {
  .pcDiv {
    display: block;
  }
  .mobileDiv {
    display: none;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    background-color: var(--color-header-bg);
    color: var(--color-white);
    z-index: 100;

    button {
      cursor: pointer;
    }
  }
  .gnb1000Child {
    background-color: var(--color-header-bg);
    width: 1000px;
    height: 94px;
  }
  .hippocratNewLogoWhite2Icon {
    position: absolute;
    top: 20px;
    left: 0;
    width: 192px;
    height: 54px;
    object-fit: cover;
  }
  .gnb1000Item {
    position: absolute;
    top: 24px;
    left: 954px;
    border-radius: 50%;
    background-color: #00d332;
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }
  .menu01Icon {
    position: absolute;
    top: 35px;
    left: 965px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000 {
    position: relative;
    background-color: var(--color-header-bg);
    width: 1000px;
    margin: 0 auto;
    height: 94px;
    overflow: hidden;
  }
  .xCloseIcon {
    position: absolute;
    top: 36px;
    left: 965px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000XItem {
    position: absolute;
    top: 25px;
    left: 954px;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }

  // menu style
  .lnb1000Child {
    position: absolute;
    top: 0;
    background-color: #282828;
    width: 1000px;
    height: 562px;
    left: calc(50% - 500.25px);
  }

  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .menuTitle,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .menuTitle {
    top: 101px;
    left: 0;
    font-size: 34px;
    color: var(--color-limegreen);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.68px;
  }
  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    top: 148px;
    left: 425px;
    font-size: var(--font-size-11xl);
    font-style: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.6px;
  }
  .leftMenu2 {
    top: 236px;
  }
  .leftMenu3 {
    top: 324px;
  }
  .leftMenu4 {
    top: 412px;
  }
  .rightMenu1 {
    top: 148px;
    left: 680px;
  }
  .rightMenu2 {
    top: 236px;
    left: 680px;
  }
  .rightMenu3 {
    top: 324px;
    left: 680px;
  }
  .rightMenu4 {
    top: 412px;
    left: 680px;
  }

  .leftMenuTitle {
    top: 111px;
    left: 425px;
    font-size: var(--font-size-mini);
    color: #8b9e8b;
    font-weight: 500;
  }
  .rightMenuTitle,
  .menuTitleDec,
  .leftMenuTitle {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .rightMenuTitle {
    top: 111px;
    left: 680px;
    font-size: var(--font-size-mini);
    color: #8b9e8b;
    font-weight: 500;
  }
  .menuTitleDec {
    top: 159px;
    left: 0;
    font-size: var(--font-size-base);
    white-space: pre-wrap;
    color: var(--color-white);
    font-style: normal;
    font-family: var(--font-inter);
    line-height: 20px;
    letter-spacing: -0.32px;
  }
  .leftMenuDec1,
  .leftMenuDec2,
  .leftMenuDec3,
  .leftMenuDec4,
  .rightMenuDec1,
  .rightMenuDec2,
  .rightMenuDec3,
  .rightMenuDec4 {
    position: absolute;
    top: 184px;
    left: 425px;
    font-size: var(--font-size-base);
    letter-spacing: -0.02em;
    color: var(--color-darkgray-100);
    font-weight: 300;
  }
  .leftMenuDec2 {
    top: 272px;
  }
  .leftMenuDec3 {
    top: 360px;
  }
  .leftMenuDec4 {
    top: 448px;
  }
  .rightMenuDec1 {
    top: 184px;
    left: 680px;
  }
  .rightMenuDec2 {
    top: 272px;
    left: 680px;
  }
  .rightMenuDec3 {
    top: 360px;
    left: 680px;
  }
  .rightMenuDec4 {
    top: 448px;
    left: 680px;
  }

  .lnb1000Item {
    position: absolute;
    top: 66.75px;
    left: calc(50% - 500.25px);
    border-top: 0.5px solid #6b6b6b;
    box-sizing: border-box;
    width: 1000.5px;
    height: 0.5px;
  }
  .menuSelect {
    border-radius: 14.5px;
    background-color: var(--color-limegreen);
    padding: 8px 20px;
    margin-top: -8px;
    margin-left: -20px;
    color: var(--color-black);
  }
  .solutions {
    position: absolute;
    top: 27px;
    left: 319px;
    letter-spacing: -0.02em;
    font-weight: 500;
    cursor: pointer;
  }
  .aboutUs a {
    text-decoration: none;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources {
    font-weight: 500;
    position: absolute;
    top: 27px;
    left: 424px;
    letter-spacing: -0.02em;
    cursor: pointer;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn {
    font-weight: 500;
    left: 536px;
  }
  .aboutUs,
  .community,
  .ecosystem {
    left: 619px;
  }
  .aboutUs,
  .ecosystem {
    left: 740px;
  }
  .ecosystem {
    left: 204px;
  }
  .lnb1000 {
    position: fixed;
    background-color: #282828;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    width: 100%;
    height: 562px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    z-index: 99;
    // 메뉴 열릴 때 애니메이션
    // transition: opacity 0.5s, visibility 0.5s;
    // opacity: 0;
    visibility: hidden;

    a {
      color: var(--color-white);
    }
  }
  .lnb1000.open {
    // 메뉴 닫힐 때 애니메이션
    // opacity: 1;
    visibility: visible;
  }
  .lnb1000Bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }

  .lnb1000Bg.open {
    display: block;
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .pcDiv {
    display: block;
  }
  .mobileDiv {
    display: none;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    background-color: var(--color-header-bg);
    color: var(--color-white);
    z-index: 100;

    button {
      cursor: pointer;
    }
  }
  .gnb1000Child {
    background-color: var(--color-header-bg);
    width: 800px;
    height: 94px;
  }
  .hippocratNewLogoWhite2Icon {
    position: absolute;
    top: 20px;
    left: 0;
    width: 192px;
    height: 54px;
    object-fit: cover;
  }
  .gnb1000Item {
    position: absolute;
    top: 24px;
    right: 0;
    border-radius: 50%;
    background-color: #00d332;
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }
  .menu01Icon {
    position: absolute;
    top: 35px;
    right: 11px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000 {
    position: relative;
    background-color: var(--color-header-bg);
    width: 800px;
    margin: 0 auto;
    height: 94px;
    overflow: hidden;
  }
  .xCloseIcon {
    position: absolute;
    top: 36px;
    right: 11px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000XItem {
    position: absolute;
    top: 25px;
    right: 0;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }

  // menu style
  .lnb1000Child {
    position: absolute;
    top: 0;
    background-color: #282828;
    width: 800px;
    height: 562px;
    left: calc(50% - 400.25px);
  }

  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .menuTitle,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .menuTitle {
    top: 101px;
    left: 0;
    font-size: 30px;
    color: var(--color-limegreen);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.68px;
  }
  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    top: 148px;
    left: 342px;
    font-size: var(--font-size-7xl);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.6px;
  }
  .leftMenu2 {
    top: 236px;
  }
  .leftMenu3 {
    top: 324px;
  }
  .leftMenu4 {
    top: 412px;
  }
  .rightMenu1 {
    top: 148px;
    left: 547px;
  }
  .rightMenu2 {
    top: 236px;
    left: 547px;
  }
  .rightMenu3 {
    top: 324px;
    left: 547px;
  }
  .rightMenu4 {
    top: 412px;
    left: 547px;
  }

  .leftMenuTitle {
    top: 111px;
    left: 342px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .rightMenuTitle,
  .menuTitleDec,
  .leftMenuTitle {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .rightMenuTitle {
    top: 111px;
    left: 547px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .menuTitleDec {
    top: 154px;
    left: 0;
    font-size: var(--font-size-sm);
    white-space: pre-wrap;
    color: var(--color-white);
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
  .leftMenuDec1,
  .leftMenuDec2,
  .leftMenuDec3,
  .leftMenuDec4,
  .rightMenuDec1,
  .rightMenuDec2,
  .rightMenuDec3,
  .rightMenuDec4 {
    position: absolute;
    top: 180px;
    left: 342px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    color: var(--color-darkgray-100);
    font-weight: 300;
  }
  .leftMenuDec2 {
    top: 268px;
  }
  .leftMenuDec3 {
    top: 356px;
  }
  .leftMenuDec4 {
    top: 444px;
  }
  .rightMenuDec1 {
    top: 180px;
    left: 547px;
  }
  .rightMenuDec2 {
    top: 268px;
    left: 547px;
  }
  .rightMenuDec3 {
    top: 356px;
    left: 547px;
  }
  .rightMenuDec4 {
    top: 444px;
    left: 547px;
  }
  .lnb1000Item {
    position: absolute;
    top: 66.75px;
    left: calc(50% - 400.25px);
    border-top: 0.5px solid #6b6b6b;
    box-sizing: border-box;
    width: 800px;
    height: 0.5px;
  }
  .menuSelect {
    border-radius: 14.5px;
    background-color: var(--color-limegreen);
    padding: 8px 20px;
    margin-top: -8px;
    margin-left: -20px;
    color: var(--color-black);
  }
  .solutions {
    position: absolute;
    top: 27px;
    left: 230px;
    letter-spacing: -0.02em;
    font-weight: 500;
    cursor: pointer;
  }
  .aboutUs a {
    text-decoration: none;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources {
    font-weight: 500;
    position: absolute;
    top: 27px;
    left: 335px;
    letter-spacing: -0.02em;
    cursor: pointer;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn {
    font-weight: 500;
    left: 447px;
  }
  .aboutUs,
  .community,
  .ecosystem {
    left: 530px;
  }
  .aboutUs,
  .ecosystem {
    left: 651px;
  }
  .ecosystem {
    left: 115px;
  }
  .lnb1000 {
    position: fixed;
    background-color: #282828;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    width: 100%;
    height: 562px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    z-index: 99;
    // 메뉴 열릴 때 애니메이션
    // transition: opacity 0.5s, visibility 0.5s;
    // opacity: 0;
    visibility: hidden;

    a {
      color: var(--color-white);
    }
  }
  .lnb1000.open {
    // 메뉴 닫힐 때 애니메이션
    // opacity: 1;
    visibility: visible;
  }
  .lnb1000Bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .lnb1000Bg.open {
    display: block;
  }
}
@media screen and (max-width: 819px) and (min-width: 500px) {
  .pcDiv {
    display: none;
  }
  .mobileDiv {
    display: block;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    background-color: var(--color-header-bg);
    color: var(--color-white);
    z-index: 100;

    button {
      cursor: pointer;
    }
  }
  .gnb1000Child {
    background-color: var(--color-header-bg);
    width: 100%;
    height: 94px;
  }
  .hippocratNewLogoWhite2Icon {
    position: absolute;
    top: 20px;
    left: 0;
    width: 192px;
    height: 54px;
    object-fit: cover;
  }
  .gnb1000Item {
    position: absolute;
    top: 24px;
    right: 0;
    border-radius: 50%;
    background-color: #00d332;
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }
  .menu01Icon {
    position: absolute;
    top: 35px;
    right: 11px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000 {
    position: relative;
    background-color: var(--color-header-bg);
    width: 100%;
    margin: 0 auto;
    height: 94px;
    overflow: hidden;
  }
  .xCloseIcon {
    position: absolute;
    top: 36px;
    right: 11px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000XItem {
    position: absolute;
    top: 25px;
    right: 0;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }

  // menu style

  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .menuTitle,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .menuTitle {
    top: 101px;
    left: 0;
    font-size: 30px;
    color: var(--color-limegreen);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.68px;
  }
  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    top: 148px;
    left: 342px;
    font-size: var(--font-size-7xl);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.6px;
  }
  .leftMenu2 {
    top: 236px;
  }
  .leftMenu3 {
    top: 324px;
  }
  .leftMenu4 {
    top: 412px;
  }
  .rightMenu1 {
    top: 148px;
    left: 547px;
  }
  .rightMenu2 {
    top: 236px;
    left: 547px;
  }
  .rightMenu3 {
    top: 324px;
    left: 547px;
  }
  .rightMenu4 {
    top: 412px;
    left: 547px;
  }

  .leftMenuTitle {
    top: 111px;
    left: 342px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .rightMenuTitle,
  .menuTitleDec,
  .leftMenuTitle {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .rightMenuTitle {
    top: 111px;
    left: 547px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .menuTitleDec {
    top: 154px;
    left: 0;
    font-size: var(--font-size-sm);
    white-space: pre-wrap;
    color: var(--color-white);
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
  .leftMenuDec1,
  .leftMenuDec2,
  .leftMenuDec3,
  .leftMenuDec4,
  .rightMenuDec1,
  .rightMenuDec2,
  .rightMenuDec3,
  .rightMenuDec4 {
    position: absolute;
    top: 180px;
    left: 342px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    color: var(--color-darkgray-100);
    font-weight: 300;
  }
  .leftMenuDec2 {
    top: 268px;
  }
  .leftMenuDec3 {
    top: 356px;
  }
  .leftMenuDec4 {
    top: 444px;
  }
  .rightMenuDec1 {
    top: 180px;
    left: 547px;
  }
  .rightMenuDec2 {
    top: 268px;
    left: 547px;
  }
  .rightMenuDec3 {
    top: 356px;
    left: 547px;
  }
  .rightMenuDec4 {
    top: 444px;
    left: 547px;
  }
  .lnb1000Item {
    position: absolute;
    top: 66.75px;
    left: calc(50% - 400.25px);
    border-top: 0.5px solid #6b6b6b;
    box-sizing: border-box;
    width: 100%;
    height: 0.5px;
  }
  .menuSelect {
    border-radius: 14.5px;
    background-color: var(--color-limegreen);
    padding: 8px 20px;
    margin-top: -8px;
    margin-left: -20px;
    color: var(--color-black);
  }
  .solutions {
    position: absolute;
    top: 27px;
    left: 230px;
    letter-spacing: -0.02em;
    font-weight: 500;
    cursor: pointer;
  }
  .aboutUs a {
    text-decoration: none;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources {
    font-weight: 500;
    position: absolute;
    top: 27px;
    left: 335px;
    letter-spacing: -0.02em;
    cursor: pointer;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn {
    font-weight: 500;
    left: 447px;
  }
  .aboutUs,
  .community,
  .ecosystem {
    left: 530px;
  }
  .aboutUs,
  .ecosystem {
    left: 651px;
  }
  .ecosystem {
    left: 115px;
  }
  .lnb1000 {
    position: fixed;
    background-color: #282828;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    width: 100%;
    height: 562px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    z-index: 99;
    // 메뉴 열릴 때 애니메이션
    // transition: opacity 0.5s, visibility 0.5s;
    // opacity: 0;
    visibility: hidden;

    a {
      color: var(--color-white);
    }
  }
  .lnb1000.open {
    // 메뉴 닫힐 때 애니메이션
    // opacity: 1;
    visibility: visible;
  }
  .lnb1000Bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .lnb1000Bg.open {
    display: block;
  }

  .mobileDepth1Child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #282828;
    width: 500px;
    height: 852px;
  }
  .circle,
  .contact,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram,
  .twitterX {
    position: absolute;
    top: 683px;
    left: 0;
    text-decoration: underline;
    letter-spacing: -0.02em;
    color: #8b9e8b;
    font-weight: 300;
  }
  .circle,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram,
  .twitterX {
    top: 751px;
  }
  .circle,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram {
    left: 130px;
  }
  .circle,
  .instagram,
  .linkedin,
  .medium,
  .telegram {
    top: 683px;
  }
  .circle,
  .instagram,
  .linkedin,
  .telegram {
    left: 260px;
  }
  .instagram,
  .linkedin,
  .telegram {
    top: 751px;
  }
  .instagram,
  .linkedin {
    top: 683px;
    left: 390px;
  }
  .instagram {
    top: 751px;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources,
  .solution {
    position: absolute;
    top: 154px;
    left: 0;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .aboutUs,
  .community,
  .learn,
  .resources,
  .solution {
    top: 224px;
  }
  .aboutUs,
  .community,
  .learn,
  .resources {
    top: 294px;
  }
  .aboutUs,
  .community,
  .learn {
    top: 364px;
  }
  .aboutUs,
  .community {
    top: 434px;
  }
  .aboutUs {
    top: 504px;
  }
  .chevronDownIcon {
    position: absolute;
    top: 157px;
    left: 188px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronDownIcon1,
  .chevronDownIcon2,
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    position: absolute;
    top: 224px;
    left: 168px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronDownIcon2,
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 297px;
    left: 186px;
  }
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 365px;
    left: 115px;
  }
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 434px;
    left: 167px;
  }
  .chevronDownIcon5 {
    top: 505px;
    left: 167px;
  }
  .mobileDepth1 {
    position: fixed;
    width: 100%;
    height: calc(100 * var(--vh));
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lgi);
    color: var(--color-darkgray);
    font-family: var(--font-geomanist);
    z-index: 9999;
    background-color: #282828;
  }
  .mobileDepth2 {
    position: fixed;
    background-color: #282828;
    width: 100%;
    height: calc(100 * var(--vh));
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lgi);
    color: var(--color-darkgray);
    font-family: var(--font-geomanist);
    z-index: 101;
  }
  .mobileDepth2Child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #282828;
    width: 500px;
    height: 852px;
  }
  .back,
  .dao,
  .ecosystem,
  .hpoToken,
  .protocol {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
  }
  .back {
    top: 31px;
    left: 25px;
    font-size: 18px;
    font-weight: 300;
  }
  .dao,
  .hpoToken,
  .protocol {
    top: 154px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .p2p {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 291px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .blog {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 358px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .news {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 425px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .press {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 492px;
    left: -1px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .dao,
  .hpoToken {
    top: 224px;
  }
  .dao {
    top: 360px;
  }
  .whatWeBuild {
    top: 119px;
    left: -1px;
    font-size: var(--font-size-mid);
    color: #8b9e8b;
    font-weight: 300;
  }
  .governedBy,
  .whatWeBuild {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .governedBy {
    top: 325px;
    left: -1px;
    font-size: var(--font-size-mid);
    color: #8b9e8b;
    font-weight: 300;
  }

  .whatWeBuild2 {
    top: 254px;
    left: 20px;
    font-size: var(--font-size-mid);
    color: #8b9e8b;
    font-weight: 300;
    position: absolute;
    letter-spacing: -0.02em;
  }
  .protocol2 {
    position: absolute;
    color: white;
    top: 289px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }

  .whatWeBuild3 {
    top: 389px;
    left: 20px;
    font-size: var(--font-size-mid);
    color: #8b9e8b;
    font-weight: 300;
    position: absolute;
    letter-spacing: -0.02em;
  }
  .protocol3 {
    position: absolute;
    color: white;
    top: 424px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .subChevronDownIcon {
    position: absolute;
    top: 27px;
    left: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .menuSubTitle {
    position: absolute;
    top: 31px;
    right: 0;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
}
@media screen and (max-width: 500px) {
  .pcDiv {
    display: none;
  }
  .mobileDiv {
    display: block;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    background-color: var(--color-header-bg);
    color: var(--color-white);
    z-index: 100;

    button {
      cursor: pointer;
    }
  }
  .gnb1000Child {
    background-color: var(--color-header-bg);
    width: 100%;
    height: 94px;
  }
  .hippocratNewLogoWhite2Icon {
    position: absolute;
    top: 20px;
    left: 0;
    width: 192px;
    height: 54px;
    object-fit: cover;
  }
  .gnb1000Item {
    position: absolute;
    top: 24px;
    right: 0;
    border-radius: 50%;
    background-color: #00d332;
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }
  .menu01Icon {
    position: absolute;
    top: 35px;
    right: 11px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000 {
    position: relative;
    background-color: var(--color-header-bg);
    width: 100%;
    margin: 0 auto;
    height: 94px;
    overflow: hidden;
  }
  .xCloseIcon {
    position: absolute;
    top: 36px;
    right: 11px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000XItem {
    position: absolute;
    top: 25px;
    right: 0;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }
}
@media screen and (max-width: 819px) and (min-width: 500px) {
  .pcDiv {
    display: none;
  }
  .mobileDiv {
    display: block;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    background-color: var(--color-header-bg);
    color: var(--color-white);
    z-index: 100;

    button {
      cursor: pointer;
    }
  }
  .gnb1000Child {
    background-color: var(--color-header-bg);
    width: 100%;
    height: 94px;
  }
  .hippocratNewLogoWhite2Icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 192px;
    height: 54px;
    object-fit: cover;
  }
  .gnb1000Item {
    position: absolute;
    top: 24px;
    right: 20px;
    border-radius: 50%;
    background-color: #00d332;
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }
  .menu01Icon {
    position: absolute;
    top: 35px;
    right: 31px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000 {
    position: relative;
    background-color: var(--color-header-bg);
    width: 100%;
    margin: 0 auto;
    height: 94px;
    overflow: hidden;
  }
  .xCloseIcon {
    position: absolute;
    top: 36px;
    right: 31px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000XItem {
    position: absolute;
    top: 25px;
    right: 20px;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }

  // menu style

  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .menuTitle,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .menuTitle {
    top: 101px;
    left: 0;
    font-size: 30px;
    color: var(--color-limegreen);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.68px;
  }
  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    top: 148px;
    left: 342px;
    font-size: var(--font-size-7xl);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.6px;
  }
  .leftMenu2 {
    top: 236px;
  }
  .leftMenu3 {
    top: 324px;
  }
  .leftMenu4 {
    top: 412px;
  }
  .rightMenu1 {
    top: 148px;
    left: 547px;
  }
  .rightMenu2 {
    top: 236px;
    left: 547px;
  }
  .rightMenu3 {
    top: 324px;
    left: 547px;
  }
  .rightMenu4 {
    top: 412px;
    left: 547px;
  }

  .leftMenuTitle {
    top: 111px;
    left: 342px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .rightMenuTitle,
  .menuTitleDec,
  .leftMenuTitle {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .rightMenuTitle {
    top: 111px;
    left: 547px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .menuTitleDec {
    top: 154px;
    left: 0;
    font-size: var(--font-size-sm);
    white-space: pre-wrap;
    color: var(--color-white);
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
  .leftMenuDec1,
  .leftMenuDec2,
  .leftMenuDec3,
  .leftMenuDec4,
  .rightMenuDec1,
  .rightMenuDec2,
  .rightMenuDec3,
  .rightMenuDec4 {
    position: absolute;
    top: 180px;
    left: 342px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    color: var(--color-darkgray-100);
    font-weight: 300;
  }
  .leftMenuDec2 {
    top: 268px;
  }
  .leftMenuDec3 {
    top: 356px;
  }
  .leftMenuDec4 {
    top: 444px;
  }
  .rightMenuDec1 {
    top: 180px;
    left: 547px;
  }
  .rightMenuDec2 {
    top: 268px;
    left: 547px;
  }
  .rightMenuDec3 {
    top: 356px;
    left: 547px;
  }
  .rightMenuDec4 {
    top: 444px;
    left: 547px;
  }
  .lnb1000Item {
    position: absolute;
    top: 66.75px;
    left: calc(50% - 400.25px);
    border-top: 0.5px solid #6b6b6b;
    box-sizing: border-box;
    width: 100%;
    height: 0.5px;
  }
  .menuSelect {
    border-radius: 14.5px;
    background-color: var(--color-limegreen);
    padding: 8px 20px;
    margin-top: -8px;
    margin-left: -20px;
    color: var(--color-black);
  }
  .solutions {
    position: absolute;
    top: 27px;
    left: 230px;
    letter-spacing: -0.02em;
    font-weight: 500;
    cursor: pointer;
  }
  .aboutUs a {
    text-decoration: none;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources {
    font-weight: 500;
    position: absolute;
    top: 27px;
    left: 335px;
    letter-spacing: -0.02em;
    cursor: pointer;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn {
    font-weight: 500;
    left: 447px;
  }
  .aboutUs,
  .community,
  .ecosystem {
    left: 530px;
  }
  .aboutUs,
  .ecosystem {
    left: 651px;
  }
  .ecosystem {
    left: 115px;
  }
  .lnb1000 {
    position: fixed;
    background-color: #282828;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    width: 100%;
    height: 562px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    z-index: 99;
    // 메뉴 열릴 때 애니메이션
    // transition: opacity 0.5s, visibility 0.5s;
    // opacity: 0;
    visibility: hidden;

    a {
      color: var(--color-white);
    }
  }
  .lnb1000.open {
    // 메뉴 닫힐 때 애니메이션
    // opacity: 1;
    visibility: visible;
  }
  .lnb1000Bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .lnb1000Bg.open {
    display: block;
  }

  .mobileDepth1Child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #282828;
    width: 500px;
    height: 852px;
  }
  .circle,
  .contact,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram,
  .twitterX {
    position: absolute;
    top: 683px;
    left: 20px;
    text-decoration: underline;
    letter-spacing: -0.02em;
    color: #8b9e8b;
    font-weight: 300;
  }
  .circle,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram,
  .twitterX {
    top: 751px;
  }
  .circle,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram {
    left: 150px;
  }
  .circle,
  .instagram,
  .linkedin,
  .medium,
  .telegram {
    top: 683px;
  }
  .circle,
  .instagram,
  .linkedin,
  .telegram {
    left: 260px;
  }
  .instagram,
  .linkedin,
  .telegram {
    top: 751px;
  }
  .instagram,
  .linkedin {
    top: 683px;
    left: 380px;
  }
  .instagram {
    top: 751px;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources,
  .solution {
    position: absolute;
    top: 154px;
    left: 20px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .aboutUs,
  .community,
  .learn,
  .resources,
  .solution {
    top: 224px;
  }
  .aboutUs,
  .community,
  .learn,
  .resources {
    top: 294px;
  }
  .aboutUs,
  .community,
  .learn {
    top: 364px;
  }
  .aboutUs,
  .community {
    top: 434px;
  }
  .aboutUs {
    top: 504px;
  }
  .chevronDownIcon {
    position: absolute;
    top: 157px;
    left: 208px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronDownIcon1,
  .chevronDownIcon2,
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    position: absolute;
    top: 224px;
    left: 188px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronDownIcon2,
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 297px;
    left: 206px;
  }
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 365px;
    left: 135px;
  }
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 434px;
    left: 187px;
  }
  .chevronDownIcon5 {
    top: 505px;
    left: 167px;
  }
  .mobileDepth1 {
    position: fixed;
    width: 100%;
    height: calc(100 * var(--vh));
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lgi);
    color: var(--color-darkgray);
    font-family: var(--font-geomanist);
    z-index: 9999;
    background-color: #282828;
  }
  .mobileDepth2 {
    position: fixed;
    background-color: #282828;
    width: 100%;
    height: calc(100 * var(--vh));
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lgi);
    color: var(--color-darkgray);
    font-family: var(--font-geomanist);
    z-index: 101;
  }
  .mobileDepth2Child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #282828;
    width: 500px;
    height: 852px;
  }
  .back,
  .dao,
  .ecosystem,
  .hpoToken,
  .protocol {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
  }
  .back {
    top: 31px;
    left: 45px;
    font-size: 18px;
    font-weight: 300;
  }
  .dao,
  .hpoToken,
  .protocol {
    top: 154px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .p2p {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 291px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .blog {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 358px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .news {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 425px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .press {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 492px;
    left: 20px;
    font-size: var(--font-size-11xl);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .dao,
  .hpoToken {
    top: 224px;
  }
  .dao {
    top: 360px;
  }
  .whatWeBuild {
    top: 119px;
    left: 20px;
    font-size: var(--font-size-mid);
    color: #8b9e8b;
    font-weight: 300;
  }
  .governedBy,
  .whatWeBuild {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .governedBy {
    top: 325px;
    left: 20px;
    font-size: var(--font-size-mid);
    color: #8b9e8b;
    font-weight: 300;
  }
  .subChevronDownIcon {
    position: absolute;
    top: 27px;
    left: 20px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .menuSubTitle {
    position: absolute;
    top: 31px;
    right: 20px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
}
@media screen and (max-width: 500px) {
  .pcDiv {
    display: none;
  }
  .mobileDiv {
    display: block;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 94px;
    background-color: var(--color-header-bg);
    color: var(--color-white);
    z-index: 100;

    button {
      cursor: pointer;
    }
  }
  .gnb1000Child {
    background-color: var(--color-header-bg);
    width: 100%;
    height: 94px;
  }
  .hippocratNewLogoWhite2Icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 192px;
    height: 54px;
    object-fit: cover;
  }
  .gnb1000Item {
    position: absolute;
    top: 24px;
    right: 20px;
    border-radius: 50%;
    background-color: #00d332;
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }
  .menu01Icon {
    position: absolute;
    top: 35px;
    right: 31px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000 {
    position: relative;
    background-color: var(--color-header-bg);
    width: 100%;
    margin: 0 auto;
    height: 94px;
    overflow: hidden;
  }
  .xCloseIcon {
    position: absolute;
    top: 36px;
    right: 31px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: cover;
  }
  .gnb1000XItem {
    position: absolute;
    top: 25px;
    right: 20px;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 2px solid #000;
    box-sizing: border-box;
    width: 46px;
    height: 46px;
  }

  // menu style

  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .menuTitle,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .menuTitle {
    top: 101px;
    left: 0;
    font-size: 30px;
    color: var(--color-limegreen);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.68px;
  }
  .rightMenu4,
  .rightMenu3,
  .rightMenu2,
  .rightMenu1,
  .leftMenu4,
  .leftMenu3,
  .leftMenu2,
  .leftMenu1 {
    top: 148px;
    left: 342px;
    font-size: var(--font-size-7xl);
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: normal;
    letter-spacing: -0.6px;
  }
  .leftMenu2 {
    top: 236px;
  }
  .leftMenu3 {
    top: 324px;
  }
  .leftMenu4 {
    top: 412px;
  }
  .rightMenu1 {
    top: 148px;
    left: 547px;
  }
  .rightMenu2 {
    top: 236px;
    left: 547px;
  }
  .rightMenu3 {
    top: 324px;
    left: 547px;
  }
  .rightMenu4 {
    top: 412px;
    left: 547px;
  }

  .leftMenuTitle {
    top: 111px;
    left: 342px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .rightMenuTitle,
  .menuTitleDec,
  .leftMenuTitle {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .rightMenuTitle {
    top: 111px;
    left: 547px;
    font-size: var(--font-size-smi);
    color: #8b9e8b;
    font-weight: 500;
  }
  .menuTitleDec {
    top: 154px;
    left: 0;
    font-size: var(--font-size-sm);
    white-space: pre-wrap;
    color: var(--color-white);
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
  .leftMenuDec1,
  .leftMenuDec2,
  .leftMenuDec3,
  .leftMenuDec4,
  .rightMenuDec1,
  .rightMenuDec2,
  .rightMenuDec3,
  .rightMenuDec4 {
    position: absolute;
    top: 180px;
    left: 342px;
    font-size: var(--font-size-sm);
    letter-spacing: -0.02em;
    color: var(--color-darkgray-100);
    font-weight: 300;
  }
  .leftMenuDec2 {
    top: 268px;
  }
  .leftMenuDec3 {
    top: 356px;
  }
  .leftMenuDec4 {
    top: 444px;
  }
  .rightMenuDec1 {
    top: 180px;
    left: 547px;
  }
  .rightMenuDec2 {
    top: 268px;
    left: 547px;
  }
  .rightMenuDec3 {
    top: 356px;
    left: 547px;
  }
  .rightMenuDec4 {
    top: 444px;
    left: 547px;
  }
  .lnb1000Item {
    position: absolute;
    top: 66.75px;
    left: calc(50% - 400.25px);
    border-top: 0.5px solid #6b6b6b;
    box-sizing: border-box;
    width: 100%;
    height: 0.5px;
  }
  .menuSelect {
    border-radius: 14.5px;
    background-color: var(--color-limegreen);
    padding: 8px 20px;
    margin-top: -8px;
    margin-left: -20px;
    color: var(--color-black);
  }
  .solutions {
    position: absolute;
    top: 27px;
    left: 230px;
    letter-spacing: -0.02em;
    font-weight: 500;
    cursor: pointer;
  }
  .aboutUs a {
    text-decoration: none;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources {
    font-weight: 500;
    position: absolute;
    top: 27px;
    left: 335px;
    letter-spacing: -0.02em;
    cursor: pointer;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn {
    font-weight: 500;
    left: 447px;
  }
  .aboutUs,
  .community,
  .ecosystem {
    left: 530px;
  }
  .aboutUs,
  .ecosystem {
    left: 651px;
  }
  .ecosystem {
    left: 115px;
  }
  .lnb1000 {
    position: fixed;
    background-color: #282828;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    width: 100%;
    height: 562px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    z-index: 99;
    // 메뉴 열릴 때 애니메이션
    // transition: opacity 0.5s, visibility 0.5s;
    // opacity: 0;
    visibility: hidden;

    a {
      color: var(--color-white);
    }
  }
  .lnb1000.open {
    // 메뉴 닫힐 때 애니메이션
    // opacity: 1;
    visibility: visible;
  }
  .lnb1000Bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .lnb1000Bg.open {
    display: block;
  }

  .mobileDepth1Child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #282828;
    width: 500px;
    height: 852px;
  }
  .circle,
  .contact,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram,
  .twitterX {
    position: absolute;
    top: 487px;
    left: 20px;
    text-decoration: underline;
    letter-spacing: -0.02em;
    color: #8b9e8b;
    font-weight: 500;
    font-size: 14px;
  }
  .circle,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram,
  .twitterX {
    top: 539px;
  }
  .circle,
  .discord,
  .instagram,
  .linkedin,
  .medium,
  .telegram {
    left: 105px;
  }
  .circle,
  .instagram,
  .linkedin,
  .medium,
  .telegram {
    top: 487px;
  }
  .circle,
  .instagram,
  .linkedin,
  .telegram {
    left: 180px;
  }
  .instagram,
  .linkedin,
  .telegram {
    top: 539px;
  }
  .instagram,
  .linkedin {
    top: 487px;
    left: 265px;
  }
  .instagram {
    top: 539px;
  }
  .aboutUs,
  .community,
  .ecosystem,
  .learn,
  .resources,
  .solution {
    position: absolute;
    top: 112px;
    left: 20px;
    font-size: 22px;
    letter-spacing: -0.44px;
    text-transform: uppercase;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .aboutUs,
  .community,
  .learn,
  .resources,
  .solution {
    top: 165px;
  }
  .aboutUs,
  .community,
  .learn,
  .resources {
    top: 218px;
  }
  .aboutUs,
  .community,
  .learn {
    top: 272px;
  }
  .aboutUs,
  .community {
    top: 325px;
  }
  .aboutUs {
    top: 504px;
  }
  .chevronDownIcon {
    position: absolute;
    top: 110px;
    left: 155px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronDownIcon1,
  .chevronDownIcon2,
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    position: absolute;
    top: 164px;
    left: 141px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .chevronDownIcon2,
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 217px;
    left: 154px;
  }
  .chevronDownIcon3,
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 270px;
    left: 102px;
  }
  .chevronDownIcon4,
  .chevronDownIcon5 {
    top: 323px;
    left: 145px;
  }
  .chevronDownIcon5 {
    top: 505px;
    left: 167px;
  }
  .mobileDepth1 {
    position: fixed;
    width: 100%;
    height: calc(100 * var(--vh));
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lgi);
    color: var(--color-darkgray);
    font-family: var(--font-geomanist);
    z-index: 9999;
    background-color: #282828;
  }
  .mobileDepth2 {
    position: fixed;
    background-color: #282828;
    width: 100%;
    height: calc(100 * var(--vh));
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lgi);
    color: var(--color-darkgray);
    font-family: var(--font-geomanist);
    z-index: 101;
  }
  .mobileDepth2Child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #282828;
    width: 500px;
    height: 852px;
  }
  .back,
  .dao,
  .ecosystem,
  .hpoToken,
  .protocol {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
  }
  .back {
    top: 20px;
    left: 45px;
    font-size: 14px;
    font-weight: 500;
  }
  .dao,
  .hpoToken,
  .protocol {
    top: 112px;
    left: 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .p2p {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 218px;
    left: 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .blog {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 271px;
    left: 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .news {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 324px;
    left: 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .press {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    top: 377px;
    left: 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .dao,
  .hpoToken {
    top: 165px;
  }
  .dao {
    top: 272px;
  }
  .whatWeBuild {
    top: 82px;
    font-size: 14px;
    color: #8b9e8b;
    font-weight: 500;
    left: 20px;
  }
  .governedBy,
  .whatWeBuild {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .governedBy {
    top: 242px;
    left: 20px;
    font-size: 14px;
    color: #8b9e8b;
    font-weight: 500;
  }
  .whatWeBuild2 {
    top: 197px;
    left: 20px;
    font-size: 14px;
    color: #8b9e8b;
    font-weight: 500;
    position: absolute;
    letter-spacing: -0.02em;
  }
  .protocol2 {
    position: absolute;
    color: white;
    top: 237px;
    left: 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .whatWeBuild3 {
    top: 312px;
    left: 20px;
    font-size: 14px;
    color: #8b9e8b;
    font-weight: 500;
    position: absolute;
    letter-spacing: -0.02em;
  }
  .protocol3 {
    position: absolute;
    color: white;
    top: 362px;
    left: 20px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .subChevronDownIcon {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  .menuSubTitle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: -0.36px;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
}
