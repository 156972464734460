@media screen and (max-width: 819px) and (min-width: 500px) {
  .protocol500Child,
  .protocol500Inner,
  .protocol500Item {
    position: absolute;
    top: 476px;
    left: 0;
    border-radius: var(--br-lg);
    background-color: var(--color-white);
    width: 500px;
    height: 332px;
  }
  .protocol500Inner,
  .protocol500Item {
    top: 822px;
  }
  .protocol500Inner {
    top: 1168px;
  }
  .first,
  .second,
  .third {
    position: absolute;
    top: 505px;
    left: 30px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .second,
  .third {
    top: 851px;
  }
  .third {
    top: 1197px;
  }
  .o {
    font-family: var(--font-bodoni-72);
  }
  .hippocratEcosystem,
  .protocol {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .protocol {
    top: 75px;
    left: 137px;
    font-size: var(--font-size-26xl);
  }
  .hippocratEcosystem {
    top: 134px;
    left: 151px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .hippocratEstablishesSustaina {
    position: absolute;
    top: 207px;
    left: calc(50% - 207px);
    font-size: 32px;
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 414px;
    text-align: center;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates,
  .healthcareDataRequires {
    position: absolute;
    top: 696px;
    left: 23px;
    letter-spacing: -0.02em;
    line-height: 25px;
    text-align: left;
    display: inline-block;
    width: 454px;
    font-size: 14px;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates {
    top: 1042px;
  }
  .healthcareDataDemands {
    top: 1388px;
  }
  .image17Icon,
  .image18Icon {
    position: absolute;
    top: 947px;
    left: 249px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .image18Icon {
    top: 1293px;
  }
  .rectangleDiv {
    position: absolute;
    bottom: 0;
    border-radius: var(--br-xl) var(--br-xl) 0 0;
    background-color: var(--color-black);
    width: 100%;
    height: 1800px;
  }
  .theHippocratWallet,
  .theUseOf,
  .utilizingTheHighly {
    position: absolute;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 375px;
    font-size: 14px;
  }
  .theUseOf {
    top: 3866px;
    left: calc(50% - 188px);
  }
  .theHippocratWallet,
  .utilizingTheHighly {
    top: 4403px;
    left: 62px;
  }
  .utilizingTheHighly {
    top: 3378px;
    left: 43px;
    width: 414px;
  }
  .leveraging {
    margin: 0;
  }
  .leveragingTheBitcoinContainer1 {
    width: 100%;
  }
  .leveragingTheBitcoinContainer {
    position: absolute;
    top: 3250px;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.02em;
    line-height: 55px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 500px;
  }
  .didVc {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .implementingDidContainer {
    position: absolute;
    top: 3737px;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.02em;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 500px;
  }
  .wallet {
    font-family: var(--font-geomanist);
  }
  .providingTheHippocratContainer {
    position: absolute;
    top: 4275px;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.02em;
    line-height: 55px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 500px;
  }
  .maskGroupIcon,
  .maskGroupIcon1,
  .maskGroupIcon2 {
    position: absolute;
    top: 517px;
    left: 163px;
    width: 164px;
    height: 164px;
    object-fit: contain;
  }
  .maskGroupIcon1,
  .maskGroupIcon2 {
    top: 863px;
  }
  .maskGroupIcon2 {
    top: 1209px;
  }
  .maskGroupIcon3,
  .maskGroupIcon4 {
    position: absolute;
    top: 3033px;
    left: calc(50% - 108px);
    width: 216px;
    height: 216px;
    object-fit: cover;
  }
  .maskGroupIcon4 {
    top: 4019px;
  }
  .maskGroupIcon6 {
    position: absolute;
    top: 3460px;
    left: calc(50% - 160px);
    width: 320px;
    height: 320px;
    object-fit: cover;
  }
  .protocol500 {
    position: relative;
    width: 100%;
    height: 4686px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -350px;
    img {
      pointer-events: none;
    }
  }
  .daoImg {
    position: absolute;
    bottom: 1840px;
    left: calc(50% - 250px);
    width: 500px;
  }
}
@media screen and (max-width: 500px) {
  .first,
  .second,
  .third {
    position: absolute;
    top: 505px;
    left: 18px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .second,
  .third {
    top: 851px;
  }
  .third {
    top: 1197px;
  }
  .o {
    font-family: var(--font-bodoni-72);
  }
  .hippocratEcosystem,
  .protocol {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .protocol {
    top: 79px;
    left: calc(50% - 180px);
    width: 360px;
    line-height: 25px;
    font-size: var(--font-size-16xl);
  }
  .hippocratEcosystem {
    top: 134px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .hippocratEstablishesSustaina {
    position: absolute;
    top: 207px;
    left: calc(50% - 180px);
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 40px;
    display: inline-block;
    width: 360px;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates,
  .healthcareDataRequires {
    position: absolute;
    top: 696px;
    left: 10px;
    letter-spacing: -0.02em;
    line-height: 25px;
    text-align: left;
    display: inline-block;
    width: calc(100% - 20px);
    font-size: 14px;
  }
  .healthcareDataDemands,
  .healthcareDataNecessitates {
    top: 1042px;
  }
  .healthcareDataDemands {
    top: 1388px;
  }
  .image17Icon,
  .image18Icon {
    position: absolute;
    top: 947px;
    left: 179px;
    width: 1px;
    height: 1px;
    object-fit: cover;
  }
  .image18Icon {
    top: 1293px;
  }
  .protocol360Child {
    position: absolute;
    bottom: 0;
    border-radius: var(--br-xl) var(--br-xl) 0 0;
    background-color: var(--color-black);
    width: 100%;
    height: 1840px;
  }
  .theHippocratWallet,
  .theUseOf,
  .utilizingTheHighly {
    position: absolute;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: var(--color-gainsboro);
    display: inline-block;
    width: 360px;
    font-size: 14px;
  }
  .theUseOf {
    top: 3503px;
    left: calc(50% - 180px);
  }
  .theHippocratWallet,
  .utilizingTheHighly {
    top: 4078px;
    left: 0;
    left: calc(50% - 180px);
    width: 360px;
  }
  .utilizingTheHighly {
    top: 3015px;
    left: calc(50% - 180px);
    width: 360px;
  }
  .leveraging {
    margin: 0;
  }
  .leveragingTheBitcoinContainer1 {
    width: 100%;
  }
  .leveragingTheBitcoinContainer {
    position: absolute;
    top: 2891px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    height: 79px;
  }
  .didVc {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .implementingDidContainer {
    position: absolute;
    top: 3385px;
    left: calc(50% - 161px);
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    width: 322px;
    height: 67px;
  }
  .wallet {
    font-family: var(--font-geomanist);
  }
  .providingTheHippocratContainer {
    position: absolute;
    top: 3954px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    height: 79px;
  }
  .maskGroupIcon,
  .maskGroupIcon1,
  .maskGroupIcon2 {
    position: absolute;
    top: 517px;
    left: 89px;
    width: 164px;
    height: 164px;
    object-fit: contain;
  }
  .maskGroupIcon1,
  .maskGroupIcon2 {
    top: 863px;
  }
  .maskGroupIcon2 {
    top: 1209px;
  }
  .maskGroupIcon3,
  .maskGroupIcon4 {
    position: absolute;
    top: 3702px;
    left: calc(50% - 101px);
    width: 202px;
    height: 202px;
    object-fit: contain;
  }
  .maskGroupIcon4 {
    top: 2650px;
    left: calc(50% - 108px);
    width: 216px;
    height: 216px;
    object-fit: cover;
  }
  .maskGroupIcon6 {
    position: absolute;
    top: 3100px;
    left: calc(50% - 160px);
    width: 320px;
    height: 320px;
    object-fit: cover;
  }
  .protocol360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 4348px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -350px;
    img {
      pointer-events: none;
    }
  }
  .daoImg {
    position: absolute;
    bottom: 1880px;
    left: calc(50% - 180px);
    width: 360px;
  }
}
