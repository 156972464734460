@media screen and (min-width: 1020px) {
  .home1000Child {
    position: absolute;
    top: 1230px;
    border-radius: var(--br-11xl) var(--br-11xl) 0 0;
    background-color: #76f15c;
    width: 100%;
    height: 686px;
  }
  .cooperativeProtocolFor {
    font-size: var(--font-size-36xl);
    font-family: var(--font-geomanist);
    letter-spacing: -0.02em;
    line-height: 65px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 104px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .theCoolestForm {
    margin: 0;
  }
  .theCoolestFormContainer1 {
    width: 100%;
  }
  .theCoolestFormContainer {
    position: absolute;
    top: 2780px;
    left: calc(50% - 500px);
    font-size: 50px;
    letter-spacing: -0.02em;
    line-height: 65px;
    display: flex;
    align-items: center;
    width: 810px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .humanHealthIs {
    position: absolute;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    top: 2950px;
    left: 0;
    display: flex;
    align-items: center;
    width: 830px;
    height: 64px;
  }
  .beyondBoundariesBeyondContainer {
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 47px;
    display: inline-block;
    text-align: center;
    margin-top: 46px;
    width: 100%;
    height: 83px;
    font-family: var(--font-geomanist);
  }
  .home1000Inner,
  .rectangleDiv2,
  .rectangleDiv {
    position: absolute;
    top: 403px;
    left: 321px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen-100);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
    cursor: pointer;
  }
  .home1000Inner,
  .rectangleDiv {
    left: 509px;
    background-color: var(--color-white);
  }
  .rectangleDiv2 {
    top: 3220px;
    left: 188px;
    background-color: var(--color-white);
  }
  .home1000Item {
  }
  .builtOnThe,
  .enablingGlobalCooperation,
  .selfSovereigntyIsThe {
    position: absolute;
    top: 2398px;
    left: 680px;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 255px;
    height: 71px;
    font-family: var(--font-inter);
  }
  .builtOnThe,
  .selfSovereigntyIsThe {
    left: 0;
  }
  .builtOnThe {
    left: 340px;
  }
  .home1000Child1,
  .home1000Child2 {
    position: absolute;
    top: 1750px;
    left: 510px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen-100);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .home1000Child2 {
    top: 3220px;
    left: 0;
    background-color: var(--color-white);
  }
  .home1000Child3,
  .home1000Child4,
  .home1000Child5,
  .home1000Child6,
  .home1000Child7 {
    position: absolute;
    top: 2117px;
    left: calc(50% - 500px);
    border-radius: var(--br-lg);
    // background-color: var(--color-whitesmoke);
    // width: 270px;
    // height: 250px;
  }
  .home1000Child3 {
    width: 259px;
    height: 250px;
  }
  .home1000Child6 {
    width: 270px;
    height: 250px;
  }
  .home1000Child4,
  .home1000Child5,
  .home1000Child6,
  .home1000Child7 {
    top: 1267px;
    width: 490px;
    height: 649px;
  }
  .home1000Child5,
  .home1000Child6,
  .home1000Child7 {
    top: 980px;
    width: 1000px;
    height: 164px;
  }
  .home1000Child6,
  .home1000Child7 {
    top: 2117px;
    left: calc(50% - 160px);
    width: 270px;
    height: 250px;
  }
  .home1000Child7 {
    left: calc(50% + 180px);
    width: 239px;
    height: 239px;
  }
  .hippocratDevelopsOpenSource,
  .weAreIntroducing {
    position: absolute;
    top: 1585px;
    left: 510px;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 490px;
    font-family: var(--font-inter);
  }
  .hippocratDevelopsOpenSource {
    top: 3064px;
    left: 0;
    width: 596px;
    height: 106px;
  }
  .anytime {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .anyoneCanAccessContainer {
    position: absolute;
    top: 1460px;
    left: 510px;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 490px;
    height: 120px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .cryptocurrencyExchange,
  .hippocratIsTransforming,
  .solutionForPersonalizedContainer {
    position: absolute;
    top: 816px;
    left: 425px;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 575px;
  }
  .hippocratIsTransforming {
    font-family: var(--font-inter);
  }
  .cryptocurrencyExchange,
  .solutionForPersonalizedContainer {
    top: 717px;
    left: 0;
    font-size: var(--font-size-15xl);
    line-height: 46px;
    width: 405px;
    height: 70px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .cryptocurrencyExchange {
    top: 3506px;
    left: 10px;
    text-transform: uppercase;
    width: 480px;
    height: 25px;
  }
  .home1000Child8 {
    position: absolute;
    top: 4771px;
    left: calc(50% - 435px);
    border-radius: var(--br-11xl);
    background-color: #282828;
    width: 870px;
    height: 492px;
    pointer-events: none;
  }
  .contact {
    position: absolute;
    top: 4883px;
    left: calc(50% - 25px);
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--color-white);
  }
  .home1000Child10,
  .home1000Child9 {
    position: absolute;
    top: 4312px;
    border-radius: var(--br-980xl);
    box-sizing: border-box;
    width: 196px;
    height: 46px;
  }
  .home1000Child9 {
    left: 297px;
    background-color: var(--color-limegreen-100);
    border: 1px solid var(--color-black);
  }
  .home1000Child10 {
    left: 511px;
    background-color: transparent;
    border: 1px solid var(--color-white);
  }
  .changingHealthcareTogether,
  .contact1 {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .changingHealthcareTogether {
    top: 4934px;
    left: calc(50% - 415px);
    font-size: var(--font-size-36xl);
    line-height: 65px;
    white-space: pre-wrap;
    width: 830px;
    height: 104px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .exchangeContainer {
    position: absolute;
    top: 3588px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    justify-content: center;
  }
  .exchangeImageBox {
    display: flex;
    gap: 66px;
    img {
      cursor: pointer !important;
      width: 200px;
      height: 80px;
    }
  }
  .image54Icon,
  .image55Icon,
  .image56Icon {
    position: absolute;
    top: 3664px;
    left: 615px;
    width: 194px;
    height: 56px;
    object-fit: cover;
  }
  .image54Icon,
  .image56Icon {
    top: 3671px;
    left: 191px;
    width: 144px;
    height: 42px;
  }
  .image56Icon {
    top: 3666px;
    left: 363px;
    width: 233px;
    height: 51px;
  }
  .openSource {
    top: 5113px;
    left: calc(50% - 204px);

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .emailUs,
  .openDiscussion,
  .openSource,
  .openSource1 {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .emailUs {
    top: 5113px;
    left: calc(50% + 10px);
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .openDiscussion {
    top: 3220px;
    left: 190px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-white);
  }
  .openSource1 {
    top: 3220px;
    left: 0;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .learnMore,
  .learnMore1 {
    top: 2519px;
    text-decoration: underline;
    color: var(--color-limegreen-100);
    cursor: pointer;

    a {
      color: var(--color-limegreen-100);
    }
  }
  .learnMore {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .learnMore1 {
    left: 340px;
  }
  .learnMore1,
  .learnMore2,
  .letsCheck {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
    cursor: pointer;
    a {
      color: var(--color-limegreen-100);
    }
  }
  .learnMore2 {
    top: 2519px;
    left: 680px;
    text-decoration: underline;
    color: var(--color-limegreen-100);
  }
  .letsCheck {
    top: 1750px;
    left: 510px;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .joinCommunity,
  .whatWeFocusContainer {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .seeWhitepaper {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    color: var(--color-black);
  }
  .seeWhitepaper2 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    color: var(--color-black);
  }
  .seeWhitepaperWrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 20px;
    a {
      text-decoration: none;
    }
  }

  .joinCommunity {
    top: 416px;
    left: 528px;
    font-weight: 500;
  }
  .whatWeFocusContainer {
    top: 2042px;
    left: 0;
    font-size: var(--font-size-15xl);
    line-height: 46px;
    display: inline-block;
    height: 25px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .home1000 {
    position: relative;
    width: 100%;
    height: 5383px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -590px;

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }

  .colorBlack {
    color: var(--color-black);
  }
  .rectangleDivA {
    color: var(--color-black);
  }

  .partnershipWrapper {
    position: absolute;
    top: 3940px;
  }
  .parent,
  .partnership {
    position: absolute;
    text-align: left;
  }
  .parent {
    top: 23px;
    left: 0;
    width: 998px;
    height: 37.99px;
    font-size: 27px;
    color: var(--color-white);
    font-family: var(--font-bodoni-72);
  }
  .partnership {
    left: calc(50% - 250px);
    width: 500px;
    text-align: center;
    font-size: var(--font-size-31xl);
    letter-spacing: -0.02em;
    line-height: 58px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .ch {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .partnerImageWrapper {
    // position: absolute;
    // top: 3150px;
    margin-top: 115px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    justify-content: center;
  }
  .partnerImageBox {
    display: flex;
    gap: 66px;
    img {
      cursor: pointer !important;
      width: 200px;
      height: 80px;
    }
  }
}

@media screen and (max-width: 1019px) and (min-width: 820px) {
  .cooperativeProtocolFor {
    font-size: var(--font-size-24xl);

    letter-spacing: -0.02em;
    line-height: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 104px;
    font-family: var(--font-geomanist);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .beyondBoundaries {
    margin: 0;
  }
  .beyondBoundariesBeyondContainer {
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 58px;
    margin-top: 46px;
    font-family: var(--font-geomanist);
  }
  .home800Child,
  .home800Item {
    position: absolute;
    top: 358px;
    left: 220px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .home800Item {
    left: 410px;
    background-color: var(--color-white);
  }
  .daoGovernanceByContainer1 {
    width: 100%;
  }
  .daoGovernanceByContainer,
  .humanHealthIs {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
  }
  .daoGovernanceByContainer {
    top: 2570px;
    left: calc(50% - 400px);
    font-size: var(--font-size-24xl);
    line-height: 55px;
    width: 870px;
  }
  .humanHealthIs {
    top: 2731px;
    left: 0;
    font-size: var(--font-size-13xl);
    line-height: 47px;
    width: 800px;
    height: 64px;
  }
  .home800Inner {
    position: absolute;
    top: 2956px;
    left: 188px;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .builtOnThe,
  .enablingGlobalCooperation,
  .selfSovereigntyIsThe {
    position: absolute;
    top: 2198px;
    left: 547px;
    font-size: var(--font-size-lg);
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 253px;
    font-size: 16px;
  }
  .builtOnThe,
  .selfSovereigntyIsThe {
    top: 2196px;
    left: 0;
    font-size: 16px;
  }
  .builtOnThe {
    top: 2198px;
    left: 272px;
  }
  .maskGroupIcon,
  .maskGroupIcon1,
  .maskGroupIcon2 {
    position: absolute;
    top: 1937px;
    left: calc(50% - 120px);
    width: 240px;
    height: 222px;
    object-fit: cover;
  }
  .maskGroupIcon1,
  .maskGroupIcon2 {
    left: calc(50% + 166px);
    object-fit: contain;
    width: 212px;
    height: 212px;
  }
  .maskGroupIcon2 {
    left: calc(50% - 400px);
    width: 240px;
    height: 232px;
  }
  .home800Child1,
  .rectangleDiv {
    position: absolute;
    top: 1574px;
    left: 412px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .home800Child1 {
    top: 2956px;
    left: 0;
    background-color: var(--color-white);
  }
  .icon {
    position: absolute;
    bottom: 3230px;
    left: -20px;
    width: 606px;
    height: 784px;
    object-fit: cover;
  }
  .hippocratDevelopsOpenSource,
  .weAreIntroducing {
    position: absolute;
    top: 1438px;
    left: 410px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 390px;
  }
  .hippocratDevelopsOpenSource {
    top: 2845px;
    left: 0;
    width: 596px;
    font-size: 14px;
  }
  .anytime {
    font-family: var(--font-bodoni-72);
  }
  .universalHealthcareForContainer {
    position: absolute;
    top: 1274px;
    left: 412px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 388px;
  }
  .image35Icon,
  .maskGroupIcon3 {
    position: absolute;
    height: 164px;
    object-fit: cover;
  }
  .image35Icon {
    top: 833px;
    left: 0;
    border-radius: var(--br-11xl);
    width: 800px;
  }
  .maskGroupIcon3 {
    top: 834px;
    left: calc(50% - 400px);
    width: 800px;
    border-radius: var(--br-11xl);
  }
  .hippocratIsTransforming {
    position: absolute;
    top: 662px;
    left: 410px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 390px;
    font-size: 14px;
  }
  .cryptocurrencyExchange,
  .solutionForPersonalizedContainer,
  .whatWeFocusContainer {
    position: absolute;
    top: 590px;
    left: 0;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 46px;
    display: inline-block;
    width: 390px;
  }
  .cryptocurrencyExchange,
  .whatWeFocusContainer {
    top: 1840px;
    width: 412px;
    height: 25px;
  }
  .cryptocurrencyExchange {
    top: 3242px;
    text-transform: uppercase;
    width: 512px;
  }
  .home800Child2 {
    position: absolute;
    top: 4355px;
    left: 66px;
    border-radius: var(--br-11xl);
    background-color: #282828;
    width: 669px;
    height: 577px;
    pointer-events: none;
  }
  .home800Child3,
  .home800Child4 {
    position: absolute;
    top: 4077px;
    border-radius: var(--br-980xl);
    box-sizing: border-box;
    width: 196px;
    height: 46px;
  }
  .home800Child3 {
    left: 195px;
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
  }
  .home800Child4 {
    left: 409px;
    background-color: transparent;
    border: 1px solid var(--color-white);
  }
  .letsRevolutionizeHealthcareContainer {
    position: absolute;
    top: 4568px;
    left: calc(50% - 332px);
    font-size: var(--font-size-24xl);
    letter-spacing: -0.02em;
    line-height: 55px;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    text-align: center;
    display: flex;
    align-items: center;
    width: 664px;
    height: 104px;
  }
  .exchangeContainer {
    position: absolute;
    top: 3311px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    justify-content: center;
  }
  .exchangeImageBox {
    display: flex;
    gap: 40px;
    img {
      cursor: pointer !important;
      width: 170px;
      height: 68px;
    }
  }
  .image54Icon,
  .image55Icon,
  .image56Icon {
    position: absolute;
    top: 3402px;
    left: 535px;
    width: 194px;
    height: 56px;
    object-fit: cover;
  }
  .image54Icon,
  .image56Icon {
    top: 3409px;
    left: 66px;
    width: 144px;
    height: 42px;
  }
  .image56Icon {
    top: 3402px;
    left: 260px;
    width: 233px;
    height: 51px;
  }
  .joinCommunity {
    top: 4747px;
    left: calc(50% - 205px);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .emailUs,
  .joinCommunity,
  .openDiscussion,
  .openSource {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .emailUs {
    top: 4747px;
    left: calc(50% + 9px);
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .openSource {
    top: 2955px;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .openDiscussion {
    top: 2955px;
    left: 187px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-white);
  }
  .learnMore,
  .learnMore1 {
    top: 2309px;
    text-decoration: underline;
    color: var(--color-limegreen);
  }
  .learnMore {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    font-weight: 500;
    a {
      color: var(--color-limegreen-100);
    }
  }
  .learnMore1 {
    left: 273px;
  }
  .learnMore1,
  .learnMore2,
  .learnMore3 {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;

    a {
      color: var(--color-limegreen-100);
    }
  }
  .learnMore2 {
    top: 2309px;
    left: 547px;
    text-decoration: underline;
    color: var(--color-limegreen);
  }
  .learnMore3 {
    top: 1574px;
    left: 412px;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .contact,
  .joinCommunity1 {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .joinCommunity1 {
    top: 371px;
    left: 429px;
  }
  .contact {
    top: 4522px;
    left: calc(50% - 27px);
    color: var(--color-white);
  }
  .home800 {
    position: relative;
    width: 100%;
    height: 5040px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -500px;
  }

  .seeWhitepaper {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .seeWhitepaper2 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }

  .seeWhitepaperWrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 20px;
    a {
      text-decoration: none;
    }
  }
  .partnershipWrapper {
    position: absolute;
    top: 3660px;
    width: 100%;
  }
  .partnershipTxt {
    line-break: anywhere;
    width: 100%;
  }
  .partnership {
    // left: calc(50% - 250px);
    text-align: center;
    letter-spacing: -0.02em;
    line-height: 58px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: var(--font-size-24xl);
    color: var(--color-black);
    // width: 500px;
    height: 34px;
  }
  .partnerImageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
    margin-top: 60px;
  }
  .partnerImageBox {
    display: flex;
    gap: 40px;
    img {
      cursor: pointer !important;
      width: 170px;
      height: 68px;
    }
  }
}

@media screen and (min-width: 1020px) {
  .homeBanner {
    position: fixed;
    width: 534px;
    z-index: 999;
    margin-left: calc(50vw - 327px);
    margin-top: calc(50vh - 370px);
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.32);
    padding: 90px 60px;
    .homeClose {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      img {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .decoBg {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
    .homeBannerTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 44px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: -0.88px;
      height: 31px;
      line-height: 31px;
      .homeBannerTitleN {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Bodoni 72 Oldstyle';
        font-size: 44px;
        font-style: italic;
        font-weight: 400;
        height: 31px;
        line-height: 31px;
        letter-spacing: -1.76px;
      }
      .homeBannerTitleOW {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'BM Hanna Pro';
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        height: 31px;
        line-height: 31px;
        letter-spacing: -1.76px;
      }
    }
    .homeBannerSubTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.32px;
      margin-top: 48px;
      height: 18px;
      line-height: 18px;
    }
    .homeBannerBorder {
      background: none;
      height: 1px;
      width: 100%;
      margin-top: 37px;
      img {
        height: 1px;
        width: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .homeBannerContentTitle {
      color: #7d7d7d;
      text-align: center;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.28px;
      text-transform: uppercase;
    }
    .homeBannerContentDesc {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
    }
    .homeBannerBtn {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
    }
    .homeVoteBtn {
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      margin-top: 58px;
      img {
        width: 534px;
        height: 40px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
  .homeCommunityVote {
    justify-content: center;
    display: flex;
    margin-top: 94px;
    padding-top: 80px;
    padding-bottom: 60px;
    .communityVoteWapper {
      width: 870px;
      padding: 60px 0;
      flex-shrink: 0;
      border-radius: 30px;
      background: #282828;
      text-align: center;
    }
    .communityVoteLeft {
      position: absolute;
      margin-top: 163px;
      img {
        width: 120px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteRight {
      position: absolute;
      margin-top: 163px;
      margin-left: 750px;
      img {
        width: 120px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteTitleVote {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.32px;
      height: 12px;
      align-self: stretch;
    }
    .communityVoteTitle {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 58px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: -1.16px;
      height: 51px;
      line-height: 51px;
      margin-top: 32px;
    }
    .communityVoteDesc {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.36px;
      margin-top: 72px;
      margin-bottom: 80px;
    }
    .gotovoteBtn {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      img {
        width: 236px;
        height: 46px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
}

@media screen and (max-width: 1019px) and (min-width: 820px) {
  .homeBanner {
    position: fixed;
    width: 534px;
    z-index: 999;
    margin-left: calc(50vw - 327px);
    margin-top: calc(50vh - 370px);
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.32);
    padding: 90px 60px;
    .homeClose {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      img {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .decoBg {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
    .homeBannerTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 44px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: -0.88px;
      height: 31px;
      line-height: 31px;
      .homeBannerTitleN {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Bodoni 72 Oldstyle';
        font-size: 44px;
        font-style: italic;
        font-weight: 400;
        height: 31px;
        line-height: 31px;
        letter-spacing: -1.76px;
      }
      .homeBannerTitleOW {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'BM Hanna Pro';
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        height: 31px;
        line-height: 31px;
        letter-spacing: -1.76px;
      }
    }
    .homeBannerSubTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.32px;
      margin-top: 48px;
      height: 18px;
      line-height: 18px;
    }
    .homeBannerBorder {
      background: none;
      height: 1px;
      width: 100%;
      margin-top: 37px;
      img {
        height: 1px;
        width: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .homeBannerContentTitle {
      color: #7d7d7d;
      text-align: center;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.28px;
      text-transform: uppercase;
    }
    .homeBannerContentDesc {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
    }
    .homeBannerBtn {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
    }
    .homeVoteBtn {
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      margin-top: 58px;
      img {
        width: 534px;
        height: 40px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
  .homeCommunityVote {
    justify-content: center;
    display: flex;
    margin-top: 94px;
    padding-top: 80px;
    padding-bottom: 60px;
    .communityVoteWapper {
      width: 699px;
      padding: 60px 0;
      flex-shrink: 0;
      border-radius: 30px;
      background: #282828;
      text-align: center;
    }
    .communityVoteLeft {
      position: absolute;
      margin-top: 163px;
      img {
        width: 120px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteRight {
      position: absolute;
      margin-top: 163px;
      margin-left: 580px;
      img {
        width: 120px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteTitleVote {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.32px;
      height: 12px;
      align-self: stretch;
    }
    .communityVoteTitle {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 68px; /* 141.667% */
      letter-spacing: -0.96px;
      height: 51px;
      line-height: 51px;
      margin-top: 32px;
    }
    .communityVoteDesc {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.36px;
      margin-top: 72px;
      margin-bottom: 80px;
    }
    .gotovoteBtn {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      img {
        width: 236px;
        height: 46px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
}
@media screen and (max-width: 819px) and (min-width: 380px) {
  .homeBanner {
    position: fixed;
    width: 296px;
    z-index: 999;
    margin-left: calc(50vw - 188px);
    margin-top: calc(50vh - 333px);
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.32);
    padding: 60px 40px;
    .homeClose {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .decoBg {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
    .homeBannerTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 37px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.74px;
      line-height: 26px;
      height: 26px;
      .homeBannerTitleN {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Bodoni 72 Oldstyle';
        font-size: 37px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1.48px;
        line-height: 26px;
        height: 26px;
      }
      .homeBannerTitleOW {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'BM Hanna Pro';
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        height: 31px;
        line-height: 31px;
        letter-spacing: -1.76px;
      }
    }
    .homeBannerSubTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.28px;
      margin-top: 32px;
      line-height: 19px;
    }
    .homeBannerBorder {
      background: none;
      height: 1px;
      width: 100%;
      margin-top: 10px;
      img {
        height: 1px;
        width: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .homeBannerContentTitle {
      color: #7d7d7d;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.28px;
      text-transform: uppercase;
    }
    .homeBannerContentDesc {
      color: #000;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
      text-align: center;
    }
    .homeBannerContentDesc_mobile {
      color: #000;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.84px;
      text-align: center;
    }
    .homeBannerBtn {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
    }
    .homeVoteBtn {
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      margin-top: 58px;
      img {
        width: 100%;
        height: 36px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
  .homeCommunityVote {
    justify-content: center;
    display: flex;
    margin-top: 94px;
    padding-top: 80px;
    padding-bottom: 60px;
    .communityVoteWapper {
      width: 325px;
      padding: 60px 0;
      flex-shrink: 0;
      border-radius: 30px;
      background: #282828;
      text-align: center;
    }
    .communityVoteLeft {
      position: absolute;
      margin-top: 192px;
      img {
        width: 100px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteRight {
      position: absolute;
      margin-top: 192px;
      margin-left: 225px;
      img {
        width: 100px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteTitleVote {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.32px;
      height: 12px;
      align-self: stretch;
    }
    .communityVoteTitle {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px; /* 100% */
      letter-spacing: -0.64px;
      padding: 0 20px;
      height: 54px;
      margin-top: 32px;
    }
    .communityVoteDesc {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.28px;
      margin-top: 62px;
      margin-bottom: 110px;
    }
    .gotovoteBtn {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      img {
        width: 236px;
        height: 46px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
}
@media screen and (max-width: 380px) {
  .homeBanner {
    position: fixed;
    width: 296px;
    z-index: 999;
    margin-left: calc(50vw - 168px);
    margin-top: calc(50vh - 333px);
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.32);
    padding: 60px 20px;
    .homeClose {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .decoBg {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
    .homeBannerTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 37px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.74px;
      line-height: 26px;
      height: 26px;
      .homeBannerTitleN {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Bodoni 72 Oldstyle';
        font-size: 37px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1.48px;
        line-height: 26px;
        height: 26px;
      }
      .homeBannerTitleOW {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'BM Hanna Pro';
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        height: 31px;
        line-height: 31px;
        letter-spacing: -1.76px;
      }
    }
    .homeBannerSubTitle {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.28px;
      margin-top: 32px;
      line-height: 19px;
    }
    .homeBannerBorder {
      background: none;
      height: 1px;
      width: 100%;
      margin-top: 10px;
      img {
        height: 1px;
        width: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .homeBannerContentTitle {
      color: #7d7d7d;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.28px;
      text-transform: uppercase;
    }
    .homeBannerContentDesc {
      color: #000;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
      text-align: center;
    }
    .homeBannerContentDesc_mobile {
      color: #000;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.84px;
      text-align: center;
    }
    .homeBannerBtn {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.28px;
    }
    .homeVoteBtn {
      cursor: pointer;
      padding: 0;
      background: none;
      border: none;
      margin-top: 58px;
      img {
        width: 100%;
        height: 36px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
  .homeCommunityVote {
    justify-content: center;
    display: flex;
    margin-top: 94px;
    padding-top: 80px;
    padding-bottom: 60px;
    .communityVoteWapper {
      width: 325px;
      padding: 60px 0;
      flex-shrink: 0;
      border-radius: 30px;
      background: #282828;
      text-align: center;
    }
    .communityVoteLeft {
      position: absolute;
      margin-top: 192px;
      img {
        width: 100px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteRight {
      position: absolute;
      margin-top: 192px;
      margin-left: 225px;
      img {
        width: 100px;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
    .communityVoteTitleVote {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.32px;
      height: 12px;
      align-self: stretch;
    }
    .communityVoteTitle {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px; /* 100% */
      letter-spacing: -0.64px;
      padding: 0 20px;
      height: 54px;
      margin-top: 32px;
    }
    .communityVoteDesc {
      color: #fff;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Geomanist;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.28px;
      margin-top: 62px;
      margin-bottom: 110px;
    }
    .gotovoteBtn {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      img {
        width: 236px;
        height: 46px;
        flex-shrink: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
    }
  }
}
