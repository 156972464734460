@media screen and (min-width: 1020px) {
  .mediaKitChild {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 340px;
  }
  .kit {
    font-family: var(--font-bodoni-72);
  }
  .pressKit {
    top: 100px;
    font-size: 68px;
    color: var(--color-white);
  }
  .allYourMedia,
  .pressKit,
  .signitureLogo {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .allYourMedia {
    top: 167px;
    font-size: 22px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .signitureLogo {
    top: 562px;
    font-size: 28px;
    line-height: 35px;
    font-family: var(--font-inter);
  }
  .aLogoCan {
    margin: 0;
  }
  .aLogoCanContainer,
  .brandIdentity {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .aLogoCanContainer {
    top: 629px;
    font-size: 17px;
    line-height: 28px;
    font-family: var(--font-inter);
    text-align: left;
    display: inline-block;
    width: 980px;
    height: 41px;
  }
  .brandIdentity {
    top: 440px;
    font-size: var(--font-size-15xl);
    line-height: 47px;
  }
  .mediaKitItem {
    position: absolute;
    top: 702px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-white);
    width: 490px;
    height: 280px;
  }
  .download,
  .download1,
  .download2,
  .download3,
  .download4 {
    position: absolute;
    top: 939px;
    left: 368px;
    text-decoration: underline;
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .download1,
  .download2,
  .download3,
  .download4 {
    top: 1491px;
  }
  .download2,
  .download3,
  .download4 {
    top: 2043px;
  }
  .download3,
  .download4 {
    top: 1491px;
    left: 878px;
  }
  .download4 {
    top: 2043px;
  }
  .mediaKitInner {
    position: absolute;
    top: 1254px;
    left: 510px;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 490px;
    height: 280px;
  }
  .icon {
    position: absolute;
    top: 1366px;
    left: 585px;
    width: 339.15px;
    height: 48.45px;
    overflow: hidden;
  }
  .mediaKitChild1,
  .mediaKitChild2,
  .rectangleDiv {
    position: absolute;
    top: 1806px;
    left: 510px;
    border-radius: var(--br-11xl);
    background-color: var(--color-white);
    width: 490px;
    height: 280px;
  }
  .mediaKitChild1,
  .mediaKitChild2 {
    top: 1254px;
    left: 0;
  }
  .mediaKitChild2 {
    top: 1806px;
  }
  .aLogoCanContainer1,
  .characterSymbolLogo,
  .itCanBe,
  .monoLogo {
    position: absolute;
    top: 1114px;
    left: 0;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 35px;
    font-family: var(--font-inter);
    text-align: left;
  }
  .aLogoCanContainer1,
  .characterSymbolLogo,
  .itCanBe {
    top: 1666px;
  }
  .aLogoCanContainer1,
  .itCanBe {
    top: 1181px;
    font-size: 17px;
    line-height: 28px;
    display: inline-block;
    width: 980px;
    height: 41px;
  }
  .aLogoCanContainer1 {
    top: 1733px;
  }
  .brandGuideBook,
  .download7,
  .ifYouWant {
    position: absolute;
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .download7 {
    top: 1491px;
    left: 878px;
    text-decoration: underline;
    color: var(--color-white);
  }
  .brandGuideBook,
  .ifYouWant {
    top: 2218px;
    left: 0;
    font-size: var(--font-size-15xl);
    text-align: left;
  }
  .ifYouWant {
    top: 2287px;
    font-size: 17px;
    line-height: 28px;
    font-family: var(--font-inter);
    display: inline-block;
    width: 980px;
  }
  .icon1,
  .icon2,
  .icon3,
  .icon4 {
    position: absolute;
    top: 1895px;
    left: 560px;
    width: 390px;
    height: 102px;
    overflow: hidden;
  }
  .icon2,
  .icon3,
  .icon4 {
    top: 1861px;
    left: 140px;
    width: 209.8px;
    height: 169.52px;
  }
  .icon3,
  .icon4 {
    top: 818px;
    left: 76px;
    width: 339.15px;
    height: 48.45px;
  }
  .icon4 {
    top: 1370px;
    left: 86px;
  }
  .maskGroupIcon {
    position: absolute;
    top: 2361px;
    left: 2px;
    width: 490px;
    height: 280px;
    object-fit: cover;
  }
  .download9 {
    position: absolute;
    top: 2597px;
    left: 368px;
    text-decoration: underline;
    letter-spacing: -0.02em;
    line-height: 47px;
  }
  .mediaKit {
    position: relative;
    width: 100%;
    height: 2779px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .presskit800Child {
    position: absolute;
    left: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 340px;
  }
  .exploreTheWorld {
    position: absolute;
    top: 211px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #d5d5d5;
    display: inline-block;
    width: 496px;
  }
  .kit {
    font-family: var(--font-bodoni-72);
  }
  .pressKit {
    top: 100px;
    font-size: 60px;
    color: var(--color-white);
  }
  .allYourMedia,
  .pressKit,
  .signitureLogo {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .allYourMedia {
    top: 164px;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .signitureLogo {
    top: 503px;
    font-size: 28px;
    line-height: 35px;
    font-family: var(--font-inter);
  }
  .aLogoCan {
    margin: 0;
  }
  .aLogoCanContainer,
  .brandIdentity {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .aLogoCanContainer {
    top: 570px;
    font-size: var(--font-size-mid);
    line-height: 28px;
    font-family: var(--font-inter);
    display: inline-block;
    width: 800px;
    height: 41px;
  }
  .brandIdentity {
    top: 381px;
    font-size: 34px;
    line-height: 47px;
    text-align: center;
  }
  .presskit800Child1,
  .presskit800Inner,
  .presskit800Item,
  .rectangleDiv {
    position: absolute;
    top: 643px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: var(--color-white);
    width: 390px;
    height: 280px;
  }
  .presskit800Child1,
  .presskit800Inner,
  .rectangleDiv {
    top: 1643px;
  }
  .presskit800Child1,
  .rectangleDiv {
    top: 1143px;
    left: 410px;
    background-color: var(--color-black);
  }
  .presskit800Child1 {
    top: 1643px;
    background-color: var(--color-white);
  }
  .download {
    position: absolute;
    top: 880px;
    left: 268px;
    text-decoration: underline;
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .download1 {
    top: 1380px;
    left: 268px;
  }
  .download1,
  .download2,
  .download3,
  .download4 {
    position: absolute;
    text-decoration: underline;
    letter-spacing: -0.02em;
    line-height: 47px;
    text-align: center;
  }
  .download2 {
    top: 1880px;
    left: 268px;
  }
  .download3,
  .download4 {
    left: 678px;
  }
  .download3 {
    top: 1380px;
    color: var(--color-white);
  }
  .download4 {
    top: 1880px;
  }
  .aLogoCanContainer1,
  .characterLogo,
  .itCanBe,
  .monoLogo {
    position: absolute;
    top: 1003px;
    left: 0;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 35px;
    font-family: var(--font-inter);
  }
  .aLogoCanContainer1,
  .characterLogo,
  .itCanBe {
    top: 1503px;
  }
  .aLogoCanContainer1,
  .itCanBe {
    top: 1070px;
    font-size: var(--font-size-mid);
    line-height: 28px;
    display: inline-block;
    width: 800px;
    height: 41px;
  }
  .aLogoCanContainer1 {
    top: 1570px;
  }
  .groupIcon,
  .icon,
  .presskit800Child2 {
    position: absolute;
    top: 763px;
    left: 59px;
    width: 272px;
    height: 39px;
  }
  .icon,
  .presskit800Child2 {
    top: 1263px;
    width: 271px;
  }
  .icon {
    top: 1261px;
    left: 470px;
    overflow: hidden;
  }
  .presskit800Child3 {
    position: absolute;
    top: 1715px;
    left: 118px;
    width: 168px;
    height: 136px;
  }
  .presskit800Child4 {
    position: absolute;
    top: 1743px;
    left: 449px;
    width: 312px;
    height: 81px;
  }
  .presskit800 {
    position: relative;
    width: 100%;
    height: 2615px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .brandguidebook {
    position: absolute;
    top: 2000px;
    color: #000;
    font-family: Geomanist;
    font-size: 34px;
    font-style: normal;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 47px; /* 138.235% */
    letter-spacing: -0.68px;
  }
  .brandguidebooktext {
    position: absolute;
    top: 2070px;
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 164.706% */
    letter-spacing: -0.34px;
  }
  .brandguidebookimg {
    position: absolute;
    top: 2170px;
    width: 492px;
  }
  .download5 {
    position: absolute;
    top: 2390px;
    left: 368px;
    text-decoration: underline;
    letter-spacing: -0.02em;
    line-height: 47px;
  }
}
