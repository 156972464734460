@media screen and (min-width: 1020px) {
  .hippocratIsNot,
  .hippocratWithP2p,
  .theExistenceOf,
  .whyP2pProtocol {
    position: absolute;
    top: 1614px;
    left: 511px;
    line-height: 28px;
    display: inline-block;
    width: 488px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .hippocratWithP2p,
  .theExistenceOf,
  .whyP2pProtocol {
    top: 1130px;
  }
  .hippocratWithP2p,
  .whyP2pProtocol {
    top: 1614px;
    left: 0;
    font-size: var(--font-size-28xl);
    letter-spacing: -0.02em;
    line-height: 50px;
    width: 473px;
    height: 84px;
    font-family: var(--font-geomanist);
  }
  .hippocratWithP2p {
    font-weight: 700;
  }
  .whyP2pProtocol {
    top: 1130px;
    width: 490px;
    font-weight: 700;
  }
  .learn01Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1034px;
  }
  .definitionOfP2p,
  .protocolIsPreDefined {
    position: absolute;
    top: 687px;
    left: 510px;
    line-height: 28px;
    color: var(--color-white);
    display: inline-block;
    width: 489px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .definitionOfP2p {
    left: 0;
    font-size: var(--font-size-28xl);
    letter-spacing: -0.02em;
    line-height: 50px;
    width: 490px;
    height: 84px;
    font-weight: 700;
    font-family: var(--font-geomanist);
  }
  .protocol {
    font-family: var(--font-bodoni-72);
  }
  .protocol400 {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .p2pProtocol,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .p2pProtocol {
    top: 100px;
    font-size: 68px;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .theComingFuture {
    top: 167px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn01Item {
    position: absolute;
    top: 243px;
    left: calc(50% - 500px);
    border-radius: var(--br-11xl);
    background-color: #d9d9d9;
    width: 1000px;
    height: 400px;

    img {
      width: 100%;
      border-radius: var(--br-11xl);
    }
  }
  .learn01Inner {
    position: absolute;
    top: 1526.5px;
    left: calc(50% - 500.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 1001px;
    height: 1px;
  }
  .nextUseCaseContainer,
  .p2pProtocol1 {
    position: absolute;
    left: 613px;
    letter-spacing: -0.02em;
  }
  .nextUseCaseContainer {
    top: 1938px;
    font-size: 34px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .p2pProtocol1 {
    top: 1987px;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 1980px;
    left: 881px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    object-fit: contain;
  }
  .previousUseCaseContainer {
    position: absolute;
    top: 1938px;
    left: 170px;
    font-size: 34px;
    letter-spacing: -0.02em;
  }
  .previousUseCaseContainer {
    position: absolute;
    top: 1938px;
    left: 170px;
    font-size: 34px;
    letter-spacing: -0.02em;
  }
  .healthLevel7 {
    position: absolute;
    top: 1987px;
    left: 321px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 1981px;
    left: 85px;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .learn01 {
    position: relative;
    width: 100%;
    height: 2228px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .hippocratIsNot,
  .theExistenceOf {
    position: absolute;
    top: 1466px;
    left: 411px;
    line-height: 25px;
    display: inline-block;
    width: 373px;
    font-size: 14px;
  }
  .theExistenceOf {
    top: 1003px;
  }
  .hippocratWithP2p,
  .whyP2pProtocol {
    position: absolute;
    left: 15px;
    font-size: var(--font-size-23xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    display: inline-block;
    width: 375px;
  }
  .hippocratWithP2p {
    top: 1466px;
    height: 84px;
  }
  .whyP2pProtocol {
    top: 1003px;
  }
  .learn800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 907px;
  }
  .definitionOfP2p,
  .protocolIsPreDefined {
    position: absolute;
    top: 683px;
    left: 411px;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 374px;
    font-size: 14px;
  }
  .definitionOfP2p {
    top: 684px;
    left: 15px;
    font-size: var(--font-size-23xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    width: 375px;
  }
  .protocol {
    font-family: var(--font-bodoni-72);
  }
  .p2pProtocol,
  .theComingFuture {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .p2pProtocol {
    top: 100px;
    font-size: 60px;
    color: var(--color-white);
  }
  .theComingFuture {
    top: 164px;
    font-size: var(--font-size-lg);
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn800Item,
  .maskGroupIcon {
    position: absolute;
    top: 240px;
    height: 400px;
  }
  .learn800Item {
    left: calc(50% - 400px);
    border-radius: var(--br-11xl);
    background-color: #d9d9d9;
    width: 800px;
  }
  .maskGroupIcon {
    left: calc(50% - 400px);
    width: 800px;
    object-fit: cover;
    border-radius: var(--br-11xl);
  }
  .learn800Inner {
    position: absolute;
    top: 1378.5px;
    left: calc(50% - 400.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 801px;
    height: 1px;
  }
  .previousUseCaseContainer {
    position: absolute;
    top: 1777px;
    left: 114px;
    font-size: 34px;
    letter-spacing: -0.02em;
  }
  .healthLevel7 {
    position: absolute;
    top: 1826px;
    left: 265px;
    font-size: var(--font-size-lg);
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 1820px;
    left: 29px;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .learn800 {
    position: relative;
    width: 100%;
    height: 2029px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
