@media screen and (max-width: 819px) and (min-width: 500px) {
  .dao500Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 533px;
  }
  .o {
    font-family: var(--font-bodoni-72);
  }
  .dao {
    top: 75px;
    left: 204px;
    font-size: var(--font-size-26xl);
  }
  .dao,
  .hippocratDaoStrives,
  .learnAboutThis {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .learnAboutThis {
    top: 137px;
    left: 95px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen-200);
  }
  .hippocratDaoStrives {
    top: 214px;
    left: calc(50% - 207px);
    font-size: 32px;
    line-height: 46px;
    display: inline-block;
    width: 414px;
  }
  .theGovernance {
    margin: 0;
  }
  .process {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theGovernanceProcessContainer1 {
    width: 100%;
  }
  .theGovernanceProcessContainer {
    position: absolute;
    top: 666px;
    left: 0;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.02em;
    color: var(--color-black);
    display: flex;
    align-items: center;
    width: 413px;
  }
  .dao500Inner,
  .dao500Item,
  .rectangleDiv {
    position: absolute;
    top: 846px;
    left: calc(50% - 250px);
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 500px;
    height: 394px;
  }
  .dao500Inner,
  .rectangleDiv {
    top: 1263px;
  }
  .rectangleDiv {
    top: 1680px;
  }
  .toSecureRegionalContainer,
  .toVerifyTheContainer {
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    height: 67px;
  }
  .toSecureRegionalContainer {
    position: absolute;
    top: 984px;
    left: 43px;
    letter-spacing: -0.02em;
    width: 410px;
  }
  .toVerifyTheContainer {
    top: 1401px;
    left: 42px;
    width: 412px;
  }
  .phase1,
  .toDisableUsageContainer,
  .toVerifyTheContainer {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .toDisableUsageContainer {
    top: 1818px;
    left: 43px;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 414px;
    height: 67px;
  }
  .phase1 {
    top: 7px;
    left: 14.86px;
    display: flex;
    align-items: center;
    width: 43.58px;
  }
  .groupChild,
  .phase1Parent {
    position: absolute;
    width: 74.28px;
    height: 28px;
  }
  .groupChild {
    top: 0;
    left: 0;
    border-radius: var(--br-sm);
    border: 1px solid var(--color-white);
    box-sizing: border-box;
  }
  .phase1Parent {
    top: 880px;
    left: 43px;
    font-size: var(--font-size-sm);
  }
  .phase2 {
    position: absolute;
    top: 7px;
    left: 15px;
    letter-spacing: -0.02em;
  }
  .groupItem,
  .phase2Parent,
  .phase3Parent {
    position: absolute;
    width: 75px;
    height: 28px;
  }
  .groupItem {
    top: 0;
    left: 0;
    border-radius: var(--br-sm);
    border: 1px solid var(--color-white);
    box-sizing: border-box;
  }
  .phase2Parent,
  .phase3Parent {
    top: 1297px;
    left: 42px;
    font-size: var(--font-size-sm);
  }
  .phase3Parent {
    top: 1712px;
    left: 43px;
  }
  .exRecruitingDaoParticipati {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: var(--padding-mid);
  }
  .exOneYearContainer,
  .exRecruitingDaoContainer,
  .exRecruitingDaoContainer1 {
    position: absolute;
    top: 1102px;
    left: 43px;
    font-size: var(--font-size-smi);
    letter-spacing: -0.02em;
    line-height: 20px;
    font-family: var(--font-inter);
    color: var(--color-gainsboro-100);
    display: inline-block;
    width: 414px;
  }
  .exOneYearContainer,
  .exRecruitingDaoContainer1 {
    top: 1519px;
    left: 42px;
    width: 415px;
  }
  .exRecruitingDaoContainer1 {
    top: 1936px;
    left: 39px;
    width: 418px;
  }
  .asclepiosStance,
  .hippocratesStance,
  .keironStance {
    position: absolute;
    top: 930px;
    left: 43px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen-200);
  }
  .hippocratesStance,
  .keironStance {
    top: 1347px;
    left: 42px;
  }
  .hippocratesStance {
    top: 1764px;
    left: 43px;
    display: flex;
    align-items: center;
    width: 284px;
  }
  .dao500Child1 {
    position: absolute;
    top: 2181px;
    left: 500px;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 1492px;
    height: 499px;
    transform: rotate(90deg);
    transform-origin: 0 0;
  }
  .image71Icon {
    position: absolute;
    top: 2873px;
    left: 59px;
    width: 383px;
    height: 322px;
    object-fit: cover;
  }
  .dao500Child2,
  .image70Icon,
  .vectorIcon {
    position: absolute;
    top: 2686px;
    left: 221px;
    width: 59px;
    height: 21px;
    object-fit: contain;
  }
  .dao500Child2,
  .image70Icon {
    top: 3215px;
  }
  .image70Icon {
    top: 2333px;
    left: 46px;
    width: 408px;
    height: 340px;
    object-fit: cover;
  }
  .dao1,
  .treasuryWg {
    position: absolute;
    top: 2367px;
    left: 197px;
    letter-spacing: -0.02em;
    color: var(--color-limegreen-100);
  }
  .dao1 {
    top: 2276px;
    left: 43px;
    font-size: var(--font-size-11xl);
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
  }
  .appsWg,
  .compilanceSig,
  .policySig,
  .techWg {
    position: absolute;
    top: 2557px;
    left: 215px;
    letter-spacing: -0.02em;
    color: var(--color-limegreen-100);
  }
  .appsWg,
  .compilanceSig,
  .policySig {
    top: 2623px;
    left: 210px;
  }
  .appsWg,
  .compilanceSig {
    top: 2430px;
  }
  .compilanceSig {
    top: 2493px;
    left: 186px;
  }
  .governanceProtocol,
  .phase1Group {
    position: absolute;
    top: 2223px;
    left: 43px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .governanceProtocol {
    top: 2805px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 327px;
    height: 25px;
  }
  .dao500Child3 {
    position: absolute;
    top: 3435px;
    left: 297px;
    width: 84px;
    height: 82px;
  }
  .bitcoin,
  .github {
    position: absolute;
    top: 3527px;
    left: 286px;
    letter-spacing: -0.02em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 106.44px;
  }
  .github {
    top: 3529px;
    left: 107px;
  }
  .dao500Child5 {
    position: absolute;
    top: 3436px;
    left: 119px;
    width: 84px;
    height: 84px;
  }
  .phase2Group {
    position: absolute;
    top: 2752px;
    left: 43px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .softwareProtocol {
    position: absolute;
    top: 3334px;
    left: 43px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 328px;
  }
  .phase3Group {
    position: absolute;
    top: 3281px;
    left: 44px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .execute,
  .informalDiscussion,
  .roughConsensus {
    position: absolute;
    top: 2912px;
    left: 165px;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    color: var(--color-black);
    text-align: center;
  }
  .execute,
  .roughConsensus {
    top: 3020px;
    left: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 187px;
  }
  .execute {
    top: 3130px;
    left: 206px;
    width: 79.07px;
  }
  .dao500 {
    position: relative;
    width: 100%;
    height: 3736px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    margin-top: -500px;
    img {
      pointer-events: none;
    }
  }
}
@media screen and (max-width: 500px) {
  .dao360Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 533px;
  }
  .o {
    font-family: var(--font-bodoni-72);
  }
  .dao {
    top: 79px;
    left: calc(50% - 180px);
    width: 360px;

    font-size: var(--font-size-16xl);
  }
  .dao,
  .hippocratDaoStrives,
  .learnAboutThis {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .learnAboutThis {
    top: 137px;
    left: calc(50% - 180px);
    width: 360px;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen-200);
  }
  .hippocratDaoStrives {
    top: 214px;
    left: calc(50% - 180px);
    font-size: 28px;
    line-height: 40px;
    display: inline-block;
    width: 360px;
  }
  .theGovernance {
    margin: 0;
  }
  .process {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .theGovernanceProcessContainer1 {
    width: 100%;
  }
  .theGovernanceProcessContainer {
    position: absolute;
    top: 653px;
    left: 0;
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    color: var(--color-black);
    display: flex;
    align-items: center;
    width: 359px;
  }
  .dao360Inner,
  .dao360Item,
  .rectangleDiv {
    position: absolute;
    top: 802px;
    left: calc(50% - 179px);
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 359px;
    height: 394px;
  }
  .dao360Inner,
  .rectangleDiv {
    top: 1219px;
    left: calc(50% - 180px);
    width: 360px;
  }
  .rectangleDiv {
    top: 1636px;
  }
  .toSecureRegionalContainer,
  .toVerifyTheContainer {
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    height: 67px;
  }
  .toSecureRegionalContainer {
    position: absolute;
    top: 940px;
    left: calc(50% - 180px);
    width: 360px;
    margin-left: 30px;
    letter-spacing: -0.02em;
    width: 297px;
  }
  .toVerifyTheContainer {
    top: 1357px;
    left: calc(50% - 180px);
    width: 360px;
    margin-left: 30px;
    width: 298px;
  }
  .phase1,
  .toDisableUsageContainer,
  .toVerifyTheContainer {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .toDisableUsageContainer {
    top: 1774px;
    left: calc(50% - 180px);
    width: 360px;
    margin-left: 30px;
    line-height: 25px;
    font-weight: 500;
    display: inline-block;
    width: 297px;
    height: 67px;
  }
  .phase1 {
    top: 7px;
    left: 14.86px;
    display: flex;
    align-items: center;
    width: 43.58px;
  }
  .groupChild,
  .phase1Parent {
    position: absolute;
    width: 74.28px;
    height: 28px;
  }
  .groupChild {
    top: 0;
    left: 0;
    border-radius: var(--br-sm);
    border: 1px solid var(--color-white);
    box-sizing: border-box;
  }
  .phase1Parent {
    top: 836px;
    left: calc(50% - 180px);
    width: 360px;
    margin-left: 30px;
    padding-left: 30px;
    font-size: var(--font-size-sm);
  }
  .phase11 {
    position: absolute;
    top: 7px;
    left: 15px;
    letter-spacing: -0.02em;
  }
  .groupItem {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-sm);
    border: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 75px;
    height: 28px;
  }
  .phase1Container,
  .phase1Group {
    position: absolute;
    top: 1253px;
    left: calc(50% - 180px);
    width: 360px;
    margin-left: 30px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .phase1Container {
    top: 1668px;
    left: 0;
  }
  .exRecruitingDaoParticipati {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: var(--padding-mid);
  }
  .exOneYearContainer,
  .exRecruitingDaoContainer,
  .exRecruitingDaoContainer1 {
    position: absolute;
    top: 1058px;
    left: calc(50% - 180px);
    width: 360px;
    margin-left: 30px;
    font-size: var(--font-size-smi);
    letter-spacing: -0.02em;
    line-height: 20px;
    font-family: var(--font-inter);
    color: var(--color-gainsboro);
    display: inline-block;
    width: 297px;
  }
  .exOneYearContainer,
  .exRecruitingDaoContainer1 {
    top: 1475px;
    left: 0;
    width: 298px;
  }
  .exRecruitingDaoContainer1 {
    top: 1892px;
    left: 0;
    width: 301px;
  }
  .asclepiosStance,
  .hippocratesStance,
  .keironStance {
    position: absolute;
    top: 886px;
    left: calc(50% - 180px);
    width: 360px;
    margin-left: 30px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: var(--color-limegreen-200);
  }
  .hippocratesStance,
  .keironStance {
    top: 1303px;
    left: 0;
  }
  .hippocratesStance {
    top: 1720px;
    left: 0;
    display: flex;
    align-items: center;
    width: 284px;
  }
  .dao360Child1 {
    position: absolute;
    top: 2137px;
    border-radius: var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1492px;
  }
  .editdaoIcon,
  .editdaoIcon1 {
    position: absolute;
    top: 2817px;
    left: calc(50% - 131px);
    width: 262px;
    height: 346px;
    object-fit: cover;
  }
  .editdaoIcon1 {
    top: 2286px;
    left: calc(50% - 133px);
    width: 266px;
    height: 351px;
  }
  .dao360Child2,
  .vectorIcon {
    position: absolute;
    top: 2642px;
    left: calc(50% - 30px);
    width: 59px;
    height: 21px;
    object-fit: contain;
  }
  .dao360Child2 {
    top: 3171px;
    left: calc(50% - 30px);
  }
  .dao1,
  .treasuryWg {
    position: absolute;
    top: 2327px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--color-limegreen-100);
  }
  .dao1 {
    top: 2232px;
    left: 31px;
    font-size: var(--font-size-11xl);
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 250px;
    height: 25px;
    text-align: left;
  }
  .appsWg,
  .compilanceSig,
  .policySig,
  .techWg {
    position: absolute;
    top: 2516px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--color-limegreen-100);
  }
  .appsWg,
  .compilanceSig,
  .policySig {
    top: 2579px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
  }
  .appsWg,
  .compilanceSig {
    top: 2390px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
  }
  .compilanceSig {
    top: 2453px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
  }
  .groupDiv {
    position: absolute;
    top: 2179px;
    left: 31px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .bitcoin,
  .github,
  .governanceProtocol {
    position: absolute;
    letter-spacing: -0.02em;
  }
  .governanceProtocol {
    top: 2761px;
    left: 31px;
    font-size: var(--font-size-11xl);
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 327px;
    height: 25px;
  }
  .bitcoin,
  .github {
    top: 3483px;
    left: 174px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 106.44px;
  }
  .github {
    top: 3485px;
    left: 79px;
  }
  .phase2Parent {
    position: absolute;
    top: 2708px;
    left: 31px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .softwareProtocol {
    position: absolute;
    top: 3290px;
    left: 31px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    color: var(--color-limegreen-200);
    display: inline-block;
    width: 328px;
  }
  .phase3Parent {
    position: absolute;
    top: 3237px;
    left: 32px;
    width: 75px;
    height: 28px;
    font-size: var(--font-size-sm);
  }
  .execute,
  .informalDiscussion,
  .roughConsensus {
    position: absolute;
    top: 2864px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
    font-size: var(--font-size-xl);
    letter-spacing: -0.02em;
    color: var(--color-black);
    text-align: center;
  }
  .execute,
  .roughConsensus {
    top: 2976px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .execute {
    top: 3086px;
    left: calc(50% - 180px);
    width: 360px;
    text-align: center;
  }
  .bitcoin2Icon,
  .githubMarkWhite2Icon {
    position: absolute;
    top: 3390px;
    left: 90px;
    width: 86px;
    height: 84px;
    object-fit: cover;
  }
  .bitcoin2Icon {
    left: 186px;
    width: 85px;
    height: 85px;
  }
  .dao360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 3736px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-white);
    font-family: var(--font-geomanist);
    margin-top: -500px;
    img {
      pointer-events: none;
    }
  }
  .bitcoinWrapper {
    position: absolute;
    left: calc(50% - 180px);
    width: 360px;
  }
}
