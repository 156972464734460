.icon_menu {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8ZyBpZD0ibWVudS0wMSI+Cgk8cGF0aCBpZD0iSWNvbiIgY2xhc3M9InN0MCIgZD0iTTMsMTJoMTggTTMsNmgxOCBNMywxOGgxOCIvPgo8L2c+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
}

.icon_delete {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MS41O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJJY29uIiBjbGFzcz0ic3QwIiBkPSJNMTcsN0w3LDE3IE03LDdsMTAsMTAiLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
}

.icon_mail_on {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzI3QjE1NjtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8ZyBpZD0ibWFpbC0wMSI+Cgk8cGF0aCBpZD0iSWNvbiIgY2xhc3M9InN0MCIgZD0iTTIsN2w4LjIsNS43YzAuNywwLjUsMSwwLjcsMS40LDAuOGMwLjMsMC4xLDAuNiwwLjEsMSwwczAuNy0wLjMsMS40LTAuOEwyMiw3IE02LjgsMjBoMTAuNAoJCWMxLjcsMCwyLjUsMCwzLjItMC4zYzAuNi0wLjMsMS0wLjcsMS4zLTEuM2MwLjMtMC42LDAuMy0xLjUsMC4zLTMuMlY4LjhjMC0xLjcsMC0yLjUtMC4zLTMuMmMtMC4zLTAuNi0wLjctMS0xLjMtMS4zCgkJQzE5LjcsNCwxOC45LDQsMTcuMiw0SDYuOEM1LjEsNCw0LjMsNCwzLjYsNC4zQzMsNC42LDIuNiw1LDIuMyw1LjZDMiw2LjMsMiw3LjEsMiw4Ljh2Ni40YzAsMS43LDAsMi41LDAuMywzLjIKCQljMC4zLDAuNiwwLjcsMSwxLjMsMS4zQzQuMywyMCw1LjEsMjAsNi44LDIweiIvPgo8L2c+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
}

.icon_mail_off {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I0JGQkZCRjtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8ZyBpZD0ibWFpbC0wMSI+Cgk8cGF0aCBpZD0iSWNvbiIgY2xhc3M9InN0MCIgZD0iTTIsN2w4LjIsNS43YzAuNywwLjUsMSwwLjcsMS40LDAuOGMwLjMsMC4xLDAuNiwwLjEsMSwwYzAuNC0wLjEsMC43LTAuMywxLjQtMC44TDIyLDcgTTYuOCwyMGgxMC40CgkJYzEuNywwLDIuNSwwLDMuMi0wLjNjMC42LTAuMywxLTAuNywxLjMtMS4zYzAuMy0wLjYsMC4zLTEuNSwwLjMtMy4yVjguOGMwLTEuNywwLTIuNS0wLjMtMy4yYy0wLjMtMC42LTAuNy0xLTEuMy0xLjMKCQlDMTkuNyw0LDE4LjksNCwxNy4yLDRINi44QzUuMSw0LDQuMyw0LDMuNiw0LjNjLTAuNiwwLjMtMSwwLjctMS4zLDEuM0MyLDYuMywyLDcuMSwyLDguOHY2LjRjMCwxLjcsMCwyLjUsMC4zLDMuMgoJCWMwLjMsMC42LDAuNywxLDEuMywxLjNDNC4zLDIwLDUuMSwyMCw2LjgsMjB6Ii8+CjwvZz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
}

.icon_send {
    display: inline-block;
    width: 82px;
    height: 82px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgODIgODIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDgyIDgyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8ZyBpZD0ic2VuZC0wMSI+Cgk8cGF0aCBpZD0iSWNvbiIgY2xhc3M9InN0MCIgZD0iTTM1LjgsNDYuMkw3Mi4xLDkuOSBNMzYuMyw0Ny4zbDkuMSwyMy40YzAuOCwyLjEsMS4yLDMuMSwxLjgsMy40YzAuNSwwLjMsMS4xLDAuMywxLjYsMAoJCWMwLjYtMC4zLDEtMS4zLDEuOC0zLjRsMjIuOC01OC40YzAuNy0xLjksMS4xLTIuOCwwLjktMy40Qzc0LDguNCw3My42LDgsNzMuMSw3LjhjLTAuNi0wLjItMS41LDAuMi0zLjQsMC45TDExLjMsMzEuNQoJCWMtMi4xLDAuOC0zLjEsMS4yLTMuNCwxLjhjLTAuMywwLjUtMC4zLDEuMSwwLDEuNmMwLjMsMC42LDEuMywxLDMuNCwxLjhsMjMuNCw5LjFjMC40LDAuMiwwLjYsMC4yLDAuOCwwLjQKCQljMC4yLDAuMSwwLjMsMC4yLDAuNCwwLjRDMzYsNDYuNywzNi4xLDQ2LjksMzYuMyw0Ny4zeiIvPgo8L2c+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
}

.icon_logout {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MS41O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNSwxNS44bDMuOC0zLjggTTE4LjgsMTJMMTUsOC4yIE0xOC44LDEyaC05IE0xMiwxNS44YzAsMC43LDAsMS0wLjEsMS4zYy0wLjIsMC44LTAuOCwxLjQtMS42LDEuNgoJQzEwLDE4LjgsOS43LDE4LjgsOSwxOC44SDguNmMtMSwwLTEuNiwwLTItMC4yYy0wLjYtMC4yLTEtMC43LTEuMi0xLjJjLTAuMi0wLjQtMC4yLTAuOS0wLjItMlY4LjZjMC0xLDAtMS42LDAuMi0yCgljMC4yLTAuNiwwLjctMSwxLjItMS4yYzAuNC0wLjIsMC45LTAuMiwyLTAuMkg5YzAuNywwLDEsMCwxLjMsMC4xYzAuOCwwLjIsMS40LDAuOCwxLjYsMS42QzEyLDcuMiwxMiw3LjYsMTIsOC4yIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
}

.icon_history {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MS41O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xOS41LDExLjNWMTJjMCwxLjYtMC41LDMuMi0xLjUsNC41Yy0xLDEuMy0yLjMsMi4yLTMuOSwyLjdjLTEuNiwwLjUtMy4yLDAuNC00LjctMC4ycy0yLjgtMS42LTMuNy0zCglzLTEuMy0zLTEuMi00LjZjMC4xLTEuNiwwLjctMy4xLDEuOC00LjRzMi41LTIuMSw0LTIuNGMxLjYtMC40LDMuMi0wLjIsNC43LDAuNSBNMTkuNSw2TDEyLDEzLjVsLTIuMi0yLjIiLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
}

.icon_answer {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MS41O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMy41LDQuN3YzLjFjMCwwLjQsMCwwLjYsMC4xLDAuOGMwLjEsMC4xLDAuMiwwLjMsMC4zLDAuM0MxNC4xLDksMTQuMyw5LDE0LjcsOWgzLjEgTTE1LDEyLjhIOSBNMTUsMTUuOEg5CgkgTTEwLjUsOS44SDkgTTEzLjUsNC41SDkuNmMtMS4zLDAtMS45LDAtMi40LDAuMmMtMC40LDAuMi0wLjgsMC42LTEsMUM2LDYuMiw2LDYuOCw2LDguMXY3LjhjMCwxLjMsMCwxLjksMC4yLDIuNAoJYzAuMiwwLjQsMC42LDAuOCwxLDFjMC41LDAuMiwxLjEsMC4yLDIuNCwwLjJoNC44YzEuMywwLDEuOSwwLDIuNC0wLjJjMC40LTAuMiwwLjgtMC42LDEtMWMwLjItMC41LDAuMi0xLjEsMC4yLTIuNFY5TDEzLjUsNC41eiIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
}

.icon_edit_on {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzI3QjE1Njt9Cgkuc3Qxe2ZpbGw6bm9uZTtzdHJva2U6IzI3QjE1NjtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTIsMTguN2g3LjVIMTJ6Ii8+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik00LjUsMTcuM2MwLTAuNCwwLTAuNiwwLTAuOGMwLTAuMiwwLjEtMC4zLDAuMi0wLjVjMC4xLTAuMiwwLjItMC4zLDAuNS0wLjZMMTUuOCw0LjljMC43LTAuNywxLjgtMC43LDIuNSwwCgljMC43LDAuNywwLjcsMS44LDAsMi41TDcuOCwxNy45Yy0wLjMsMC4zLTAuNCwwLjQtMC42LDAuNWMtMC4xLDAuMS0wLjMsMC4yLTAuNSwwLjJjLTAuMiwwLTAuNCwwLTAuOCwwSDQuNVYxNy4zeiIvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTIsMTguN2g3LjUgTTQuNSwxOC43aDEuNGMwLjQsMCwwLjYsMCwwLjgsMGMwLjIsMCwwLjMtMC4xLDAuNS0wLjJjMC4yLTAuMSwwLjMtMC4yLDAuNi0wLjVMMTguMiw3LjQKCWMwLjctMC43LDAuNy0xLjgsMC0yLjVjLTAuNy0wLjctMS44LTAuNy0yLjUsMEw1LjMsMTUuNEM1LDE1LjcsNC44LDE1LjgsNC43LDE2Yy0wLjEsMC4xLTAuMiwwLjMtMC4yLDAuNWMwLDAuMiwwLDAuNCwwLDAuOFYxOC43eiIKCS8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
}

.icon_edit_off {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzI3QjE1NjtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTIsMjBoOSBNMywyMGgxLjdjMC41LDAsMC43LDAsMS0wLjFjMC4yLDAsMC40LTAuMSwwLjYtMC4yYzAuMi0wLjEsMC40LTAuMywwLjctMC42TDE5LjUsNi41CgljMC44LTAuOCwwLjgtMi4yLDAtM2MtMC44LTAuOC0yLjItMC44LTMsMEwzLjksMTYuMWMtMC4zLDAuMy0wLjUsMC41LTAuNiwwLjdjLTAuMSwwLjItMC4yLDAuNC0wLjIsMC42QzMsMTcuNiwzLDE3LjgsMywxOC4zVjIweiIKCS8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
}

.icon_check {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzI3QjE1NjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNOC42LDEybDIuMiwyLjJsNC41LTQuNSBNMTkuNSwxMmMwLDQuMS0zLjQsNy41LTcuNSw3LjVjLTQuMSwwLTcuNS0zLjQtNy41LTcuNWMwLTQuMSwzLjQtNy41LDcuNS03LjUKCUMxNi4xLDQuNSwxOS41LDcuOSwxOS41LDEyeiIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
}

.icon_users {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuNTU2IDE4di0xLjQ0NGEyLjg4OSAyLjg4OSAwIDAgMSAyLjg4OC0yLjg5aDUuNzc4YTIuODkgMi44OSAwIDAgMSAyLjg5IDIuODlWMThtLTUuNzc5LTcuMjIyYTIuODg5IDIuODg5IDAgMSAxIDAtNS43NzggMi44ODkgMi44ODkgMCAwIDEgMCA1Ljc3OFoiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
}

.icon_arrow {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fQo8L3N0eWxlPgo8ZyBpZD0iY2hldnJvbi1sZWZ0Ij4KCTxwYXRoIGlkPSJJY29uIiBjbGFzcz0ic3QwIiBkPSJNMTUsMThsLTYtNmw2LTYiLz4KPC9nPgo8L3N2Zz4K);
    background-repeat: no-repeat;
}