@media screen and (min-width: 1020px) {
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-slide {
    width: 100%;
  }

  .solutionChild {
    top: 2362px;
    background-color: #f46cbd;
  }
  .solutionChild,
  .solutionInner,
  .solutionItem {
    position: absolute;
    left: 0;
    width: 100%;
    height: 648px;
  }
  .solutionItem {
    top: 3010px;
    background-color: var(--color-cornflowerblue);
  }
  .solutionInner {
    top: 3658px;
    background-color: #f46cbd;
  }
  .personalizedHealthcareSoluti,
  .yourGatewayTo {
    position: absolute;
    left: calc(50% + 10px);
    letter-spacing: -0.02em;
  }
  .yourGatewayTo {
    top: 130px;
    font-size: var(--font-size-15xl);
    line-height: 47px;
    display: inline-block;
    width: 381px;
  }
  .personalizedHealthcareSoluti {
    top: 253px;
    line-height: 26px;
    text-transform: uppercase;
    color: #00d308;
  }
  .icon {
    position: absolute;
    top: 215px;
    left: 78px;
    width: 364px;
    height: 89px;
    overflow: hidden;
  }
  .developedByHippocrat {
    position: absolute;
    top: 297px;
    left: 510px;
    letter-spacing: -0.02em;
    line-height: 30px;
    color: var(--color-darkslategray);
    display: inline-block;
    width: 490px;
    font-size: 16px;
  }
  .alwaysAccessibleHealth {
    margin: 0;
  }
  .alwaysAccessibleHealthContainer,
  .hipposTechnology {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .alwaysAccessibleHealthContainer {
    top: 743px;
    left: calc(50% - 414px);
    font-size: var(--font-size-15xl);
    line-height: 47px;
    display: inline-block;
    width: 849px;
  }
  .hipposTechnology {
    top: 3202px;
    left: calc(50% - 313px);
    font-size: var(--font-size-49xl);
    transform: rotate(0.38deg);
    transform-origin: 0 0;
  }
  .rectangleDiv,
  .solutionChild1 {
    position: absolute;
    left: 0;
    background-color: var(--color-limegreen-200);
    width: 100%;
    height: 648px;
  }
  .rectangleDiv {
    top: 1066px;
    border-radius: var(--br-11xl) var(--br-11xl) 0 0;
  }
  .solutionChild1 {
    top: 1714px;
    background-color: var(--color-cornflowerblue);
  }
  .image34Icon {
    position: absolute;
    top: 4065px;
    left: 64px;
    border-radius: var(--br-11xl);
    width: 870px;
    height: 516px;
    object-fit: cover;
    pointer-events: none;
  }
  .hippoIsOnContainer,
  .joinTheMedical {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    text-align: center;
  }
  .joinTheMedical {
    top: 4200px;
    left: 165px;
    font-size: var(--font-size-49xl);
  }
  .hippoIsOnContainer {
    top: 4322px;
    left: 122px;
    font-size: 22px;
    line-height: 30px;
    display: inline-block;
    width: 754px;
    height: 76px;
  }
  .solutionChild2 {
    position: absolute;
    top: 5753px;
    left: 401px;
    border-radius: var(--br-980xl);
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 196px;
    height: 46px;
  }
  .letsJoin {
    position: absolute;
    top: 4458px;
    left: calc(50% - 99px);
    font-size: var(--font-size-base);
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .accessExpertMedical,
  .accessExpertMedical1,
  .youCanPay,
  .youCanPay1,
  .yourHealthInformation {
    position: absolute;
    top: 1375px;
    left: 510px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 490px;
    font-size: 16px;
  }
  .accessExpertMedical1,
  .youCanPay,
  .youCanPay1,
  .yourHealthInformation {
    top: 2023px;
  }
  .youCanPay,
  .youCanPay1,
  .yourHealthInformation {
    top: 2671px;
  }
  .youCanPay1,
  .yourHealthInformation {
    top: 3319px;
  }
  .yourHealthInformation {
    top: 3967px;
  }
  .cryptocurrencyPayments,
  .cryptocurrencyPayments1,
  .dataOwnership,
  .remoteConsultation,
  .remoteConsultation1 {
    position: absolute;
    top: 1301px;
    left: 510px;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
    line-height: 46px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .cryptocurrencyPayments,
  .cryptocurrencyPayments1,
  .dataOwnership,
  .remoteConsultation1 {
    top: 1949px;
  }
  .cryptocurrencyPayments,
  .cryptocurrencyPayments1,
  .dataOwnership {
    top: 2597px;
  }
  .cryptocurrencyPayments1,
  .dataOwnership {
    top: 3245px;
  }
  .dataOwnership {
    top: 3893px;
  }
  .sdk,
  .theHipposOriginal {
    position: absolute;
    left: 20px;
    line-height: 28px;
  }
  .theHipposOriginal {
    top: 3748px;
    display: inline-block;
    width: 450px;
    height: 69px;
    font-size: 16px;
  }
  .sdk {
    top: 3696px;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
  }
  .decentralizedDataTransmissio,
  .p2pStorage {
    position: absolute;
    left: 530px;
    line-height: 28px;
  }
  .p2pStorage {
    top: 3696px;
    font-size: var(--font-size-15xl);
    letter-spacing: -0.02em;
  }
  .decentralizedDataTransmissio {
    top: 3748px;
    display: inline-block;
    width: 450px;
    height: 69px;
    font-size: 16px;
  }
  .solutionChild3,
  .solutionChild4 {
    position: absolute;
    top: 3850px;
    left: 530px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen-100);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .solutionChild4 {
    left: 20px;
  }
  .readMore,
  .readMore1 {
    position: absolute;
    top: 3850px;
    left: 20px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IiMxOUQ5NTkiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .readMore1 {
    left: 530px;
  }
  .solutionChild5 {
    position: absolute;
    top: 3366px;
    left: 510px;
    border-radius: var(--br-11xl);
    background-color: #f6f8f9;
    width: 490px;
    height: 280px;
  }
  .maskGroupIcon,
  .maskGroupIcon2 {
    position: absolute;
    top: 1066px;
    left: 0;
    width: 478px;
    height: 648px;
    object-fit: cover;
  }
  .maskGroupIcon1 {
    position: absolute;
    top: 1714px;
    left: 0;
    width: 478px;
    height: 648px;
    object-fit: cover;
  }
  .maskGroupIcon1_1 {
    position: absolute;
    top: 2363px;
    left: 0;
    width: 478px;
    height: 648px;
    object-fit: cover;
    pointer-events: none;
  }
  .maskGroupIcon2 {
    top: 3010px;
    left: -1px;
  }
  .solutionChild6,
  .solutionChild7 {
    position: absolute;
    top: 0;
    left: 512px;
    background-color: var(--color-red);
    width: 488px;
    height: 498px;
  }
  .solutionChild7 {
    top: 498px;
    left: 0;
    width: 1000px;
    height: 568px;
  }
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5 {
    position: absolute;
    top: 3366px;
    left: 510px;
    width: 490px;
    height: 280px;
    object-fit: cover;
  }
  .maskGroupIcon4,
  .maskGroupIcon5 {
    left: 0;
    object-fit: contain;
  }
  .maskGroupIcon3,
  .maskGroupIcon4 {
    border-radius: 30px;
    pointer-events: none;
  }
  .maskGroupIcon5 {
    top: 3658px;
    left: -1px;
    width: 478px;
    height: 648px;
  }
  .solution {
    position: relative;
    width: 100%;
    height: 4753px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
  .earthVideo {
    position: absolute;
    top: 265px;
    left: calc(50% - 500px);
  }
  img {
    -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
    -moz-user-select: none; /* 파이어폭스 */
    -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
    user-select: none; /* 표준 문법 */
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .solutionChild,
  .solutionItem {
    position: absolute;
    top: 2362px;
    left: 0;
    background-color: var(--color-cornflowerblue);
    width: 100%;
    height: 648px;
    background-color: #f46cbd;
  }
  .solutionItem {
    top: 3010px;
  }
  .maskGroupIcon,
  .solutionInner {
    position: absolute;
    top: 3658px;
    height: 648px;
  }
  .solutionInner {
    left: 0;
    background-color: #f46cbd;
    width: 100%;
  }
  .maskGroupIcon {
    left: 0;
    width: 478px;
    object-fit: contain;
  }
  .personalizedHealthcareSoluti,
  .yourGatewayTo {
    position: absolute;
    left: calc(50% + 10px);
    letter-spacing: -0.02em;
  }
  .yourGatewayTo {
    top: 130px;
    font-size: var(--font-size-13xl);
    line-height: 46px;
    display: inline-block;
    width: 390px;
  }
  .personalizedHealthcareSoluti {
    top: 253px;
    font-size: var(--font-size-lg);
    line-height: 26px;
    text-transform: uppercase;
    color: #00d308;
  }
  .icon {
    position: absolute;
    top: 208px;
    left: 0;
    width: 364px;
    height: 89px;
    overflow: hidden;
  }
  .developedByHippocrat,
  .svg {
    position: absolute;
    top: 298px;
    left: 410px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: var(--color-darkslategray);
    display: inline-block;
    width: 390px;
    font-size: 14px;
  }
  .svg {
    top: 337px;
    left: 124px;
    font-size: var(--font-size-lg);
    line-height: 30px;
    width: 82px;
    height: 73px;
  }
  .alwaysAccessibleHealth {
    margin: 0;
  }
  .alwaysAccessibleHealthContainer,
  .hipposTechnology {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .alwaysAccessibleHealthContainer {
    top: 748px;
    left: calc(50% - 356px);
    font-size: 34px;
    line-height: 47px;
    display: inline-block;
    width: 733px;
    height: 166px;
  }
  .hipposTechnology {
    top: 3202px;
    left: calc(50% - 277px);
    font-size: 60px;
    transform: rotate(0.38deg);
    transform-origin: 0 0;
  }
  .rectangleDiv,
  .solutionChild1 {
    position: absolute;
    left: 0;
    background-color: var(--color-limegreen-200);
    width: 100%;
    height: 648px;
  }
  .rectangleDiv {
    top: 1066px;
    border-radius: var(--br-11xl) var(--br-11xl) 0 0;
  }
  .solutionChild1 {
    top: 1714px;
    background-color: var(--color-cornflowerblue);
  }
  .image34Icon,
  .maskGroupIcon1,
  .maskGroupIcon2 {
    position: absolute;
    top: 2362px;
    left: 0;
    width: 478px;
    height: 648px;
    object-fit: cover;
  }
  .image34Icon,
  .maskGroupIcon2 {
    top: 1714px;
    left: 0;
    object-fit: contain;
  }
  .image34Icon {
    top: 4065px;
    left: 48px;
    border-radius: var(--br-11xl);
    width: 704px;
    height: 516px;
    object-fit: cover;
    pointer-events: none;
  }
  .hippoIsOnContainer,
  .joinTheMedical {
    position: absolute;
    top: 4182px;
    left: 189px;
    font-size: 43px;
    letter-spacing: -0.02em;
    line-height: 55px;
    color: var(--color-white);
    text-align: center;
  }
  .hippoIsOnContainer {
    top: 4267px;
    left: 137px;
    font-size: 22px;
    line-height: 30px;
    display: inline-block;
    width: 526px;
  }
  .solutionChild2 {
    position: absolute;
    top: 5753px;
    left: 302px;
    border-radius: var(--br-980xl);
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 196px;
    height: 46px;
  }
  .letsJoin {
    position: absolute;
    top: 4458px;
    left: calc(50% - 99px);
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .accessExpertMedical,
  .accessExpertMedical1 {
    position: absolute;
    top: 1372px;
    left: 410px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 390px;
    font-size: 14px;
  }
  .accessExpertMedical1 {
    top: 2020px;
  }
  .youCanPay {
    top: 2668px;
    font-size: 14px;
    line-height: 30px;
  }
  .dataManagementSystem,
  .youCanPay,
  .youCanPay1 {
    position: absolute;
    left: 410px;
    letter-spacing: -0.02em;
    display: inline-block;
    width: 390px;
  }
  .youCanPay1 {
    top: 3316px;
    font-size: var(--font-size-lg);
    line-height: 30px;
  }
  .dataManagementSystem {
    top: 3964px;
    line-height: 25px;
  }
  .cryptocurrencyPayments,
  .cryptocurrencyPayments1,
  .remoteConsultation,
  .remoteConsultation1 {
    position: absolute;
    top: 1301px;
    left: 410px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    line-height: 37px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .cryptocurrencyPayments,
  .cryptocurrencyPayments1,
  .remoteConsultation1 {
    top: 1949px;
  }
  .cryptocurrencyPayments,
  .cryptocurrencyPayments1 {
    top: 2597px;
  }
  .cryptocurrencyPayments1 {
    top: 3245px;
  }
  .dataOwnership,
  .theHipposOriginal {
    position: absolute;
    display: inline-block;
  }
  .dataOwnership {
    top: 3893px;
    left: 410px;
    font-size: var(--font-size-11xl);
    letter-spacing: -0.02em;
    line-height: 46px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    width: 390px;
  }
  .theHipposOriginal {
    top: 3748px;
    left: 20px;
    line-height: 25px;
    width: 370px;
    height: 69px;
    font-size: 14px;
  }
  .p2pStorage,
  .sdk {
    position: absolute;
    top: 3696px;
    left: 20px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .p2pStorage {
    left: 430px;
  }
  .decentralizedDataTransmissio {
    position: absolute;
    top: 3748px;
    left: 430px;
    line-height: 25px;
    display: inline-block;
    width: 370px;
    font-size: 14px;
  }
  .solutionChild3,
  .solutionChild4 {
    position: absolute;
    top: 3869px;
    left: 430px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen-100);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .solutionChild4 {
    left: 20px;
  }
  .readMore,
  .readMore1 {
    position: absolute;
    top: 3869px;
    left: 20px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IiMxOUQ5NTkiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .readMore1 {
    left: 430px;
  }
  .maskGroupIcon3,
  .maskGroupIcon4,
  .maskGroupIcon5,
  .maskGroupIcon6 {
    position: absolute;
    top: 1066px;
    left: 0;
    width: 478px;
    height: 648px;
    object-fit: cover;
  }
  .maskGroupIcon4,
  .maskGroupIcon5,
  .maskGroupIcon6 {
    top: 3010px;
  }
  .maskGroupIcon5,
  .maskGroupIcon6 {
    top: 3366px;
    left: 411px;
    width: 389px;
    height: 280px;
    height: 280px;
    object-fit: cover;
    border-radius: var(--br-11xl);
    pointer-events: none;
  }
  .maskGroupIcon6 {
    left: 0;
    height: 280px;
    object-fit: cover;
    border-radius: var(--br-11xl);
  }
  .solution {
    position: relative;
    width: 100%;
    height: 4753px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .earthVideo {
    position: absolute;
    top: 385px;
    left: calc(50% - 400px);
  }
}
