@media screen and (max-width: 819px) and (min-width: 500px) {
  .solutionChild,
  .solutionItem {
    position: absolute;
    top: 2550px;
    left: 0;
    background-color: #1ca9fe;
    width: 100%;
    height: 1126px;
  }
  .solutionItem {
    top: 3676px;
    background-color: #f46cbd;
  }
  .adobestock46112667723Icon {
    position: absolute;
    top: 3873px;
    left: -100px;
    width: 700px;
    height: 903.91px;
    object-fit: contain;
    pointer-events: none;
  }
  .personalizedHealthcareSoluti,
  .yourGatewayTo {
    position: absolute;
    left: calc(50% - 249px);
    letter-spacing: -0.02em;
  }
  .yourGatewayTo {
    top: 428px;
    font-size: var(--font-size-13xl);
    line-height: 46px;
    display: inline-block;
    width: 390px;
  }
  .personalizedHealthcareSoluti {
    top: 551px;
    font-size: var(--font-size-lg);
    line-height: 26px;
    text-transform: uppercase;
    color: #00d308;
  }
  .developedByHippocrat {
    position: absolute;
    top: 596px;
    left: 1px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #484848;
    display: inline-block;
    width: 390px;
    height: 166px;
    font-size: 14px;
  }
  .alwaysAccessibleHealth {
    margin: 0;
  }
  .alwaysAccessibleHealthContainer,
  .hipposTechnology {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .alwaysAccessibleHealthContainer {
    top: 980px;
    left: calc(50% - 176px);
    font-size: 34px;
    line-height: 47px;
    display: inline-block;
    width: 353px;
  }
  .hipposTechnology {
    top: 4922px;
    left: calc(50% - 249.79px);
    font-size: 45px;
    transform: rotate(0.38deg);
    transform-origin: 0 0;
  }
  .solutionInner {
    position: absolute;
    top: 1424px;
    left: 0;
    border-radius: var(--br-11xl) var(--br-11xl) 0 0;
    background-color: #00d332;
    width: 100%;
    height: 1126px;
  }
  .image34Icon {
    position: absolute;
    top: 6341px;
    left: 23px;
    border-radius: var(--br-11xl);
    width: 454px;
    height: 516px;
    object-fit: cover;
    pointer-events: none;
  }
  .hippoIsOnContainer,
  .joinTheMedicalContainer {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    text-align: center;
  }
  .joinTheMedicalContainer {
    top: 6451px;
    left: 117px;
    font-size: 43px;
    line-height: 50px;
  }
  .hippoIsOnContainer {
    top: 6567px;
    left: 47px;
    line-height: 25px;
    display: inline-block;
    width: 386px;
  }
  .rectangleDiv {
    position: absolute;
    top: 6723px;
    left: 152px;
    border-radius: var(--br-980xl);
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 196px;
    height: 46px;
  }
  .letsJoin {
    position: absolute;
    top: 6723px;
    left: calc(50% - 99px);
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .dataManagementSystem,
  .youCanPay {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
    width: 390px;
  }
  .youCanPay {
    top: 2669px;
    font-size: 16px;
    line-height: 30px;
  }
  .dataManagementSystem {
    top: 3795px;
    line-height: 25px;
  }
  .cryptocurrencyPayments,
  .medicalDataSovereignty {
    position: absolute;
    top: 2610px;
    left: 0;
    font-size: var(--font-size-8xl);
    letter-spacing: -0.02em;
    line-height: 37px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .medicalDataSovereignty {
    top: 3736px;
    white-space: pre-wrap;
  }
  .theHipposOriginal {
    position: absolute;
    top: 5415px;
    left: 20px;
    line-height: 25px;
    display: inline-block;
    width: 370px;
    font-size: 14px;
  }
  .p2pStorage,
  .sdk {
    position: absolute;
    top: 5363px;
    left: 20px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .p2pStorage {
    top: 6002px;
  }
  .decentralizedDataTransmissio {
    position: absolute;
    top: 6054px;
    left: 20px;
    line-height: 25px;
    display: inline-block;
    width: 370px;
    font-size: 14px;
  }
  .solutionChild1,
  .solutionChild2 {
    position: absolute;
    top: 6165px;
    left: 20px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen-100);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .solutionChild2 {
    top: 5526px;
  }
  .readMore,
  .readMore1 {
    position: absolute;
    top: 5526px;
    left: 20px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IiMxOUQ5NTkiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .readMore1 {
    top: 6165px;
  }
  .solutionChild3 {
    position: absolute;
    top: 5672px;
    left: 0;
    border-radius: var(--br-11xl);
    background-color: #f6f8f9;
    width: 390px;
    height: 280px;
  }
  .icon {
    position: absolute;
    top: 145px;
    left: 69px;
    width: 364px;
    height: 89px;
    overflow: hidden;
  }
  .accessExpertMedical,
  .remoteConsultation {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
  }
  .accessExpertMedical {
    top: 1580px;
    line-height: 25px;
    display: inline-block;
    width: 390px;
  }
  .remoteConsultation {
    top: 1484px;
    font-size: 30px;
    line-height: 37px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .adobestock46112667724Icon,
  .adobestock46112667731Icon {
    position: absolute;
    top: 1622px;
    left: calc(50% - 350px);
    width: 700px;
    height: 903px;
    object-fit: contain;
    pointer-events: none;
  }

  .adobestock46112667724Icon {
    top: 2748px;
  }
  .maskGroupIcon {
    position: absolute;
    top: 5672px;
    left: 1px;
    width: 389px;
    height: 280px;
    object-fit: cover;
    border-radius: var(--br-11xl);
    pointer-events: none;
  }
  .maskGroupIcon_1 {
    position: absolute;
    top: 5040px;
    left: 1px;
    width: 389px;
    height: 280px;
    object-fit: cover;
    border-radius: var(--br-11xl);
    pointer-events: none;
  }
  .solution {
    position: relative;
    width: 100%;
    height: 7002px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .earthVideo {
    position: absolute;
    top: 843px;
    left: calc(50% - 250px);
  }
}
@media screen and (max-width: 500px) {
  .mySwiper1 {
    position: absolute;
    top: 1448px;
    width: 100%;
  }
  .mySwiper2 {
    position: absolute;
    top: 2299px;
    width: 100%;
  }
  .mySwiperImg {
    width: 500px;
    margin-left: calc(50% - 250px);
  }

  .swiper-slide {
    width: 250px;
  }
  .swiperImg1 {
    width: 500px;
    position: absolute;
    left: -160px;
  }
  .swiperImg2 {
    width: 500px;
    margin-left: -150px;
  }
  @media screen and (max-width: 380px) {
    .swiperImg1 {
      width: 500px;
      position: absolute;
      left: -180px;
    }
    .swiperImg2 {
      width: 500px;
      margin-left: -170px;
    }
  }

  .solution360Child,
  .solution360Item {
    position: absolute;
    top: 2100px;
    left: 0;
    background-color: #1ca9fe;
    width: 500px;
    height: 850px;
  }
  .solution360Item {
    top: 2950px;
    background-color: #f46cbd;
  }
  .adobestock46112667723Icon {
    position: absolute;
    top: 3180px;
    left: -92px;
    width: 500px;
    height: 645.65px;
    object-fit: cover;
  }
  .yourGatewayTo {
    position: absolute;
    top: 428px;
    left: 0;
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    display: inline-block;
    width: 356px;
  }
  .personalizedHealthcareSoluti {
    position: absolute;
    top: 551px;
    left: 0;
    font-size: var(--font-size-lg);
    letter-spacing: -0.02em;
    line-height: 26px;
    text-transform: uppercase;
    color: #00d308;
  }
  .developedByHippocrat {
    position: absolute;
    top: 596px;
    left: 1px;
    letter-spacing: -0.02em;
    line-height: 25px;
    color: #484848;
    display: inline-block;
    width: 100%;
    height: 166px;
    font-size: 14px;
  }
  .alwaysAccessibleHealth {
    margin: 0;
  }
  .alwaysAccessibleHealthContainer,
  .hipposTechnology {
    position: absolute;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .alwaysAccessibleHealthContainer {
    top: 980px;
    left: calc(50% - 176px);
    font-size: var(--font-size-6xl);
    line-height: 31px;
    display: inline-block;
    width: 353px;
  }
  .hipposTechnology {
    top: 3886px;
    left: 0;
    font-size: 35px;
    transform: rotate(0.38deg);
    transform-origin: 0 0;
  }
  .solution360Inner {
    position: absolute;
    top: 1250px;
    left: 0;
    border-radius: var(--br-11xl) var(--br-11xl) 0 0;
    background-color: #00d332;
    width: 100%;
    height: 850px;
  }
  .image34Icon {
    position: absolute;
    top: 5281px;
    left: calc(50% - 180px);
    border-radius: var(--br-11xl);
    width: 360px;
    height: 516px;
    object-fit: cover;
  }
  .hippoIsOnContainer,
  .joinTheMedicalContainer {
    position: absolute;
    letter-spacing: -0.02em;
    color: var(--color-white);
    text-align: center;
  }
  .joinTheMedicalContainer {
    top: 5374px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: 43px;
    line-height: 50px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .hippoIsOnContainer {
    top: 5490px;
    left: calc(50% - 150px);
    line-height: 25px;
    display: inline-block;
    width: 300px;
  }
  .rectangleDiv {
    position: absolute;
    top: 5666px;
    left: calc(50% - 98px);
    border-radius: var(--br-980xl);
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 196px;
    height: 46px;
  }
  .letsJoin {
    position: absolute;
    top: 5666px;
    left: calc(50% - 99px);
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .dataManagementSystem,
  .youCanPay {
    position: absolute;
    left: 0;
    letter-spacing: -0.02em;
    display: inline-block;
    width: 360px;
  }
  .youCanPay {
    top: 2218px;
    font-size: var(--font-size-lg);
    line-height: 30px;
    width: 100%;
    font-size: 16px;
  }
  .dataManagementSystem {
    top: 3103px;
    line-height: 25px;
    width: 100%;
  }
  .cryptocurrencyPayments,
  .medicalDataSovereigntyContainer {
    position: absolute;
    top: 2160px;
    left: 0;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 37px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .medicalDataSovereigntyContainer {
    top: 3010px;
    line-height: 35px;
    white-space: pre-wrap;
  }
  .theHipposOriginal {
    position: absolute;
    top: 4355px;
    left: 0;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-size: 14px;
  }
  .p2pStorage,
  .sdk {
    position: absolute;
    top: 4303px;
    left: 0;
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    line-height: 28px;
  }
  .p2pStorage {
    top: 4942px;
  }
  .decentralizedDataTransmissio {
    position: absolute;
    top: 4994px;
    left: 3px;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-size: 14px;
  }
  .solution360Child1,
  .solution360Child2 {
    position: absolute;
    top: 5105px;
    left: 3px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen-100);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .solution360Child2 {
    top: 4466px;
  }
  .readMore,
  .readMore1 {
    position: absolute;
    top: 4466px;
    left: 0px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IiMxOUQ5NTkiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .readMore1 {
    top: 5105px;
  }
  .solution360Child3 {
    position: absolute;
    top: 4612px;
    left: 0;
    border-radius: var(--br-11xl);
    width: 390px;
    height: 280px;
  }
  .icon {
    position: absolute;
    top: 132px;
    left: 46px;
    width: 262px;
    height: 64px;
    overflow: hidden;
  }
  .accessExpertMedical {
    position: absolute;
    top: 1368px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 100%;
    font-size: 16px;
  }
  .remoteConsultation {
    position: absolute;
    top: 1310px;
    left: 0;
    font-size: var(--font-size-6xl);
    letter-spacing: -0.02em;
    line-height: 37px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .adobestock46112667724Icon,
  .adobestock46112667731Icon {
    position: absolute;
    top: 1448px;
    left: calc(50% - 250px);
    width: 500px;
    height: 645px;
    object-fit: contain;
  }
  .adobestock46112667724Icon {
    top: 2299px;
  }
  .adobestock46112667724Icon2 {
    top: 2299px;
    position: absolute;
    left: calc(50% + 10px);
    width: 500px;
    height: 645px;
    object-fit: contain;
  }
  .adobestock46112667724Icon3 {
    top: 3160px;
    position: absolute;
    left: calc(50% - 250px);
    width: 500px;
    height: 645px;
    object-fit: contain;
  }
  .maskGroupIcon,
  .maskGroupIcon1 {
    position: absolute;
    top: 4612px;
    left: 0;
    width: 359px;
    height: 280px;
    object-fit: cover;
    border-radius: 30px;
  }
  .maskGroupIcon1 {
    top: 3973px;
    left: 0;
    width: 357px;
    object-fit: cover;
    border-radius: 30px;
  }
  .adobestock4611266772Icon {
    position: absolute;
    top: 1451px;
    left: calc(50% + 10px);
    width: 500px;
    height: 645.21px;
    object-fit: cover;
  }
  .solution360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 5910px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
  }
  .earthVideo {
    position: absolute;
    top: 768px;
    left: calc(50% - 250px);
  }
}
