@media screen and (min-width: 1020px) {
  .itsUnrealisticFor {
    margin: 0;
  }
  .itsUnrealisticFor700 {
    margin: 0;
    font-weight: 700;
  }
  .itHasBeenContainer,
  .itsUnrealisticForContainer {
    position: absolute;
    top: 1740px;
    left: 511px;
    line-height: 28px;
    display: inline-block;
    width: 488px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .itHasBeenContainer {
    top: 1130px;
    height: 363px;
  }
  .howToAchieveContainer,
  .whyHealthcareData {
    position: absolute;
    top: 1740px;
    left: 0;
    font-size: var(--font-size-28xl);
    letter-spacing: -0.02em;
    line-height: 50px;
    display: inline-block;
    width: 473px;
    height: 84px;
  }
  .whyHealthcareData {
    top: 1130px;
    line-height: 35px;
    width: 490px;
    font-weight: 700;
  }
  .learn01Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1034px;
  }
  .visitingHospitalAnd,
  .whatIsSelfSovereigntyContainer {
    position: absolute;
    top: 687px;
    left: 510px;
    line-height: 28px;
    color: var(--color-white);
    display: inline-block;
    width: 489px;
    font-family: var(--font-inter);
    font-size: 16px;
  }
  .whatIsSelfSovereigntyContainer {
    left: 0;
    font-size: var(--font-size-28xl);
    letter-spacing: -0.02em;
    line-height: 50px;
    width: 490px;
    height: 84px;
    font-family: var(--font-geomanist);
  }
  .lf {
    font-family: var(--font-bodoni-72);
    font-weight: 400;
  }
  .lf700 {
    font-family: var(--font-bodoni-72);
    font-weight: 700;
  }
  .selfSovereignHealthcareDataContainer {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 68px;
    letter-spacing: -0.02em;
    color: var(--color-white);
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    line-height: 48px;
  }
  .theComingFuture {
    position: absolute;
    top: 167px;
    left: 0;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn01Item {
    position: absolute;
    top: 243px;
    left: calc(50% - 500px);
    border-radius: var(--br-11xl);
    background-color: #d9d9d9;
    width: 1000px;
    height: 400px;

    img {
      width: 100%;
      border-radius: var(--br-11xl);
    }
  }
  .learn01Inner {
    position: absolute;
    top: 1652.5px;
    left: calc(50% - 500.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 1001px;
    height: 1px;
  }
  .healthLevelSeven,
  .nextUseCaseContainer {
    position: absolute;
    left: 613px;
    letter-spacing: -0.02em;
  }
  .nextUseCaseContainer {
    top: 2120px;
    font-size: 34px;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
  }
  .healthLevelSeven {
    top: 2169px;
  }
  .chevronLeftIcon {
    position: absolute;
    top: 2162px;
    left: 881px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    object-fit: contain;
  }
  .learn01 {
    position: relative;
    width: 100%;
    height: 2410px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }

    img {
      -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
      -moz-user-select: none; /* 파이어폭스 */
      -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
      user-select: none; /* 표준 문법 */
    }
  }
}
@media screen and (max-width: 1019px) and (min-width: 820px) {
  .itsUnrealisticFor {
    margin: 0;
  }
  .itHasBeenContainer,
  .itsUnrealisticForContainer {
    position: absolute;
    top: 1718px;
    left: 411px;
    line-height: 25px;
    display: inline-block;
    width: 373px;
    font-size: 14px;
  }
  .itHasBeenContainer {
    top: 1128px;
    height: 363px;
  }
  .howToAchieveContainer,
  .whyHealthcareData {
    position: absolute;
    left: 15px;
    font-size: var(--font-size-23xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    display: inline-block;
    width: 375px;
  }
  .howToAchieveContainer {
    top: 1718px;
    height: 84px;
  }
  .whyHealthcareData {
    top: 1128px;
  }
  .learn800Child {
    position: absolute;
    top: 0;
    border-radius: 0 0 var(--br-11xl) var(--br-11xl);
    background-color: var(--color-black);
    width: 100%;
    height: 1032px;
  }
  .visitingHospitalAnd,
  .whatIsSelfSovereigntyContainer {
    position: absolute;
    top: 683px;
    left: 411px;
    line-height: 25px;
    color: var(--color-white);
    display: inline-block;
    width: 374px;
    font-size: 14px;
  }
  .whatIsSelfSovereigntyContainer {
    top: 684px;
    left: 15px;
    font-size: var(--font-size-23xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    width: 375px;
  }
  .lf {
    font-family: var(--font-bodoni-72);
  }
  .selfSovereignHealthcareDataContainer {
    position: absolute;
    top: 100px;
    left: 0;
    font-size: 60px;
    letter-spacing: -0.02em;
    color: var(--color-white);
  }
  .theComingFuture {
    position: absolute;
    top: 164px;
    left: 0;
    font-size: 18px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-weight: normal;
    font-family: var(--font-geomanist-ultra);
    color: #00d308;
  }
  .learn800Item {
    position: absolute;
    top: 1630.5px;
    left: calc(50% - 400.5px);
    border-top: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 801px;
    height: 1px;
  }
  .nextUseCaseContainer {
    position: absolute;
    top: 2129px;
    left: 411px;
    font-size: 30px;
    letter-spacing: -0.02em;
  }
  .selfSovereignHealthcareData {
    position: absolute;
    top: 2176px;
    left: 411px;
    letter-spacing: -0.02em;
  }
  .chevronLeftIcon,
  .maskGroupIcon {
    position: absolute;
    object-fit: contain;
  }
  .chevronLeftIcon {
    top: 2169px;
    left: 683px;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .maskGroupIcon {
    top: 240px;
    left: calc(50% - 400px);
    width: 800px;
    border-radius: var(--br-11xl);
    height: 400px;
    object-fit: cover;
  }
  .learn800 {
    position: relative;
    width: 100%;
    height: 2345px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-geomanist);
    a {
      color: var(--color-black);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
