@media screen and (max-width: 819px) and (min-width: 500px) {
  .cooperativeProtocolFor {
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .beyondBoundaries {
    margin: 0;
  }
  .beyondBoundariesBeyondContainer {
    font-size: 21px;
    letter-spacing: -0.02em;
    line-height: 31px;
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-top: 57px;
  }
  .home500Child,
  .home500Item {
    position: absolute;
    top: 360px;
    left: 70px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .home500Item {
    left: 260px;
    background-color: var(--color-white);
  }
  .care {
    font-family: var(--font-bodoni-72);
  }
  .hippocratIsTransforming,
  .solutionForPersonalizedContainer {
    position: absolute;
    letter-spacing: -0.02em;
    display: inline-block;
    width: 370px;
  }
  .solutionForPersonalizedContainer {
    top: 709px;
    left: 0;
    font-size: var(--font-size-13xl);
    line-height: 46px;
  }
  .hippocratIsTransforming {
    top: 1003px;
    left: 130px;
    line-height: 25px;
    font-size: 14px;
  }
  .home500Inner {
    position: absolute;
    top: 2409px;
    left: 2px;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .universalHealthcareForContainer,
  .weAreIntroducing,
  .whatWeFocusContainer {
    position: absolute;
    top: 2273px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 457px;
  }
  .universalHealthcareForContainer,
  .whatWeFocusContainer {
    top: 2109px;
    left: 2px;
    font-size: var(--font-size-13xl);
    line-height: 46px;
    width: 388px;
  }
  .whatWeFocusContainer {
    top: 2646px;
    width: 412px;
    height: 25px;
  }
  .builtOnThe,
  .enablingGlobalCooperation,
  .selfSovereigntyIsThe {
    position: absolute;
    top: 2968px;
    left: 2px;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 253px;
  }
  .builtOnThe,
  .enablingGlobalCooperation {
    top: 3405px;
    left: 0;
  }
  .enablingGlobalCooperation {
    top: 3812px;
    left: 2px;
  }
  .daoGovernanceBy,
  .humanHealthIs {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
  }
  .daoGovernanceBy {
    top: 4081px;
    left: calc(50% - 250px);
    font-size: var(--font-size-16xl);
    line-height: 45px;
    width: 500px;
  }
  .humanHealthIs {
    top: 4204px;
    left: 0;
    font-size: 28px;
    line-height: 40px;
    width: 411px;
  }
  .hippocratDevelopsOpenSource {
    position: absolute;
    top: 4439px;
    left: 2px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 455px;
    font-size: 14px;
  }
  .home500Child1,
  .rectangleDiv {
    position: absolute;
    top: 4575px;
    left: 190px;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .home500Child1 {
    left: 2px;
  }
  .exchange {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .cryptocurrencyExchange {
    position: absolute;
    top: 4854px;
    left: 2px;
    font-size: var(--font-size-13xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    text-transform: uppercase;
    display: inline-block;
    width: 301px;
    height: 25px;
  }
  .home500Child2 {
    position: absolute;
    top: 5703px;
    left: 23px;
    border-radius: 30px;
    background-color: #282828;
    width: 454px;
    height: 577px;
    pointer-events: none;
  }
  .groupChild {
    top: 0;
    left: 0;
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 196px;
  }
  .groupChild,
  .groupItem,
  .rectangleParent {
    position: absolute;
    height: 46px;
  }
  .groupItem {
    top: 0;
    left: 214px;
    border-radius: var(--br-980xl);
    background-color: transparent;
    border: 1px solid var(--color-white);
    box-sizing: border-box;
    width: 196px;
  }
  .rectangleParent {
    top: 5514px;
    left: 45px;
    width: 410px;
  }
  .letsRevolutionizeHealthcareContainer1 {
    width: 100%;
  }
  .letsRevolutionizeHealthcareContainer {
    position: absolute;
    top: 5912px;
    left: calc(50% - 207px);
    font-size: var(--font-size-16xl);
    letter-spacing: -0.02em;
    line-height: 45px;
    color: var(--color-white);
    text-align: center;
    display: flex;
    align-items: center;
    width: 414px;
    height: 104px;
  }
  .openDiscussion {
    position: absolute;
    top: 4575px;
    left: 190px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-white);
  }
  .openSource {
    position: absolute;
    top: 4575px;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .learnMore,
  .learnMore1 {
    text-decoration: underline;
    color: var(--color-limegreen-100);

    a {
      cursor: pointer;
      color: var(--color-limegreen-100);
    }
  }
  .learnMore {
    position: absolute;
    top: 3898px;
    left: 0;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .learnMore1 {
    top: 3481px;
    left: 2px;
  }
  .learnMore1,
  .learnMore2,
  .learnMore5 {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .learnMore2 {
    top: 3074px;
    left: 2px;
    text-decoration: underline;
    color: var(--color-limegreen-100);

    a {
      cursor: pointer;
      color: var(--color-limegreen-100);
    }
  }
  .learnMore5 {
    top: 2409px;
    left: 0;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .joinCommunity {
    position: absolute;
    top: 373px;
    left: 280px;
  }
  .contact,
  .joinCommunity,
  .joinCommunity1 {
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .contact {
    position: absolute;
    top: 5853px;
    left: calc(50% - 27px);
    color: var(--color-white);
  }
  .joinCommunity1 {
    top: 6065px;
    left: calc(50% - 206px);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    position: absolute;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .emailUs,
  .icon,
  .joinCommunity1 {
    position: absolute;
  }
  .emailUs {
    top: 6065px;
    left: calc(50% + 9px);
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    position: absolute;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .icon {
    bottom: 4360px;
    left: calc(50% - 350px);
    width: 700px;
    height: 905.61px;
    object-fit: contain;
  }
  .maskGroupIcon,
  .maskGroupIcon1,
  .maskGroupIcon2 {
    position: absolute;
    top: 2713px;
    left: calc(50% - 250px);
    width: 240px;
    height: 222.22px;
    object-fit: contain;
  }
  .maskGroupIcon1,
  .maskGroupIcon2 {
    top: 3150px;
    object-fit: cover;
  }
  .maskGroupIcon2 {
    top: 3556px;
    object-fit: contain;
  }
  .home500 {
    position: relative;
    width: 100%;
    height: 6457px;
    overflow: hidden;
    text-align: left;
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -550px;
  }
  .web3Img {
    position: absolute;
    top: 820px;
    width: 100%;
    height: 164px;
    object-fit: cover;
    border-radius: 18px;
  }
  .exchangeContainer {
    position: absolute;
    top: 4972px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .exchangeImageBox {
    display: flex;
    gap: 7px;
    img {
      cursor: pointer !important;
      width: 120px;
      height: 48px;
    }
  }
  .image55Icon {
    position: absolute;
    top: 5000px;
    left: 350px;
    width: 133px;
    height: 38px;
  }
  .image54Icon {
    position: absolute;
    top: 5005px;
    left: 23px;
    width: 99px;
    height: 29px;
  }
  .image56Icon {
    position: absolute;
    top: 5002px;
    left: 158px;
    width: 160px;
    height: 35px;
  }
  .seeWhitepaper {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .seeWhitepaper2 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .seeWhitepaperWrapper {
    margin-top: 70px;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 20px;
    a {
      text-decoration: none;
    }
  }
  .partnershipWrapper {
    position: absolute;
    top: 5200px;
  }
  .partnership {
    letter-spacing: -0.02em;
    left: calc(50% - 250px);
    width: 500px;
    line-height: 58px;
    text-align: center;
    font-size: var(--font-size-13xl);
    font-weight: 400;
  }
  .ch {
    font-family: var(--font-bodoni-72);
  }
  .partnerImageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
  }
  .partnerImageBox {
    display: flex;
    gap: 7px;
    img {
      cursor: pointer !important;
      width: 120px;
      height: 48px;
    }
  }
}
@media screen and (max-width: 500px) {
  .cooperativeProtocolFor {
    font-size: 35px;
    letter-spacing: -0.02em;
    line-height: 45px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 366px;
  }
  .beyondBoundaries {
    margin: 0;
  }
  .beyondBoundariesBeyondContainer {
    font-size: var(--font-size-2xl);
    letter-spacing: -0.02em;
    line-height: 31px;
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-top: 57px;
  }
  .home360Child,
  .home360Item {
    position: absolute;
    top: 360px;
    left: calc(50% - 85px);
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .home360Item {
    top: 411px;
    background-color: var(--color-white);
  }
  .care {
    font-family: var(--font-bodoni-72);
  }
  .hippocratIsTransforming,
  .solutionForPersonalizedContainer {
    position: absolute;
    letter-spacing: -0.02em;
    display: inline-block;
  }
  .solutionForPersonalizedContainer {
    top: 709px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: var(--font-size-9xl);
    line-height: 40px;
    width: 370px;
  }
  .hippocratIsTransforming {
    top: 1003px;
    left: calc(50% - 180px);
    line-height: 25px;
    width: 360px;
    font-size: 14px;
  }
  .home360Inner {
    position: absolute;
    top: 2225px;
    left: calc(50% - 180px);
    border-radius: var(--br-980xl);
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .universalHealthcareForContainer,
  .weAreIntroducing,
  .whatWeFocusContainer {
    position: absolute;
    top: 2089px;
    left: calc(50% - 180px);
    width: 360px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 360px;
    font-size: 14px;
  }
  .universalHealthcareForContainer,
  .whatWeFocusContainer {
    top: 1979px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: var(--font-size-9xl);
    line-height: 40px;
  }
  .whatWeFocusContainer {
    top: 2461px;
    line-height: 46px;
    left: calc(50% - 180px);
    width: 360px;
    height: 25px;
  }
  .builtOnThe,
  .enablingGlobalCooperation,
  .selfSovereigntyIsThe {
    position: absolute;
    top: 2783px;
    left: 2px;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 30px;
    display: inline-block;
    width: 253px;
  }
  .builtOnThe,
  .enablingGlobalCooperation {
    top: 3220px;
    left: 0;
  }
  .enablingGlobalCooperation {
    top: 3627px;
    left: 2px;
  }
  .daoGovernanceBy,
  .humanHealthIs {
    position: absolute;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
  }
  .daoGovernanceBy {
    top: 3934px;
    left: 0;
    font-size: var(--font-size-9xl);
    line-height: 40px;
    width: 358px;
  }
  .humanHealthIs {
    top: 4084px;
    left: 0;
    font-size: var(--font-size-2xl);
    line-height: 31px;
    width: 360px;
  }
  .hippocratDevelopsOpenSource {
    position: absolute;
    top: 4259px;
    left: 2px;
    letter-spacing: -0.02em;
    line-height: 25px;
    display: inline-block;
    width: 358px;
    font-size: 14px;
  }
  .home360Child1,
  .rectangleDiv {
    position: absolute;
    top: 4496px;
    left: 1px;
    border-radius: var(--br-980xl);
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 170px;
    height: 36px;
  }
  .home360Child1 {
    top: 4445px;
  }
  .exchange {
    margin: 0;
    font-family: var(--font-bodoni-72);
  }
  .cryptocurrencyExchange {
    position: absolute;
    top: 4729px;
    left: 2px;
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    text-transform: uppercase;
    display: inline-block;
    width: 301px;
  }
  .home360Child2 {
    position: absolute;
    top: 5535px;
    left: calc(50% - 180px);
    // left: 0;
    border-radius: 30px;
    background-color: #282828;
    width: 360px;
    // width: 100%;
    height: 463px;
    pointer-events: none;
  }
  .home360Child3,
  .home360Child4 {
    position: absolute;
    left: calc(50% - 98px);
    border-radius: var(--br-980xl);
    box-sizing: border-box;
    width: 196px;
    height: 46px;
  }
  .home360Child3 {
    top: 5389px;
    background-color: var(--color-limegreen);
    border: 1px solid var(--color-black);
  }
  .home360Child4 {
    top: 5450px;
    background-color: transparent;
    border: 1px solid var(--color-white);
  }
  .letsRevolutionizeHealthcareContainer1 {
    width: 100%;
  }
  .letsRevolutionizeHealthcareContainer {
    position: absolute;
    top: 5687px;
    left: calc(50% - 150px);
    font-size: var(--font-size-9xl);
    letter-spacing: -0.02em;
    line-height: 40px;
    color: var(--color-white);
    text-align: center;
    display: flex;
    align-items: center;
    width: 300px;
    height: 104px;
  }
  .openDiscussion {
    position: absolute;
    top: 4496px;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-white);
  }
  .openSource {
    position: absolute;
    top: 4445px;
    left: 0;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .learnMore,
  .learnMore1 {
    text-decoration: underline;
    color: var(--color-limegreen-100);

    a {
      cursor: pointer;
      color: var(--color-limegreen-100);
    }
  }
  .learnMore {
    position: absolute;
    top: 3713px;
    left: 0;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .learnMore1 {
    top: 3296px;
    left: 2px;
  }
  .learnMore1,
  .learnMore2,
  .learnMore5 {
    position: absolute;
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .learnMore2 {
    top: 2889px;
    left: 2px;
    text-decoration: underline;
    color: var(--color-limegreen);
    a {
      cursor: pointer;
      color: var(--color-limegreen-100);
    }
  }
  .learnMore5 {
    top: 2225px;
    left: 0;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .joinCommunity {
    position: absolute;
    top: 424px;
    left: calc(50% - 65px);
  }
  .contact,
  .joinCommunity,
  .joinCommunity1 {
    letter-spacing: -0.02em;
    font-weight: 500;
  }
  .contact {
    position: absolute;
    top: 5628px;
    left: calc(50% - 27px);
    color: var(--color-white);
  }
  .joinCommunity1 {
    top: 5839px;
    left: calc(50% - 98px);

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    position: absolute;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .emailUs,
  .icon,
  .joinCommunity1 {
    position: absolute;
  }
  .emailUs {
    top: 5901px;
    left: calc(50% - 99px);
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--color-white);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk2IiBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgMTk2IDQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTk2IDEyLjkyMzRIMTkyLjI0NVY4LjAzNzQ4SDE4Ny44NzFWMy4yMzQzOEgxODIuODY3VjBMMTMuMTMyNiAwVjMuMjM0MzhIOC4xMjg2OFY4LjAzNzQ4SDMuNzU0OTVWMTIuOTIzNEgwVjMzLjA3ODdIMy43NTQ5NVYzNy45NjI1SDguMTI4NjhWNDIuNzY1NkgxMy4xMzI2VjQ2SDE4Mi44NjdWNDIuNzY1NkgxODcuODcxVjM3Ljk2MjVIMTkyLjI0NVYzMy4wNzg3SDE5NlYxMi45MjM0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 196px;
    position: absolute;
    height: 46px;
    line-height: 46px;
    text-align: center;
  }
  .icon {
    bottom: 4130px;
    left: calc(50% - 300px);
    width: 600px;
    height: 776.24px;
    object-fit: contain;
  }
  .maskGroupIcon,
  .maskGroupIcon1,
  .maskGroupIcon2 {
    position: absolute;
    top: 2528px;
    left: 0;
    width: 240px;
    height: 222.22px;
    object-fit: contain;
  }
  .maskGroupIcon1,
  .maskGroupIcon2 {
    top: 2965px;
  }
  .maskGroupIcon2 {
    top: 3371px;
  }
  .home360 {
    position: relative;
    width: 360px;
    margin: 0 auto;
    height: 6102px;
    overflow: hidden;
    text-align: left;
    color: var(--color-black);
    font-family: var(--font-geomanist);
    margin-top: -600px;
  }
  .exchangeContainer {
    position: absolute;
    top: 4848px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .exchangeImageBox {
    display: flex;
    gap: 7px;
    img {
      cursor: pointer !important;
      width: 85px;
      height: 34px;
    }
  }
  .cryptoImg {
    position: absolute;
    top: 4885px;
    width: 100%;
    display: flex;
    gap: 20px;
    text-align: center;
    justify-content: center;
  }
  .maskGroupIcon4 {
    // position: absolute;
    // top: 4885px;
    // left: 10px;
    width: 99px;
    height: 28px;
    object-fit: contain;
  }
  .maskGroupIcon5 {
    // position: absolute;
    // top: 4888px;
    // left: 120px;
    width: 73px;
    height: 22px;
    margin-top: 3px;
    object-fit: contain;
  }
  .maskGroupIcon6 {
    // position: absolute;
    // top: 4885px;
    // left: 220px;
    width: 119px;
    height: 26px;
    object-fit: contain;
  }
  .web3Img {
    position: absolute;
    top: 815px;
    left: calc(50% - 180px);
    width: 360px;
    height: 164px;
    object-fit: cover;
    border-radius: 18px;
  }

  .seeWhitepaper {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTcwIDEwLjExMzlIMTY2Ljc0M1Y2LjI5MDJIMTYyLjk1VjIuNTMxMjVIMTU4LjYxVjBMMTEuMzkwNSAwVjIuNTMxMjVINy4wNTAzOVY2LjI5MDJIMy4yNTY4NFYxMC4xMTM5SDBWMjUuODg3NkgzLjI1Njg0VjI5LjcwOThINy4wNTAzOVYzMy40Njg4SDExLjM5MDVWMzZIMTU4LjYxVjMzLjQ2ODhIMTYyLjk1VjI5LjcwOThIMTY2Ljc0M1YyNS44ODc2SDE3MFYxMC4xMTM5WiIgZmlsbD0iIzE5RDk1OSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .seeWhitepaper2 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcwIiBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMTcwIDM2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTY2LjI0MyAxMC4xMTM5VjEwLjYxMzlIMTY2Ljc0M0gxNjkuNVYyNS4zODc2SDE2Ni43NDNIMTY2LjI0M1YyNS44ODc2VjI5LjIwOThIMTYyLjk1SDE2Mi40NVYyOS43MDk4VjMyLjk2ODhIMTU4LjYxSDE1OC4xMVYzMy40Njg4VjM1LjVIMTEuODkwNVYzMy40Njg4VjMyLjk2ODhIMTEuMzkwNUg3LjU1MDM5VjI5LjcwOThWMjkuMjA5OEg3LjA1MDM5SDMuNzU2ODRWMjUuODg3NlYyNS4zODc2SDMuMjU2ODRIMC41VjEwLjYxMzlIMy4yNTY4NEgzLjc1Njg0VjEwLjExMzlWNi43OTAySDcuMDUwMzlINy41NTAzOVY2LjI5MDJWMy4wMzEyNUgxMS4zOTA1SDExLjg5MDVWMi41MzEyNVYwLjVIMTU4LjExVjIuNTMxMjVWMy4wMzEyNUgxNTguNjFIMTYyLjQ1VjYuMjkwMlY2Ljc5MDJIMTYyLjk1SDE2Ni4yNDNWMTAuMTEzOVoiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    font-family: Geomanist;
    font-size: 16px;
    font-weight: 500;
    width: 170px;
    line-height: 36px;
    height: 36px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-black);
  }
  .seeWhitepaperWrapper {
    margin-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 20px;
    a {
      text-decoration: none;
    }
  }
  .partnershipWrapper {
    position: absolute;
    top: 5085px;
  }
  .partnership {
    letter-spacing: -0.02em;
    width: 100%;
    line-height: 58px;
    text-align: center;
    font-size: var(--font-size-9xl);
  }
  .ch {
    font-family: var(--font-bodoni-72);
  }
  .partnerImageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    justify-content: center;
  }
  .partnerImageBox {
    display: flex;
    gap: 7px;
    img {
      cursor: pointer !important;
      width: 85px;
      height: 34px;
    }
  }
}
